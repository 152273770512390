export const MAIN_FONT = '"Segma", Helvetica, Arial, sans-serif'
export const TITLE_FONT = '"BuenosAires", Helvetica, Arial, sans-serif'
export const SCREEN_SIZES = {
  TINY: '21rem',
  XS: '30rem',
  SM: '48rem',
  MD: '64rem',
  LG: '75rem',
  XL: '90rem',
  HUGE: '120rem'
}
const EM_TO_PIXEL = 16

export const SCREEN_BREAKPOINTS_PX = Object.keys(SCREEN_SIZES).reduce(
  (breakpoints, key) => ({
    ...breakpoints,
    [key]: parseInt(SCREEN_SIZES[key], 10) * EM_TO_PIXEL
  }),
  {}
)
export const FLEXBOXGRID = {
  OUTER_MARGIN: '2rem',
  CONTAINER_SM: '44rem',
  CONTAINER_MD: '60rem',
  CONTAINER_LG: '71rem'
}

export const LAYOUT = {
  SPACING: 24,
  NB_OF_COL: 12
}

export const HEADER = {
  Z_INDEX: 1300,
  HEIGHT: '140px',
  XS_HEIGHT: '71px',
  SM_HEIGHT: '80px'
}

export const PASSWORD_MINIMUM_LENGTH = 8

export const ONBOARDING_LAYOUT = {
  SIDEBAR: '480px',
  SIDEBAR_CONTENT: '380px',
  MAIN_CONTENT: '640px',
  SPACING: 24
}

export const DEFAULT_LOCALE = 'fr'

export const AVAILABLE_LOCALES = ['en', 'fr']
export const AVAILABLE_LOCALES_DICTIONARY = { en: 'English', fr: 'Français' }

export const API_PAYMENT_ERRORS = {
  STRIPE_CARD_AUTHENTICATION_REQUIRED: 'STRIPE_CARD_AUTHENTICATION_REQUIRED'
}

export const AVAILABLE_PERIODS = ['all', 'year', 'month', 'week']

// CORPO
export const MV_ORDER_ITEM_TITLE = {
  MISTER: 'MISTER',
  MISSUS: 'MISSUS'
}

export const SIDEBAR_WIDTH = '328px'
export const STICKY_BUTTON_HEIGHT = '50px'

export const CREDIT_CARD_ERRORS = {
  AUTHENTICATION_REQUIRED: 'AUTHENTICATION_REQUIRED'
}

export const CGPS_RESTAURANT_PDF =
  'https://www.swile.co/legal/conditions-generales-restaurants'
