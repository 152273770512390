import React from 'react'
import styled from 'styled-components'

import { ButtonLink, Box, COLORS } from '@themenu/ui'
import { FormattedMessage } from 'react-intl'

const ActivateCardBanner = styled(Box)`
  background: ${COLORS.PURPLE};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;

  .ActivateCardBanner__Text {
    color: ${COLORS.WHITE};
    margin-right: 16px;
  }
`

const ActivateCard = ({ isAnonymous = false, hasOnlyVirtualCard = false }) => (
  <ActivateCardBanner flex>
    {!hasOnlyVirtualCard ? (
      <React.Fragment>
        <span className="ActivateCardBanner__Text">
          <FormattedMessage
            id="ActivateCard.info.description"
            defaultMessage="Vous avez reçu votre carte Swile ? Activez-la pour découvrir votre code PIN."
          />
        </span>
        <ButtonLink
          size="medium"
          to={isAnonymous ? '/anonymous-card-activate' : '/card-activate'}
        >
          <FormattedMessage
            id="ActivateCard.link"
            defaultMessage="Activer ma carte"
          />
        </ButtonLink>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span className="ActivateCardBanner__Text">
          <FormattedMessage
            id="ActivateCard.info.description.virtual"
            defaultMessage="Votre carte virtuelle vous attend !  Activez-la en un clic depuis votre application."
          />
        </span>
      </React.Fragment>
    )}
  </ActivateCardBanner>
)

export default ActivateCard
