/**
 * Redirect Router for legacy routes
 */
import React from 'react'
import { connect } from 'react-redux'
import { Switch, Route, Redirect } from 'react-router-dom'
import { compose, branch, renderNothing, lifecycle } from 'recompose'

import { COMPANY_ROLE } from '@themenu/shared/lib/constants/corpo'
import PrivateRoute from '@themenu/ui/src/containers/PrivateRoute'

import { companyAdminId } from '../modules/auth-selectors'
import { fetchProfile } from '../modules/auth'

const enhance = compose(
  connect(state => ({ companyId: companyAdminId(state) })),
  lifecycle({
    async UNSAFE_componentWillMount() {
      const { user } = await this.props.dispatch(fetchProfile())
      const isAdmin =
        user.companies.filter(
          c => c.company_membership.role === COMPANY_ROLE.ADMINISTRATOR
        ).length > 0

      if (!isAdmin) this.props.history.replace('/')
    },
  }),
  branch(({ companyId }) => companyId === null, renderNothing)
)
const LegacyMVOrder = enhance(({ companyId }) => (
  <Redirect to={`/pro/${companyId}/order`} />
))

const LegacyRouter = () => (
  <Switch>
    <Route path="/orders" exact component={() => <Redirect to="/history" />} />
    <PrivateRoute path="/pro/order/:action" component={LegacyMVOrder} />
  </Switch>
)

export default LegacyRouter
