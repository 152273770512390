import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { compose } from 'recompose'
import PropTypes from 'prop-types'

import { actions } from '../../../modules'
import {
  userSelector,
  isAffiliateSelector,
  hasMealVoucherProgramSelector,
  hasMealVoucherBalanceSelector,
} from '../../../modules/auth-selectors'

import MobileMenu from './MobileMenu'
import { MealVoucherWalletPropType } from '../../../utils/propTypes'

const mapStateToProps = state => ({
  user: userSelector(state),
  isAffiliate: isAffiliateSelector(state),
  hasMealVoucherProgram: hasMealVoucherProgramSelector(state),
  hasMealVoucherBalance: hasMealVoucherBalanceSelector(state),
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators({ ...actions.auth }, dispatch),
})

const MobileMenuContainer = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps)
)(MobileMenu)

MobileMenuContainer.propType = {
  mealVoucherWallet: MealVoucherWalletPropType,
  isDisplayingDailyBalance: PropTypes.bool,
}

export default MobileMenuContainer
