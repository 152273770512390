import React from 'react'
import qs from 'query-string'
import { withRouter } from 'react-router'

import { testLunchrUrl } from '../../common/testLunchrUrl'
import { FeedBackTitle } from '../common/FeedBackTitle'
import { FeedBackWrap } from '../common/FeedBackWrap'
import { NegativeFeedBackForm } from './NegativeFeedBackForm'

function NegativeFeedBack({ history }) {
  const { email, rating } = qs.parse(history.location.search)

  if (!email || !rating) {
    window.location = testLunchrUrl
    return null
  }

  const values = {
    user: { email },
    comment: '',
    rating,
  }

  return (
    <FeedBackWrap>
      <FeedBackTitle emoji="thinking-face">
        Mince alors ! Que s’est-il passé ?
      </FeedBackTitle>
      <NegativeFeedBackForm values={values} />
    </FeedBackWrap>
  )
}

const NegativeFeedBackWithRouter = withRouter(NegativeFeedBack)
export { NegativeFeedBackWithRouter as NegativeFeedBack }
