import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { COLORS } from '@themenu/ui'
import { media } from '@themenu/ui/src/components/utils'

export const MobileMenuWrapper = styled.div`
  display: block;

  ${media.SM`
    display: none;
  `};
`

export const StyledMobileMenu = styled.div`
  z-index: 1701;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: ${COLORS.WHITE};
  transition: transform 0.5s ease;
  overflow: hidden;
  transform: translateY(-100%);

  ${props =>
    props.isVisible &&
    `
    transform: translateY(0);
  `};
`

export const MobileMenuNav = styled.nav`
  width: 100%;
  height: 100%;
  padding-top: 100px;
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: auto;
`

export const MobileMenuNavContent = styled.ul`
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 1px solid ${COLORS.MAIN_BORDER_COLOR};
`

export const MobileMenuNavItem = styled.li`
  &:not(:first-child) {
    margin-left: 0;
  }
`

export const LinkStyles = css`
  display: block;
  padding: 10px 0;
  font-size: 18px;
  font-weight: 400;
  line-height: 26px;
  color: ${COLORS.MAIN_TEXT_COLOR};

  &:hover {
    color: ${COLORS.GREEN};
  }
`

export const MobileMenuNavLink = styled(Link)`
  ${LinkStyles};
`

export const MobileMenuNavExternalLink = styled.a`
  ${LinkStyles};
`

export const UserAvatar = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  img {
    display: inline-block;
    vertical-align: middle;
    height: 40px;
    width: 40px;
    border-radius: 100%;
  }

  figcaption {
    display: inline-block;
    vertical-align: middle;
    margin-left: 10px;
    font-size: 24px;
    font-weight: 700;
  }
`

export const MobileMenuMealVoucher = styled.div`
  margin-top: -21px;
`
