import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'

import { DoubleSideLayout, media } from '@themenu/ui'
import ActivateCard from '../../../screens/Shared/ActivateCard/ActivateCard'
import {
  mainLunchrCardSimpleSelector,
  hasOnlyVirtualCardSelector,
} from '../../../modules/uicorpo/my-lunchr-cards-selector'
import {
  hasActivateAnonymousCardAccessSelector,
  hasLunchrCardSelector,
  userSelector,
} from '../../../modules/auth-selectors'
import { fetchMyLunchrCards } from '../../../modules/uicorpo/my-lunchr-cards'

import Sidebar from './Sidebar'
import Banner from '../../Banner/Banner'
import { FormattedHTMLMessage } from 'react-intl'

const SidebarBody = styled.div`
  padding: 1.125rem 0.75rem;
  margin: 0 auto;
  height: 100vh;

  ${media.SM`
    padding: 3.25rem 2.5rem;
  `};
`

const SidebarLayout = ({
  hasActivateAnonymousCardAccess,
  card,
  children,
  dbjsCards,
  fetchMyLunchrCards,
  hasLunchrCard,
  hasOnlyVirtualCard,
  user,
  ...props
}) => {
  useEffect(() => {
    if (hasLunchrCard) {
      fetchMyLunchrCards()
    }
  }, [hasLunchrCard])

  const hasNoneActivatedCard =
    dbjsCards.length > 0 && dbjsCards.every(card => !card.activated_at)

  return (
    <DoubleSideLayout noHeader sidebarLeft={<Sidebar {...props} />}>
      {hasNoneActivatedCard || (hasActivateAnonymousCardAccess && !card) ? (
        <ActivateCard
          isAnonymous={hasActivateAnonymousCardAccess}
          hasOnlyVirtualCard={hasOnlyVirtualCard}
        />
      ) : (
        user &&
        user.betaWebappEnabled && (
          <Banner>
            <FormattedHTMLMessage
              id="Sidebarlayout.betawebapp"
              defaultMessage="<a href='{url}'>Votre espace client s'est refait une beauté, découvrez-le !</a>"
              values={{ url: process.env.REACT_APP_EMPLOYEE_APP_URL }}
            />
          </Banner>
        )
      )}
      <SidebarBody>{children}</SidebarBody>
    </DoubleSideLayout>
  )
}

const mapStateToProps = state => {
  return {
    card: mainLunchrCardSimpleSelector(state),
    hasActivateAnonymousCardAccess: hasActivateAnonymousCardAccessSelector(
      state
    ),
    hasLunchrCard: hasLunchrCardSelector(state),
    dbjsCards: Object.values(state.getIn(['dbjs']).lunchrCards),
    hasOnlyVirtualCard: hasOnlyVirtualCardSelector(state),
    user: userSelector(state),
  }
}

export default connect(mapStateToProps, { fetchMyLunchrCards })(SidebarLayout)
