import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveCardIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 2C4 0.895432 4.89543 0 6 0H14C15.1046 0 16 0.895431 16 2V20H4V2ZM9 4H7V6H9V4ZM7 7H9V9H7V7ZM9 10H7V12H9V10ZM11 4H13V6H11V4ZM13 7H11V9H13V7ZM11 10H13V12H11V10ZM12 15H8V19H12V15Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default ActiveCardIcon
