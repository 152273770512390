import Immutable from 'immutable'

/**
 * Transforms a price from Immutable to a JS object
 * @param  {Immutable.Map} price
 * @return {Object}
 */
const getPrice = price => {
  const isImmutable = price instanceof Immutable.Map

  return price && isImmutable
    ? {
        value: price.get('value'),
        currency: price.getIn(['currency', 'iso_3']),
      }
    : price && !isImmutable
    ? {
        value: price.value,
        currency: price.currency.iso_3,
      }
    : {
        value: 0,
        currency: 'EUR',
      }
}

export { getPrice }
