export const uniqueValues = a =>
  a.filter((value, index, newArray) => newArray.indexOf(value) === index)

export const invert = object => {
  const result = {}
  Object.keys(object).forEach(key => {
    let value = object[key]
    if (value != null && typeof value.toString !== 'function') {
      value = toString.call(value)
    }
    result[value] = key
  })
  return result
}

export const getDuplicateValue = a => {
  const seen = {}

  for (let i = 0; i < a.length; i++) {
    if (seen[a[i]]) return a[i]
    seen[a[i]] = true
  }

  return null
}

export const flatten = a =>
  a.reduce(
    (acc, val) =>
      Array.isArray(val) ? acc.concat(flatten(val)) : acc.concat(val),
    []
  )

export const createMemoizeSelectorByHash = hashFunction => {
  const _cache = {}

  return value => {
    if (!_cache[hashFunction(value)]) {
      _cache[hashFunction(value)] = value
    }

    return _cache[hashFunction(value)]
  }
}

export function debounce(func, wait, immediate) {
  var timeout
  return function() {
    var context = this,
      args = arguments
    var later = function() {
      timeout = null
      if (!immediate) func.apply(context, args)
    }
    var callNow = immediate && !timeout
    clearTimeout(timeout)
    timeout = setTimeout(later, wait)
    if (callNow) func.apply(context, args)
  }
}
