import React from 'react'
import styled from 'styled-components'

import ReferralWrap from '../../ReferralWrap/ReferralWrap'

const Wrapper = styled.div`
  padding-left: ${props => (props.paddingwidth ? props.paddingwidth : '13%')};
  padding-right: ${props => (props.paddingwidth ? props.paddingwidth : '13%')};
  ${props => (props.margintop ? `margin-top: ${props.margintop} ` : '')}
  background: ${props => (props.bgColor ? props.bgColor : 'white')};
`
export function FeedBackWrap({ children }) {
  return (
    <ReferralWrap>
      <Wrapper paddingwidth={'10%'}>{children}</Wrapper>
    </ReferralWrap>
  )
}
