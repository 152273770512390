import React from 'react'
import styled from 'styled-components'
import { COLORS } from '@themenu/ui'
import Cloudinary from '@themenu/shared/lib/services/cloudinary'
import ReferralFooter from '../../../ReferralWrap/ReferralFooter/ReferralFooter'

const FLAG_MAN = Cloudinary.url('web-assets/reco_flag_header', {
  secure: true,
})
const LOGO = Cloudinary.url('web-assets/reco-Logo-White', {
  secure: true,
})
const StyledHeaderWrap = styled.div`
  color: ${COLORS.WHITE};
  padding: 24px;
  background: ${COLORS.GREEN};
  background-image: url(${FLAG_MAN});
  background-repeat: no-repeat;
  background-position: 50% 0%;
  height: 300px;
`
const CongratulationsMobileWrapper = styled.div`
  background-color: ${COLORS.WHITE};
`
const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 36px;
  line-height: 36px;
  color: #1b2733;
  margin-top: 32px;
  margin-bottom: 16px;
`
const StyledDescription = styled.div`
  font-weight: bold;
  font-size: 28px;
  line-height: 36px;
  color: #1b2733;
  margin-top: 16px;
  margin-bottom: 12px;
`
const StyledText = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.BLUE_SOFT};
  margin-top: 12px;
  margin-bottom: 16px;
`
const TextWrap = styled.div`
  padding-left: 26px;
  padding-right: 26px;
`

function CongratulationsHeader() {
  return (
    <StyledHeaderWrap>
      <a href="https://www.lunchr.co" role="button">
        <img src={LOGO} width="80px" alt="" />
      </a>
    </StyledHeaderWrap>
  )
}

export default function CongratulationsMobile({ title, description, text }) {
  return (
    <CongratulationsMobileWrapper>
      <CongratulationsHeader />
      <TextWrap>
        <StyledTitle>{title}</StyledTitle>
        <StyledDescription>{description}</StyledDescription>
        <StyledText>{text}</StyledText>
        <ReferralFooter paddingTop={'92px'} />
      </TextWrap>
    </CongratulationsMobileWrapper>
  )
}
