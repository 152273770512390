import React, { useEffect } from 'react'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { Redirect } from 'react-router'
import isNil from 'ramda/src/isNil'
import Auth from '@themenu/shared/lib/services/auth'

import { fetchIsAffiliate, fetchProfile } from '../../modules/auth'
import {
  userSelector,
  companyAdminId,
  isAffiliateSelector,
  hasActivateAnonymousCardAccessSelector,
} from '../../modules/auth-selectors'
import { fetchMyLunchrCards } from '../../modules/uicorpo/my-lunchr-cards'
import {
  hasOnlyVirtualCardSelector,
  lunchrCardsSelector,
} from '../../modules/uicorpo/my-lunchr-cards-selector'

import { SignInRoute } from '../../routes'
import { isRealProd, STATIC_WEBSITE } from '../../utils/config'

import AppLayout from '../Layout/AppLayout'
import { pushLoggedStateToDatalayer } from '../../modules/tracking/datalayer'

const HomeSwitch = props => {
  const {
    user,
    companyAdminId,
    fetchProfile,
    fetchIsAffiliate,
    isAffiliate,
    fetchMyLunchrCards,
    hasActivateAnonymousCardAccess,
    swileCards,
    hasOnlyVirtualCard,
  } = props

  useEffect(() => {
    if (isNil(user)) {
      fetchProfile()
    }
    if (user) {
      fetchMyLunchrCards()
    }
  }, [user])

  useEffect(() => {
    if (isNil(isAffiliate)) {
      fetchIsAffiliate()
    }
  }, [isAffiliate])

  if (!Auth.isLoggedIn()) {
    if (isRealProd && document.location.pathname === '/') {
      document.location.href = STATIC_WEBSITE
      return ''
    }
    return <Redirect to={SignInRoute.reverse()} />
  }

  pushLoggedStateToDatalayer()

  if (!isNil(isAffiliate) && !isNil(user)) {
    if (companyAdminId && !user.phoneNumberConfirmed) {
      return (window.top.location = process.env.REACT_APP_CORPO_APP_URL)
    } else if (isAffiliate) {
      return (window.top.location = process.env.REACT_APP_AFFILIATES_URL)
    } else {
      if (
        hasActivateAnonymousCardAccess &&
        !swileCards &&
        !hasOnlyVirtualCard
      ) {
        return <Redirect to="/anonymous-card-activate/" />
      } else {
        return (window.top.location = process.env.REACT_APP_EMPLOYEE_APP_URL)
      }
    }
  }

  return <AppLayout />
}

const mapStateToProps = state => {
  const user = userSelector(state)

  return {
    user,
    companyAdminId: companyAdminId(state),
    isAffiliate: isAffiliateSelector(state),
    hasActivateAnonymousCardAccess: hasActivateAnonymousCardAccessSelector(
      state
    ),
    swileCards: lunchrCardsSelector(state),
    hasOnlyVirtualCard: hasOnlyVirtualCardSelector(state),
  }
}

const mapDispatchToProps = dispatch => ({
  ...bindActionCreators(
    { fetchProfile, fetchIsAffiliate, fetchMyLunchrCards },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeSwitch)
