import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { compose, withHandlers } from 'recompose'
import React from 'react'
import WebNotification from 'react-web-notification'

import { notificationsSelector } from '../modules/uiresto/notifications-selectors'
import { removeNotification } from '../modules/uiresto/notifications'

const Notification = compose(
  connect(null, dispatch =>
    bindActionCreators({ remove: removeNotification }, dispatch)
  ),
  withHandlers({
    onClose: ({ remove, id }) => () => {
      remove(id)
    },
  })
)(WebNotification)

function Notifications({ notifications = [] }) {
  return (
    <div style={{ display: 'none' }}>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          id={notification.id}
          title={notification.title}
          options={notification}
        />
      ))}
    </div>
  )
}

export default connect(state => ({
  notifications: notificationsSelector(state),
}))(Notifications)
