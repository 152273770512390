import React from 'react'
import qs from 'query-string'
import { withRouter } from 'react-router'

import { testLunchrUrl } from '../../common/testLunchrUrl'
import { FeedBackTitle } from '../common/FeedBackTitle'
import { FeedBackWrap } from '../common/FeedBackWrap'
import { PositiveFeedBackForm } from './PositiveFeedBackForm'

export function PositiveFeedBack({ history }) {
  const { email, rating } = qs.parse(history.location.search)

  if (!email || !rating) {
    window.location = testLunchrUrl
    return null
  }

  const values = {
    user: { email },
    comment: '',
    drh: { firstname: '', lastname: '', email: '' },
    rating,
  }
  return (
    <FeedBackWrap>
      <FeedBackTitle emoji="thumbs-up">
        Yeah ! On sent que vous êtes heureux
      </FeedBackTitle>
      <PositiveFeedBackForm values={values} />
    </FeedBackWrap>
  )
}

const PositiveFeedBackFormWithRouter = withRouter(PositiveFeedBackForm)
export { PositiveFeedBackFormWithRouter as PositiveFeedBackForm }
