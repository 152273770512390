import React from 'react'
import styled from 'styled-components'
import Grid from '@material-ui/core/Grid'
import Svg from 'react-inlinesvg'
import { COLORS, media } from '@themenu/ui'

import Cloudinary from '@themenu/shared/lib/services/cloudinary'
import { EmojiInText } from '../../common/components/EmojiInText'

const LOGO = Cloudinary.url('web-assets/reco-Logo-White', {
  secure: true,
})

const CARD_MAN = Cloudinary.url('web-assets/reco_card_man', {
  secure: true,
})

const GuestHeaderWrapper = styled.div`
  color: ${COLORS.BLUE_SOFT};
`

const StyledHeaderWrap = styled.div`
  color: ${COLORS.WHITE};
  padding-top: 20px;
  background: ${COLORS.GREEN};

  ${media.SM`
    padding: 90px 13% 0;
  `}
`

const HeaderWrapLink = styled.a`
  padding-left: 24px;

  ${media.SM`
    padding: 0;
  `}
`

const StyledTitle = styled.p`
  font-size: 32px;
  line-height: 36px;
  font-weight: bold;
  color: #1b2733;

  ${media.SM`
    margin-top: 105px;
    margin-right: 34px;
    color: ${COLORS.WHITE};
    font-size: 54px;
    line-height: 58px;
  `}
`
const StyledDescription = styled.p`
  font-size: 20px;
  line-height: 24px;

  ${media.SM`
    font-size: 36px;
    line-height: 46px;
    color: ${COLORS.WHITE};
    margin-right: 34px;
  `}
`
const StyledText = styled.p`
  font-size: 16px;
  line-height: 24px;

  ${media.SM`
    color: ${COLORS.WHITE};
    font-size: 20px;
    margin-right: 34px;
    padding-bottom: 50px;
  `}
`

const StyledContainer = styled.div`
  height: 100%;
  width: 100%;
  vertical-align: middle;
`

const StyledCardManWrapper = styled(Grid)`
  padding: 0 50px;

  ${media.SM`
    padding: 0;
  `}
`

const StyledCardMan = styled(Svg)`
  align-self: flex-end;

  svg {
    width: 100%;
    height: 100%;
    margin-bottom: -7px;

    ${media.SM`
      /* height: auto; */
    `}
  }
`

const GuestTextDesktopWrapper = styled(Grid)`
  display: none;
  ${media.SM`
  display: inherit;
  `}
`

const GuestTextMobileWrapper = styled(Grid)`
  display: inherit;
  padding: 23px;

  ${media.SM`
    display: none;
  `}
`

function GuestText({ leaderName, guestName, amount }) {
  return (
    <StyledContainer>
      <StyledTitle>
        Votre carte Swile bientôt chez vous !
        <EmojiInText emoji="party-popper" size="54px" />
      </StyledTitle>
      <StyledDescription>Bonjour {guestName},</StyledDescription>
      <StyledText>
        {leaderName} vous invite à découvrir Swile ! Recevez votre carte Lunchr
        prépayée avec un crédit de {amount}€ directement chez vous. Ça se passe
        par ici
        <EmojiInText emoji="backhand-index-pointing-down" />
      </StyledText>
    </StyledContainer>
  )
}

function GuestHeader(props) {
  return (
    <GuestHeaderWrapper>
      <StyledHeaderWrap>
        <HeaderWrapLink href="https://www.lunchr.co" role="button">
          <img src={LOGO} width="80px" alt="logo" />
        </HeaderWrapLink>
        <Grid container justify="center">
          <GuestTextDesktopWrapper item md={6} lg={6}>
            <GuestText {...props} />
          </GuestTextDesktopWrapper>
          <StyledCardManWrapper item md={6} lg={6} justify="center">
            <StyledCardMan src={CARD_MAN} cacheGetRequests />
          </StyledCardManWrapper>
        </Grid>
      </StyledHeaderWrap>
      <GuestTextMobileWrapper>
        <GuestText {...props} />
      </GuestTextMobileWrapper>
    </GuestHeaderWrapper>
  )
}
export default GuestHeader
