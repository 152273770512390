import { curry, path, pathEq, ifElse, always, prop } from 'ramda'

import {
  orderSafetyCards as requestOrderSafetyCards,
  fetchSafetyCardsOrderPriceAndOrderLimit as requestSafetyCardsOrderPriceAndOrderLimit,
} from '../../services/api'
import {
  checkNeedAuthCreditCard,
  validateAuthCreditCard,
} from '../dbjs/creditCards'
import { API_PAYMENT_ERRORS } from '@themenu/ui'

const SAFETY_CARDS_RESET_ERROR_CODE =
  'uicorpo/safety-cards/SAFETY_CARDS_RESET_ERROR_CODE'
const SAFETY_CARDS_RESET_ORDERS =
  'uicorpo/safety-cards/SAFETY_CARDS_RESET_ORDERS'
const ORDER_SAFETY_CARDS_REQUEST =
  'uicorpo/safety-cards/ORDER_SAFETY_CARDS_REQUEST'
const ORDER_SAFETY_CARDS_SUCCEEDED =
  'uicorpo/safety-cards/ORDER_SAFETY_CARDS_SUCCEEDED'
const ORDER_SAFETY_CARDS_FAILED =
  'uicorpo/safety-cards/ORDER_SAFETY_CARDS_FAILED'
const SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_REQUEST =
  'uicorpo/safety-cards/SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_REQUEST'
const SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_SUCCEEDED =
  'uicorpo/safety-cards/SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_SUCCEEDED'
const SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_FAILED =
  'uicorpo/safety-cards/SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_FAILED'

const initialState = {
  errorCode: null,
  orders: [],
  orderLimit: 0,
  priceInCents: 0,
}

export const resetErrorCode = () => ({ type: SAFETY_CARDS_RESET_ERROR_CODE })
export const resetOrders = () => ({ type: SAFETY_CARDS_RESET_ORDERS })

export const orderSafetyCards = curry(async (payload, stripe, dispatch) => {
  try {
    dispatch({ type: ORDER_SAFETY_CARDS_REQUEST })

    await dispatch(
      checkNeedAuthCreditCard(stripe, payload.stripeCardToken, false)
    )

    const safetyCardOrder = await requestOrderSafetyCards(payload)

    dispatch({
      type: ORDER_SAFETY_CARDS_SUCCEEDED,
      payload: {
        data: safetyCardOrder,
      },
    })
  } catch (error) {
    console.error(error)
    const code = path(['result', 'error', 'code'], error)
      ? error.result.error.code
      : null

    if (code === API_PAYMENT_ERRORS.STRIPE_CARD_AUTHENTICATION_REQUIRED) {
      try {
        await dispatch(
          validateAuthCreditCard(stripe, payload.stripeCardToken, false)
        )
        const safetyCardOrder = await requestOrderSafetyCards(payload)

        dispatch({
          type: ORDER_SAFETY_CARDS_SUCCEEDED,
          payload: {
            data: safetyCardOrder,
          },
        })
      } catch (e) {
        console.error(e)
        dispatch({
          type: ORDER_SAFETY_CARDS_FAILED,
          payload: { errorCode: 2 },
        })
      }
    } else {
      const resolveErrorCode = ifElse(
        pathEq(['result', 'error', 'code'], 'PAYMENT_ERROR'),
        always(2),
        always(1)
      )

      dispatch({
        type: ORDER_SAFETY_CARDS_FAILED,
        payload: { errorCode: resolveErrorCode(error) },
      })
    }
  }
})

export const fetchSafetyCardsOrderPriceAndOrderLimit = curry(async dispatch => {
  try {
    dispatch({ type: SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_REQUEST })

    const priceAndOrderLimit = await requestSafetyCardsOrderPriceAndOrderLimit()

    dispatch({
      type: SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_SUCCEEDED,
      payload: {
        data: priceAndOrderLimit,
      },
    })
  } catch (error) {
    console.error(error)
    dispatch({ type: SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_FAILED })
  }
})

const reducer = (
  state = initialState,
  { type, payload: { errorCode, data } = {} }
) => {
  switch (type) {
    case SAFETY_CARDS_RESET_ORDERS:
      return { ...state, orders: prop('orders', initialState) }
    case SAFETY_CARDS_RESET_ERROR_CODE:
    case ORDER_SAFETY_CARDS_REQUEST:
      return { ...state, errorCode: prop('errorCode', initialState) }
    case ORDER_SAFETY_CARDS_FAILED:
      return { ...state, errorCode }
    case ORDER_SAFETY_CARDS_SUCCEEDED:
      const order = {
        id: path(['safety_card_order', 'id'], data),
      }

      return { ...state, orders: [order] }
    case SAFETY_CARDS_PRICE_AND_ORDER_LIMIT_SUCCEEDED:
      return {
        ...state,
        priceInCents: path(['price_and_order_limit', 'price_cents'], data),
        orderLimit: path(['price_and_order_limit', 'order_limit'], data),
      }
    default:
      return state
  }
}

export default reducer
