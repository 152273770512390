import React from 'react'
import styled from 'styled-components'

import { media } from '@themenu/ui'
import cloudinary from '@themenu/shared/lib/services/cloudinary'

const WALKING_GUY = cloudinary.url(
  'web-assets/reco_user_summary_walking_guy.svg',
  {
    secure: true,
  }
)

const WalkingGuyWrapper = styled.div`
  overflow: hidden;
`

const StyledWalkingGuy = styled.img`
  width: 100%;
  margin-top: 30px;
  margin-left: 45px;

  ${media.SM`
    width: auto;
    position: absolute;
    top: -140px;
    right: -500px;
    margin-top: 0;
  `};
`

export function WalkingGuy() {
  return (
    <WalkingGuyWrapper>
      <StyledWalkingGuy src={WALKING_GUY} cacheGetRequests />
    </WalkingGuyWrapper>
  )
}
