import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

import { COLORS, Box } from '@themenu/ui'
import { MealVoucherWalletPropType } from '../../utils/propTypes'
import { path, prop } from 'ramda'

const Gauge = ({ value }) => (
  <Box
    height="0.6em"
    bg={value === 0 ? COLORS.FUSHIA_PASTEL : COLORS.GRAY_LIGHTER}
    borderRadius="6px"
    m="0.5em 0 0.5em 0"
    overflow="hidden"
  >
    <Box
      width={value === 0 ? '1%' : `${value}%`}
      height="100%"
      bg={value === 0 ? COLORS.PINK : COLORS.PURPLE}
    />
  </Box>
)

export const OUT_RESTAURANT_TEXT = 'Dépenses hors restos limitées à'
export const TODAY_YOU_HAVE_SOMETHING = "Aujourd'hui, il vous reste"
export const TODAY_YOU_HAVE_NOTHING =
  'Vous avez dépensé la totalité de votre solde titres-resto.'
export const OVERALL_BALANCE = 'Solde global'

const MealVoucherBalanceBlock = ({
  mealVoucherWallet,
  isWithBorder,
  isDisplayingBalance = true,
  isDisplayingDailyBalance = true,
}) => {
  const isEmptyBalance = !path(
    ['dailyAvailableBalanceForRestaurant', 'value'],
    mealVoucherWallet
  )

  return (
    <Box
      borderWidth={isWithBorder && isDisplayingDailyBalance ? '1px' : '0'}
      borderStyle="solid"
      borderColor={COLORS.GRAY_LIGHTER}
      borderRadius="12px"
      p={isDisplayingDailyBalance ? '1em' : '0'}
      mb="0.8em"
      color={COLORS.PURPLE_DARK}
    >
      {isDisplayingDailyBalance && (
        <Box>
          <Box
            display="flex"
            fontWeight="bold"
            color={isEmptyBalance ? COLORS.PINK : COLORS.PURPLE}
          >
            <Box mt="auto" mb="auto" textAlign="left" style={{ flex: 3 }}>
              {isEmptyBalance ? (
                <FormattedMessage
                  id="MealVoucherBalanceBlock.todayYouHaveNothing"
                  defaultMessage={TODAY_YOU_HAVE_NOTHING}
                />
              ) : (
                <FormattedMessage
                  id="MealVoucherBalanceBlock.todayYouHaveSomething"
                  defaultMessage={TODAY_YOU_HAVE_SOMETHING}
                />
              )}
            </Box>
            <Box ml="auto" fontSize={20} textAlign="right" style={{ flex: 1 }}>
              {path(
                ['dailyAvailableBalanceForRestaurant', 'text'],
                mealVoucherWallet
              )}
            </Box>
          </Box>
          <Gauge value={prop('gauge', mealVoucherWallet)} />
          {!isEmptyBalance && (
            <Box display="flex" fontSize="small">
              <Box
                color={COLORS.PURPLE_DARK}
                textAlign="left"
                style={{ flex: 4 }}
              >
                <Box>
                  <FormattedMessage
                    id="MealVoucherBalanceBlock.outRestaurant"
                    defaultMessage={OUT_RESTAURANT_TEXT}
                  />
                </Box>
                <Box color={COLORS.PURPLE_GRAY}>
                  <FormattedMessage
                    id="MealVoucherBalanceBlock.outRestaurant.info"
                    defaultMessage="(supermarchés, boucheries, boulangeries...)"
                  />
                </Box>
              </Box>
              <Box textAlign="right" ml="auto" style={{ flex: 1 }}>
                <Box>
                  {path(['dailyAvailableBalance', 'text'], mealVoucherWallet)}
                </Box>
                <Box color={COLORS.PURPLE_GRAY}>{`/ ${path(
                  ['maximumDailyBalance', 'text'],
                  mealVoucherWallet
                )}`}</Box>
              </Box>
            </Box>
          )}
        </Box>
      )}
      {isDisplayingBalance && (
        <Box
          display="flex"
          mt={isDisplayingDailyBalance ? '1em' : '0'}
          bg={COLORS.GRAY_VERY_LIGHT}
          color={COLORS.PURPLE_GRAY}
          borderRadius="8px"
          p="0.8em"
          fontSize="small"
          fontWeight="700"
        >
          <Box textAlign="left">
            <FormattedMessage
              id="MealVoucherBalanceBlock.globalSold"
              defaultMessage={OVERALL_BALANCE}
            />
          </Box>
          <Box ml="auto" textAlign="right">
            {path(['balance', 'text'], mealVoucherWallet)}
          </Box>
        </Box>
      )}
    </Box>
  )
}

MealVoucherBalanceBlock.propTypes = {
  mealVoucherWallet: MealVoucherWalletPropType,
  isWithBorder: PropTypes.bool,
  isDisplayingBalance: PropTypes.bool,
  isDisplayingDailyBalance: PropTypes.bool,
}

export default MealVoucherBalanceBlock
