import React, { useContext } from 'react'
import { ReferralContext } from '../ReferralContext'
import styled from 'styled-components'

import { CompletePage } from '../common/components/Congratulations/CompletePage'
import { EmojiInText } from '../common/components/EmojiInText'
import { getVoucherAmount } from '../common/helpers/getVoucherAmount'
import { testLunchrUrl } from '../common/testLunchrUrl'

function Description({ voucherAmount, rewardPerPerson }) {
  return (
    <div>
      Votre inscription est bien validée, vous allez débloquer tous ensemble{' '}
      {voucherAmount}€, dont {rewardPerPerson}€ pour vous!
      <EmojiInText emoji="party-popper" size="35px" />
    </div>
  )
}

function Text() {
  const SemiBold = styled.span`
    font-weight: 600;
  `

  return (
    <span>
      Vous n’avez plus rien à faire maintenant
      <EmojiInText emoji={'raising-hands'} size={'24px'} />, à part être patient
      ! Car oui,
      <SemiBold>
        pour envoyer les autres cartes prépayées chez vos invités, nous allons
        leur demander leur adresse <EmojiInText emoji="house-with-garden" />.
      </SemiBold>
      Dès qu’ils nous les auront données, nous enverrons toutes les cartes en
      même temps. Pensez simplement à le leur rappeler !
    </span>
  )
}

export function ReferralSummaryComplete() {
  const { state } = useContext(ReferralContext)
  const name = state.leader.firstname
  let { invitees_count, card_amount } = state
  const voucherAmount = getVoucherAmount(state, invitees_count)
  if (!name || invitees_count < 1) {
    window.location = testLunchrUrl
    return null
  }
  const params = {
    title: `Bravo ${name} ! `,
    description: (
      <Description
        voucherAmount={voucherAmount}
        rewardPerPerson={card_amount}
      />
    ),
    text: <Text />,
  }
  return <CompletePage {...params} />
}
