import React, { Component } from 'react'
import ReactModal from 'react-modal'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { Icon, COLORS } from '@themenu/ui'
import { media } from '@themenu/ui/src/components/utils'

const ModalWrapper = styled(ReactModal)`
  position: relative;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: auto;
  -webkit-overflow-scrolling: touch;

  ${media.MD`
    position: absolute;
    top: 40px;
    left: 50%;
    transform: translate3d(-50%, 0, 0);
    width: ${props => (props.width ? props.width : '750px')};
    height: auto;
    ${props => !props.noBackground && `border-radius: 22px;`}

    /* customization modal */
    ${props =>
      props.scrollingContent &&
      `
      bottom: 40px;
      margin-bottom: 0;
    `}

    ${props =>
      props.verticalCenter &&
      `
      top: 50%;
      transform: translate3d(-50%, -50%, 0);
    `}

    ${props =>
      props.smallWrapper &&
      `
      width: 560px;
      margin-bottom: 50px;
    `}

    ${props =>
      props.hugeWrapper &&
      `
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: 0;
      width: auto;
      transform: none;
      border-radius: 0;
    `}
  `};
`

const ModalContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  ${props => !props.noBackground && `background-color: ${COLORS.WHITE}`}
  overflow: ${props => props.overflow || 'auto'};
`

const CloseLink = styled.a`
  position: absolute;
  right: ${props => props.closeLinkRightPosition || '20px'};
  top: 20px;
  cursor: pointer;
  z-index: 1;
`

export default function(WrapperComponent) {
  return class Modal extends Component {
    static propTypes = {
      hideCloseCross: PropTypes.bool,
    }

    static defaultProps = {
      hideCloseCross: false,
    }

    handleCloseClick(event) {
      event.preventDefault()
      this.handleRequestClose()
    }

    handleRequestClose() {
      this.props.modalProps.onRequestClose()
    }

    render() {
      const {
        modalProps,
        hideCloseCross,
        notCloseOnOverlayClick = false,
        noBackground = false,
        overflow,
        closeLinkRightPosition,
        ...props
      } = this.props

      return (
        <ModalWrapper
          {...modalProps}
          noBackground={noBackground}
          onRequestClose={() =>
            !notCloseOnOverlayClick && this.handleRequestClose()
          }
          overlayClassName="ReactModal__Overlay"
        >
          <ModalContainer noBackground={noBackground} overflow={overflow}>
            {!hideCloseCross && (
              <CloseLink closeLinkRightPosition={closeLinkRightPosition}>
                <Icon name="cross" onClick={this.handleCloseClick.bind(this)} />
              </CloseLink>
            )}
            <WrapperComponent
              {...props}
              onModalClose={this.handleRequestClose.bind(this)}
            />
          </ModalContainer>
        </ModalWrapper>
      )
    }
  }
}
