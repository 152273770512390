import React from 'react'
import styled from 'styled-components'
import Cloudinary from '@themenu/shared/lib/services/cloudinary'

const StyledHeaderWrap = styled.div`
  padding-top: 45px;
  padding-bottom: 45px;
`
const logo = Cloudinary.url('web-assets/reco-Logo', {
  secure: true,
})

function ReferralHeader() {
  return (
    <StyledHeaderWrap>
      <a href="https://www.lunchr.co" role="button">
        <img src={logo} width="80px" alt="logo" />
      </a>
    </StyledHeaderWrap>
  )
}
export default ReferralHeader
