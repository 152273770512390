import { createStore, applyMiddleware, compose } from 'redux'
import { fromJS } from 'immutable'
import createRavenMiddleware from 'raven-for-redux'
import thunk from 'redux-thunk'
import Auth from '@themenu/shared/lib/services/auth'

import { getInitialState as getInitialCookiesState } from './modules/cookies'
import Analytics, { analyticsMiddleware } from './services/analytics'
import rootReducer, { stateExporter } from './modules'

const getRaven = () =>
  window.Raven || {
    setDataCallback: () => {},
    captureBreadcrumb: () => {},
  }

export default function configureStore(initialState = {}) {
  // Load cart
  const userData = Auth.user

  // TODO remove this initialisation from cookies
  if (userData) {
    initialState.auth = { user: userData, companyAdminId: null }
  }

  initialState.cookies = getInitialCookiesState()

  const composeEnhancers =
    (process.env.NODE_ENV !== 'production' &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: ['app/UPDATE_CLOCK'],
      })) ||
    compose

  let newState = fromJS(initialState)

  const store = createStore(
    rootReducer,
    newState,
    composeEnhancers(
      applyMiddleware(
        thunk,
        createRavenMiddleware(getRaven(), {
          filterBreadcrumbActions: action =>
            /^(db\/|app\/|ui\/)/.test(action.type),
          actionTransformer: ({ type }) => ({ type }),
          stateTransformer: stateExporter,
        }),
        analyticsMiddleware
      )
    )
  )

  if (Auth.isLoggedIn() && Auth.user) {
    Analytics.trackUser({ ...Auth.user })
  }
  return store
}
