import { useEffect } from 'react'
import { withRouter } from 'react-router-dom'

import Zendesk, {
  CORPORATE_DEPARTMENT_ID,
  USER_DEPARTMENT_ID,
  AFFILIATE_DEPARTMENT_ID,
} from '@themenu/shared/lib/services/zendesk'

function ZendeskProvider({ location }) {
  useEffect(() => {
    const path = location.pathname

    if (path === '/' || /\/pro/.test(path)) {
      Zendesk.setDepartment(CORPORATE_DEPARTMENT_ID)
    } else if (path.startsWith('/partners')) {
      Zendesk.setDepartment(AFFILIATE_DEPARTMENT_ID)
    } else {
      Zendesk.setDepartment(USER_DEPARTMENT_ID)
    }
  }, [location.pathname])

  return null
}

export default withRouter(ZendeskProvider)
