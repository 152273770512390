import t from 'tcomb'

import {
  MV_ORDER_SHIPPING_STREET_MAXLENGTH,
  CARD_PROVIDER,
} from '@themenu/shared/lib/constants/corpo'

import { mergeTypedEntities } from './utils'

const initialState = {}

export const CoordinatesType = t.struct(
  {
    latitude: t.maybe(t.Number),
    longitude: t.maybe(t.Number),
  },
  'Coordinates'
)

export const AddressStreetType = t.refinement(
  t.String,
  street =>
    street.length <= MV_ORDER_SHIPPING_STREET_MAXLENGTH[CARD_PROVIDER.TREEZOR],
  'AddressStreet'
)
export const CountryCodeType = t.refinement(
  t.String,
  code => code.length === 2,
  'CountryCode'
)
export const AddressIdType = t.union([t.String, t.Number], 'AddressId')

export const BasicAddressType = t.struct(
  {
    id: AddressIdType,
    name: t.maybe(t.String),
    coordinates: t.maybe(CoordinatesType),
    formated: t.maybe(t.String),
    street: t.maybe(t.String),
    street2: t.maybe(t.String),
    city: t.maybe(t.String),
    zip: t.maybe(t.String),
    country: t.maybe(CountryCodeType),
    type: t.maybe(t.String),
    additional_info: t.maybe(t.String),
    address_kind: t.maybe(t.String),
  },
  'BasicAddress'
)

export const ShippingAddressType = BasicAddressType.extend(
  {
    default_shipping_address: t.maybe(t.Boolean),
    shipping_point_code: t.maybe(t.String),
    shipping_point_name: t.maybe(t.String),
  },
  'ShippingAddress'
)

export const AddressType = t.union(
  [BasicAddressType, ShippingAddressType],
  'Address'
)
AddressType.dispatch = ({ shipping_point_code }) =>
  shipping_point_code ? ShippingAddressType : BasicAddressType

export const AddressStoreType = t.dict(
  AddressIdType,
  AddressType,
  'AddressStore'
)

export default function reducer(state = initialState, action = {}) {
  const { dbRes } = action
  if (dbRes && dbRes.addresses) {
    return mergeTypedEntities(state, dbRes.addresses, AddressStoreType)
  }
  return state
}
