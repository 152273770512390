import React, { useEffect } from 'react'
import { createBrowserHistory } from 'history'
import { Router } from 'react-router-dom'
import { connect } from 'react-redux'
import pathToRegexp from 'path-to-regexp'

import Auth from '@themenu/shared/lib/services/auth'
import * as Zendesk from '@themenu/shared/lib/services/zendesk'
import includeIntercom, {
  getIntercomSettings,
} from '@themenu/ui/src/services/intercom'

import { changeLocation } from '../modules/router'
import { debounce } from '../services/utils'

const { REACT_APP_INTERCOM_APP_ID } = process.env

const pathCompany = pathToRegexp('/pro/:companyAdminId', [], {
  strict: false,
  end: false,
})

const zendeskUpdateLabels = debounce(Zendesk.setLabelsFromPathname, 1000)

const defaultHistory = createBrowserHistory()

const RouterDispatcher = ({ history = defaultHistory, children, dispatch }) => {
  useEffect(() => {
    const handleLocationChange = (location, action) => {
      const companyMatches = pathCompany.exec(location.pathname)
      const params = companyMatches &&
        companyMatches.length > 1 && { companyAdminId: companyMatches[1] }

      dispatch(changeLocation(location, action, params))

      if (REACT_APP_INTERCOM_APP_ID && !Auth.isSudo()) {
        includeIntercom(
          REACT_APP_INTERCOM_APP_ID,
          getIntercomSettings({ user: Auth.user })
        )
      }

      zendeskUpdateLabels(location.pathname)
    }

    const unsubscribeFromHistory = history.listen(handleLocationChange)
    handleLocationChange(history.location)

    return unsubscribeFromHistory
  }, [])

  return <Router history={history}>{children}</Router>
}

export default connect()(RouterDispatcher)
