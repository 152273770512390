import React from 'react'
import styled from 'styled-components'
import {
  ifElse,
  curryN,
  cond,
  propEq,
  always,
  T,
  pipe,
  either,
  prop,
  identity,
} from 'ramda'
import { Transition } from 'react-transition-group'
import ReactDOMServer from 'react-dom/server'

import { COLORS } from '@themenu/ui'
import ActiveCardIcon from './ActiveCardIcon'
import ActiveHistoryIcon from './ActiveHistoryIcon'
import ActiveSettingsIcon from './ActiveSettingsIcon'
import ActiveUserIcon from './ActiveUserIcon'
import ActiveCompanyIcon from './ActiveCompanyIcon'
import ActiveMvoRefundIcon from './ActiveMvoRefundIcon'
import InactiveCardIcon from './InactiveCardIcon'
import InactiveHistoryIcon from './InactiveHistoryIcon'
import InactiveSettingsIcon from './InactiveSettingsIcon'
import InactiveUserIcon from './InactiveUserIcon'
import InactiveCompanyIcon from './InactiveCompanyIcon'
import InactiveMvoRefundIcon from './InactiveMvoRefundIcon'
import ActiveGiftIcon from './ActiveGiftIcon'
import InacitveGiftIcon from './InactiveGiftIcon'

const resolveActiveOrButton = ifElse(
  either(prop('active'), prop('button')),
  always(`
    position: relative;
    left: -20px;
    padding-left: 20px;
    padding-right: 10px;
    margin-right: -40px;
  `),
  always('')
)

const resolveActiveStyle = ifElse(
  prop('active'),
  always(`
    background-color: ${COLORS.GREEN};
    cursor: default;
    border-radius: 20px;
    color: white;
  `),
  always('')
)

const resolveButton = ifElse(
  prop('button'),
  always(`
    background-color: ${COLORS.WHITE};
    border: 1px solid ${COLORS.GRAY_COOL};
    border-radius: 20px;
    color: ${COLORS.GRAY};
    cursor: pointer;
  `),
  always('')
)

const cardIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveCardIcon, ...props }),
  props => ({ Component: InactiveCardIcon, ...props })
)

const historyIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveHistoryIcon, ...props }),
  props => ({ Component: InactiveHistoryIcon, ...props })
)

const settingsIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveSettingsIcon, ...props }),
  props => ({ Component: InactiveSettingsIcon, ...props })
)

const userIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveUserIcon, ...props }),
  props => ({ Component: InactiveUserIcon, ...props })
)

const companyIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveCompanyIcon, ...props }),
  props => ({ Component: InactiveCompanyIcon, ...props })
)

const refundIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveMvoRefundIcon, ...props }),
  props => ({ Component: InactiveMvoRefundIcon, ...props })
)

const giftIcon = ifElse(
  propEq('status', 'active'),
  props => ({ Component: ActiveGiftIcon, ...props }),
  props => ({ Component: InacitveGiftIcon, ...props })
)

const resolveInactive = props => resolveIcon({ status: 'inactive', ...props })

const resolveActive = props => resolveIcon({ status: 'active', ...props })

const resolveState = cond([
  [propEq('state', 'entering'), resolveActive],
  [propEq('state', 'entered'), resolveActive],
  [propEq('state', 'exiting'), resolveInactive],
  [T, resolveInactive],
])

const resolveIcon = cond([
  [propEq('icon', 'card'), cardIcon],
  [propEq('icon', 'history'), historyIcon],
  [propEq('icon', 'user'), userIcon],
  [propEq('icon', 'company'), companyIcon],
  [propEq('icon', 'refund'), refundIcon],
  [propEq('icon', 'gift'), giftIcon],
  [T, settingsIcon],
])

const ifActiveResolveIconIfNotResolveState = ifElse(
  prop('active'),
  resolveActive,
  resolveState
)

const svgToString = ({ Component, color }) =>
  encodeURIComponent(
    ReactDOMServer.renderToStaticMarkup(<Component color={color} />)
  )

const stringToDataURI = str =>
  `background: url("data:image/svg+xml,${str}") no-repeat center`

const resolveSVGBackground = pipe(
  ifActiveResolveIconIfNotResolveState,
  svgToString,
  stringToDataURI
)

const resolveActiveIcon = ifElse(identity, always('white'), always('gray'))

const Icon = styled.i`
  width: 20px;
  height: 20px;
  transition: background 150ms ease;
  ${resolveSVGBackground}
`

const SidebarItemStyle = styled.div`
  margin-bottom: 5px;
  font-size: 14px;
  height: 40px;
  color: ${COLORS.GRAY};
  font-weight: 600;
  text-decoration: none;
  transition: all 0.15s ease;
  display: flex;
  align-items: center;
  line-height: 1.1;
  ${resolveActiveOrButton}
  ${resolveActiveStyle}
  ${resolveButton}
`

const renderTransition = curryN(3, (active, icon, state) => (
  <Icon
    state={state}
    icon={icon}
    active={active}
    color={resolveActiveIcon(active)}
  />
))

const IconWithTransition = ({ active, hovered, icon }) => (
  <Transition in={hovered} timeout={150}>
    {renderTransition(active, icon)}
  </Transition>
)

const SidebarItem = ({ children, active, ...props }) => (
  <SidebarItemStyle active={active} {...props}>
    <IconWithTransition active={active} {...props} />
    {children}
  </SidebarItemStyle>
)

export default SidebarItem
