import { LOGOUT_SUCCEEDED } from '../auth/types'
import {
  FETCH_USER_ADDRESSES_SUCCEEDED,
  ADD_USER_ADDRESSES_SUCCEEDED,
  DELETE_USER_ADDRESSES_SUCCEEDED,
} from '../userAddresses'

const initialState = {}

const reducer = (state = initialState, action = {}) => {
  const { dbRes } = action

  if (dbRes && dbRes.userMe) {
    const {
      dbRes: { userMe },
    } = action
    const companies = userMe.companies.map(c => c.id)

    return { ...state, companies }
  }

  switch (action.type) {
    case LOGOUT_SUCCEEDED:
      return initialState

    case FETCH_USER_ADDRESSES_SUCCEEDED:
      return {
        ...state,
        addresses: action.addresses.map(a => a.id),
      }

    case ADD_USER_ADDRESSES_SUCCEEDED:
      return {
        ...state,
        addresses: [...state.addresses, ...action.addresses.map(a => a.id)],
      }

    case DELETE_USER_ADDRESSES_SUCCEEDED:
      return {
        ...state,
        addresses: state.addresses.filter(id => id !== action.addresseId),
      }

    default:
      return state
  }
}

export default reducer
