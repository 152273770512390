import React from 'react'
import styled from 'styled-components'

import { media, HEADER } from '@themenu/ui'

import { pickChildren } from '@themenu/ui/src/components/utils'

const FixedHeaderWrapper = styled.div`
  position: fixed;
  width: 100%;
  z-index: 1000;
`

const FixedBodyWrapper = styled.div`
  height: calc(100vh - ${HEADER.HEIGHT});
  position: absolute;
  top: ${HEADER.HEIGHT};
  width: 100%;

  ${media.XS`
    height: calc(100vh - ${HEADER.XS_HEIGHT});
    top: ${HEADER.XS_HEIGHT};
  `};

  ${media.SM`
    height: calc(100vh - ${HEADER.SM_HEIGHT});
    top: ${HEADER.SM_HEIGHT};
  `};
`

FixedHeaderWrapper.displayName = 'FixedHeaderWrapper'
FixedBodyWrapper.displayName = 'FixedBodyWrapper'

function FixedHeaderLayout({ children }) {
  const header = pickChildren(FixedHeader, children)
  const body = pickChildren(FixedBody, children)

  return (
    <React.Fragment>
      <FixedHeaderWrapper>{header}</FixedHeaderWrapper>
      <FixedBodyWrapper>{body}</FixedBodyWrapper>
    </React.Fragment>
  )
}

function FixedHeader({ children }) {
  return children
}

function FixedBody({ children }) {
  return children
}

export { FixedHeaderLayout as default, FixedHeader, FixedBody }
