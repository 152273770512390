import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'
import { Sidebar } from '@themenu/ui'
import { hasActivateAnonymousCardAccessSelector } from '../../../modules/auth-selectors'

export const SidebarUser = ({ userName, hasActivateAnonymousCardAccess }) => (
  <Sidebar
    nav={[
      {
        to: '/profile',
        label: (
          <FormattedMessage
            id="UserSidebar.profile"
            defaultMessage="Modifier mon profil"
          />
        ),
      },
      {
        to: '/payments/swile-card',
        label: (
          <FormattedMessage
            id="UserSidebar.card"
            defaultMessage="Cartes Swile"
          />
        ),
      },
      hasActivateAnonymousCardAccess
        ? {
            to: '/anonymous-card-activate',
            label: (
              <FormattedMessage
                id="UserSidebar.activateCard"
                defaultMessage="Activer une carte anonyme"
              />
            ),
          }
        : null,
      {
        to: '/payments/credit-cards',
        label: (
          <FormattedMessage
            id="UserSidebar.payments"
            defaultMessage="Moyens de paiement"
          />
        ),
      },
    ].filter(x => x)}
  >
    <h2 className="Sidebar__Title">{userName}</h2>
  </Sidebar>
)

SidebarUser.defaultProps = {
  creditCards: [],
}

const enhance = compose(
  withRouter,
  connect(state => ({
    userName: `${state.getIn(['auth', 'user', 'first_name'])} ${state.getIn([
      'auth',
      'user',
      'last_name',
    ])}`,
    hasActivateAnonymousCardAccess: hasActivateAnonymousCardAccessSelector(
      state
    ),
  }))
)

export default enhance(SidebarUser)
