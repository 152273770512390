import React from 'react'
import { Route } from 'react-router-dom'

const AppRedirect = ({ origin, path, ...routeProps }) => {
  return (
    <Route
      path={path}
      {...routeProps}
      render={() => {
        const href = window.location.href.replace(
          window.location.origin,
          origin
        )
        window.location.replace(path ? href.replace(path, '') : href)
        return null
      }}
    />
  )
}

export default AppRedirect
