import React from 'react'
import Loadable from 'react-loadable'
import { lifecycle, compose } from 'recompose'
import { withRouter } from 'react-router-dom'
import LoadingBar from '../components/LoadingBar/LoadingBar'
import Cookies from '@themenu/shared/lib/services/cookies'

/**
 * Use case :
 *  - the user is on the website v150
 *  - we are deploying v151
 *  - the user visit the page P, the chunk does not exist anymore -> 404
 *  - solution -> reload the page !
 */
const Reload = compose(
  withRouter,
  lifecycle({
    componentDidMount() {
      // don't reload endlessly with 30s cookie
      const noReload = Cookies.get('noreload:timeout')
      if (!noReload) {
        Cookies.set('noreload:timeout', 'yes', {
          maxAge: 30
        })
        window.location.reload()
      }
    }
  })
)(() => null)

export const Async = (loader, opts) =>
  Loadable({
    loading: ({ error, pastDelay }) =>
      error ? <Reload /> : pastDelay ? <LoadingBar /> : null,
    loader,
    pastDelay: 200, // https://github.com/jamiebuilds/react-loadable#avoiding-flash-of-loading-component
    ...opts
  })

export default Async
