import React from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Wrapper from './Wrapper'
import {
  hasActivateAnonymousCardAccessSelector,
  hasMealVoucherProgramSelector,
  hasGiftProgramSelector,
  hasMealVoucherBalanceSelector,
  hasLunchrCardSelector,
} from '../../../../modules/auth-selectors'

const GIFT_APP_URL = process.env.REACT_APP_CORPO_GIFT_APP_URL

const historyLabel = (
  <FormattedMessage
    id="UserSidebar.new.history"
    defaultMessage="Commandes & transactions"
  />
)
const giftAppLabel = (
  <FormattedMessage id="UserSidebar.gift" defaultMessage="Mes avantages" />
)
const lunchrCardLabel = (
  <FormattedMessage id="UserSidebar.card" defaultMessage="Cartes Swile" />
)
const activateCardLabel = (
  <FormattedMessage
    id="UserSidebar.activateCard"
    defaultMessage="Activer une carte anonyme"
  />
)
const profileLabel = (
  <FormattedMessage id="UserSidebar.new.profile" defaultMessage="Préférences" />
)
const creditCardsLabel = (
  <FormattedMessage
    id="UserSidebar.payments"
    defaultMessage="Moyens de paiement"
  />
)
const title = (
  <FormattedMessage id="UserSidebar.new.title" defaultMessage="Mon compte" />
)
const creditCardsLink = {
  to: '/payments/credit-cards',
  icon: 'card',
  label: creditCardsLabel,
}

const mvHistoryLink = {
  to: '/history/mealvouchers',
  icon: 'history',
  activePath: '/history/*',
  label: historyLabel,
}

const profileLink = {
  to: '/profile',
  icon: 'settings',
  label: profileLabel,
}

const activateSwileCardLink = {
  to: '/anonymous-card-activate',
  icon: 'card',
  label: activateCardLabel,
}

const swileCardLink = {
  to: '/payments/swile-card',
  icon: 'card',
  label: lunchrCardLabel,
}

const giftAppLink = {
  to: GIFT_APP_URL,
  icon: 'gift',
  label: giftAppLabel,
  absolute: true,
}

const buildNav = ({
  hasActivateAnonymousCardAccess,
  hasMealVoucherProgram,
  hasGiftProgram,
  hasMealVoucherBalance,
  hasLunchrCard,
}) => {
  const nav = [profileLink]
  if (hasLunchrCard || hasMealVoucherProgram) {
    nav.push(swileCardLink)
  }
  if (hasActivateAnonymousCardAccess) {
    nav.push(activateSwileCardLink)
  }

  if (hasMealVoucherProgram || hasMealVoucherBalance) {
    nav.push(creditCardsLink)
    nav.unshift(mvHistoryLink)
  }
  if (hasGiftProgram) {
    nav.push(giftAppLink)
  }

  return nav
}

const Sidebar = ({
  hasActivateAnonymousCardAccess,
  hasMealVoucherProgram,
  hasGiftProgram,
  hasMealVoucherBalance,
  hasLunchrCard,
}) => (
  <Wrapper
    title={title}
    nav={buildNav({
      hasActivateAnonymousCardAccess,
      hasMealVoucherProgram,
      hasGiftProgram,
      hasMealVoucherBalance,
      hasLunchrCard,
    })}
  />
)

const mapStateToProps = state => ({
  hasActivateAnonymousCardAccess: hasActivateAnonymousCardAccessSelector(state),
  hasMealVoucherProgram: hasMealVoucherProgramSelector(state),
  hasMealVoucherBalance: hasMealVoucherBalanceSelector(state),
  hasGiftProgram: hasGiftProgramSelector(state),
  hasLunchrCard: hasLunchrCardSelector(state),
})
const enhance = connect(mapStateToProps)

export default enhance(Sidebar)
