import SharedAsync from '@themenu/ui/src/services/Async'

export const Async = (loader, opts) => SharedAsync(loader, opts)

export const AsyncPreferences = Async(() => import('./Profile/Preferences'))
export const AsyncCardOnboarding = Async(() => import('./CardOnboarding'))
export const AsyncAnonymousCardOnboarding = Async(() =>
  import('./AnonymousCardOnboarding')
)
export const AsyncHistory = Async(() => import('./History/History'))
export const AsyncDashboard = Async(() => import('./Dashboard'))
export const AsyncConfirmAccount = Async(() => import('./ConfirmAccount'))
export const AsyncStayhungry = Async(() => import('./Stayhungry'))
export const AsyncForgotPassword = Async(() => import('./ForgotPassword'))
export const AsyncResetPassword = Async(() => import('./ResetPassword'))
export const AsyncPageNotFound = Async(() => import('./PageNotFound'))
export const AsyncLegalNotice = Async(() =>
  import('./TermsOfUse/LegalNoticeScreen')
)
export const AsyncPrivacyPolicy = Async(() => import('./PrivacyPolicy'))
export const AsyncKycAppRedirect = Async(() =>
  import('./KycAppRedirect/KycAppRedirectScreen')
)
export const AsyncGeneralConditionsOfService = Async(() =>
  import('./GeneralConditionsOfService')
)
export const AsyncPayments = Async(() => import('./Payments'))
export const AsyncConfirmExternalRequest = Async(() =>
  import('./ConfirmExternalRequest')
)

export const AsyncTaxReceiptAddress = Async(() => import('./TaxReceiptAddress'))
