import mainTheme from './main'

export const main = {
  ...mainTheme,
  flexboxgrid: {
    // Defaults
    gutterWidth: 1, // rem
    outerMargin: 2, // rem
    container: {
      sm: 44, // rem
      md: 60, // rem
      lg: 71, // rem
    },
    breakpoints: {
      xs: 0, // em
      sm: 48, // em
      md: 64, // em
      lg: 75, // em
    },
  },
}

export default { main }
