import React, { useContext, useEffect } from 'react'
import { Formik } from 'formik'
import styled from 'styled-components'
import qs from 'query-string'
import Grid from '@material-ui/core/Grid'
import { ReferralSummaryRoute } from '../../../../routes'
import { Button, media } from '@themenu/ui'
import { hasErrors } from '../../common/helpers/hasErrors'
import { isDisabledContact } from '../../common/helpers/isDisabledContact'
import { isValidLeader } from '../../common/helpers/isValidLeader'
import { getColleagueCount } from '../../common/helpers/getColleagueCount'
import { getRewardPerPerson } from '../../common/helpers/getRewardPerPerson'
import { colleaguesSchema } from '../../common/StateSchema'
import { ReferralContext } from '../../ReferralContext'
import FormColleague from '../FormColleague/FormColleague'
import ReferralFlags from '../ReferralFlags/RefferalFlags'

const StyledButton = styled(Button)`
  margin-top: 4px;
  width: 100%;

  ${media.SM`
    width: auto;
  `}
`

const ButtonFlagsContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;

  ${media.SM`
    flex-direction: column;
    align-items: flex-end;
  `}
`

function FormUser({ history }) {
  const { state, setState } = useContext(ReferralContext)
  useEffect(() => {
    if (history.location.search) {
      const params = qs.parse(history.location.search)
      setState({
        ...state,
        leader: {
          ...state.leader,
          ...params,
        },
      })
    }
  }, [history.location.search])

  return (
    <div>
      <Formik
        initialValues={state}
        validateOnChange={true}
        enableReinitialize={true}
        validationSchema={colleaguesSchema}
        onSubmit={values => {
          const invitees_count = getColleagueCount(values, {})
          setState({
            ...values,
            invitees_count,
            card_amount: getRewardPerPerson(invitees_count),
          })
          history.push(ReferralSummaryRoute.spec)
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => {
          const disabled =
            !isValidLeader(values.leader) ||
            getColleagueCount(values, errors) === 0 ||
            hasErrors(errors)

          const commonProps = {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }

          return (
            <form method="post" onSubmit={handleSubmit}>
              <Grid container spacing={8}>
                <Grid item xs={12}>
                  <FormColleague
                    id={1}
                    disabled={isDisabledContact('contact_1', values, errors)}
                    {...commonProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormColleague
                    id={2}
                    disabled={isDisabledContact('contact_2', values, errors)}
                    {...commonProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormColleague
                    id={3}
                    disabled={isDisabledContact('contact_3', values, errors)}
                    {...commonProps}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormColleague
                    id={4}
                    disabled={isDisabledContact('contact_4', values, errors)}
                    {...commonProps}
                  />
                </Grid>
              </Grid>
              <ButtonFlagsContainer>
                <StyledButton
                  color="green"
                  size="large"
                  type="submit"
                  disabled={disabled}
                >
                  Valider
                </StyledButton>
                <ReferralFlags />
              </ButtonFlagsContainer>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}

export default FormUser
