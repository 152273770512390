import React from 'react'
import {
  ErrorBoundary,
  DEFAULT_LOCALE,
  MuiLightTheme as MuiThemeProvider,
} from '@themenu/ui'
import { detectBrowserLocale } from '@themenu/shared/lib/helpers/locale'
import { IntlProvider, addLocaleData } from 'react-intl'
import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import localeData from '../i18n/locales'

const locale = detectBrowserLocale()
const messages = localeData[locale]
const formats = {
  number: {
    EUR: { style: 'currency', currency: 'EUR' },
    percent: { style: 'percent' },
  },
}
addLocaleData([...en, ...fr])

const MainAppContainer = ({ children }) => (
  <IntlProvider
    locale={locale}
    defaultLocale={DEFAULT_LOCALE}
    messages={messages}
    formats={formats}
  >
    <MuiThemeProvider>
      <ErrorBoundary>{children}</ErrorBoundary>
    </MuiThemeProvider>
  </IntlProvider>
)

export default MainAppContainer
