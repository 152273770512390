import React from 'react'
import { Helmet } from 'react-helmet'

import Toaster from './Toaster'
import ToasterHandler from '../LayoutNew/ToastersHandler'
import styled from 'styled-components'
import {
  COLORS,
  IconButton,
  Typography,
  useTheme,
} from '@themenu/design-system'
import { FormattedMessage } from 'react-intl'

import GradientBg from './gradient-bg.jpg'

const Container = styled.div`
  min-height: 100vh;
  background: url(${GradientBg});
  background-size: cover;
  display: flex;
  position: relative;
  overflow: hidden;
  font-family: Graphik, serif;
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  min-height: 100vh;
`

const Header = styled.div`
  width: 100%;
`

const Content = styled.div`
  width: 720px;
  background: white;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: center;
  padding: 62px;
`

const Footer = styled.div`
  width: 100%;
  font-size: 0.9rem;
`

const FooterDivider = styled.div`
  height: 2px;
  width: 100%;
  background: ${COLORS.GRADIENTS.RAINBOW};
`

const FooterLink = styled.a`
  color: ${props => props.color};
  text-decoration: underline;
  margin-left: 8px;
`

const NewAuthLayout = ({ children }) => {
  const theme = useTheme()

  const goBack = () => {
    history.back()
  }

  return (
    <Container>
      <Helmet>
        <title>Swile</title>
      </Helmet>
      <Toaster />

      <ToasterHandler />
      <Wrapper>
        <Header>
          <IconButton
            component="a"
            onClick={goBack}
            name="interface_arrow-left"
            size={32}
            mt="54px"
            ml="44px"
            variant="text"
          />
        </Header>
        <Content>{children}</Content>
        <Footer>
          <FooterDivider />
          <Typography
            variant="body"
            color={theme.invertedColors.content.default}
            mx="40px"
            my="32px"
          >
            <FormattedMessage
              id="recover_card.layout.contact_us"
              defaultMessage="Un problème ou une question ? {StyledLink}"
              values={{
                StyledLink: (
                  <FooterLink
                    color={theme.invertedColors.content.default}
                    href="https://help.swile.co/hc/fr"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FormattedMessage
                      id="recover_card.layout.contact_us.contact"
                      defaultMessage="Contactez-nous"
                    />
                  </FooterLink>
                ),
              }}
            />
          </Typography>
        </Footer>
      </Wrapper>
    </Container>
  )
}

export default NewAuthLayout
