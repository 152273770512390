import React from 'react'
import { Helmet } from 'react-helmet'

import Header from './Header'
import Toaster from './Toaster'
import { Gradient } from '@themenu/ui'
import ToasterHandler from '../LayoutNew/ToastersHandler'
import styled from 'styled-components'

const AuthLayoutStyled = styled.div`
  ${Gradient}
  min-height: 100vh;
`

const AuthLayout = ({ children, noHeader, showMenu }) => {
  return (
    <AuthLayoutStyled>
      <Helmet>
        <title>Swile</title>
      </Helmet>
      {!noHeader && (
        <Header
          transparent
          showMenu={showMenu}
          showSearchBar={false}
          whiteHeader
        />
      )}
      <Toaster />
      <ToasterHandler />

      {children}
    </AuthLayoutStyled>
  )
}

export default AuthLayout
