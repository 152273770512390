/* eslint-disable */
window.SmartPinJS = window.SmartPinJS || {
  _ConsumerId: '',
  _RequestRef: '',
  _Callback: function() {},
  _IsCalling: !1,
  _Parameters: '',
  _URLDomain: '',
  _Time: 0,
  getPin: function(r, t, n, e, i, a, s, o, c) {
    this._IsCalling
      ? c('', !1, 'Pin request already being processed')
      : ((this._IsCalling = !0),
        (this._ConsumerId = n),
        (this._RequestRef = t),
        (this._Callback = c),
        (this._URLDomain = r),
        (this._Time = a),
        (this._Parameters =
          'Reference=' +
          n +
          '&Customer_REF=' +
          e +
          '&ControlValue=' +
          i +
          '&TIME=' +
          a +
          '&MAC=' +
          s +
          '&Encrypted=' +
          (o ? '1' : '0')),
        this._jsonp(
          r + '/GetPin.aspx',
          'window.SmartPinJS.OnSuccess',
          this._Parameters
        ))
  },
  OnSuccess: function(r) {
    if (((SmartPinJS._IsCalling = !1), 0 == r.ErrorCode)) {
      var t = this._DecryptPin(
        r.EncryptedPin,
        '' == SmartPinJS._RequestRef
          ? SmartPinJS._ConsumerId
          : SmartPinJS._RequestRef,
        SmartPinJS._Time
      )
      SmartPinJS._IsPinBlockOk(t)
        ? SmartPinJS._AckAndCallback(
            SmartPinJS._PinBlockToPin(t),
            r.RequestID,
            !0,
            ''
          )
        : SmartPinJS._AckAndCallback(
            '',
            r.RequestID,
            !1,
            'Error: could not decrypt PIN'
          )
    } else SmartPinJS._Callback('', !1, 'Error: ' + r.ErrorMessage)
  },
  _AckAndCallback: function(r, t, n, e) {
    SmartPinJS._Callback(r, n, e),
      SmartPinJS._jsonp(
        SmartPinJS._URLDomain + '/AckPin.aspx',
        'SmartPinJS.OnAck',
        this._Parameters +
          '&RequestId=' +
          t +
          '&Ack=' +
          (n ? '1' : '0') +
          '&ErrorMessage=' +
          e
      )
  },
  OnAck: function(r) {},
  _jsonp: function(r, t, n) {
    r.indexOf('?') > -1 ? (r += '&callback=') : (r += '?callback='),
      (r += t + '&'),
      n && (r += n + '&_='),
      (r += new Date().getTime().toString())
    var e = document.createElement('script')
    e.setAttribute('src', r),
      e.setAttribute('type', 'text/javascript'),
      document.body.appendChild(e)
  },
  _DecryptPin: function(r, t, n) {
    for (var e = [], i = 0; i < r.length; i += 2)
      e.push(parseInt(r.substr(i, 2), 16))
    for (var a = [], i = 0; i < t.length; ++i) a.push(t.charCodeAt(i))
    for (var s = a.length; a.length < 8; ) a.push(8 - s)
    var o = this._intToByteArray(n)
    o = o.concat(o)
    for (var c = [], i = 0; i < 8; ++i) c.push(e[i] ^ a[i] ^ o[i])
    return this._ByteArrayToHexString(c)
  },
  _AsciiStringToHexString: function(r) {
    var t,
      n = []
    for (t = 0; t < r.length; t++) n.push(r.charCodeAt(t).toString(16))
    return n.join('')
  },
  _HexStringToAsciiString: function(r) {
    for (var t = r.toString(), n = '', e = 0; e < t.length; e += 2)
      n += String.fromCharCode(parseInt(t.substr(e, 2), 16))
    return n
  },
  _addZeroBefore: function(r) {
    return (r < 10 ? '0' : '') + r
  },
  _addZeroBefore_Hex: function(r) {
    return (r < 16 ? '0' : '') + r.toString(16)
  },
  _PinBlockToPin: function(r) {
    return this._IsPinBlockOk(r)
      ? r.substring(2, 2 + parseInt(r[1], 16))
      : 'Error: could not decrypt PIN'
  },
  _IsPinBlockOk: function(r) {
    if ('1' != r[0]) return !1
    var t = parseInt(r[1], 16)
    if (isNaN(t) || t > 14) return !1
    for (i = 0; i < t; i++) {
      var n = parseInt(r[i + 2], 16)
      if (isNaN(n) || n > 9) return !1
    }
    for (var e = 0, i = 0; i < r.length / 2; i++)
      e ^= parseInt(r[2 * i] + r[2 * i + 1], 16)
    return 0 == e
  },
  _intToByteArray: function(r) {
    for (var t = new Array(4), n = 0; n < t.length; n++) {
      var e = 255 & r
      ;(t[n] = e), (r = (r - e) / 256)
    }
    return t.reverse()
  },
  _ByteArrayToHexString: function(r) {
    for (var t = [], n = 0; n < r.length; n++)
      t.push(this._addZeroBefore_Hex(r[n]))
    return t.join('')
  }
}

export default SmartPinJS
