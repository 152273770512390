import { connect } from 'react-redux'
import get from 'lodash/get'
import pick from 'lodash/pick'
import { compose, lifecycle } from 'recompose'
import { userSelector } from '../modules/auth-selectors'

const { Raven } = window
const {
  REACT_APP_SOURCE_VERSION,
  REACT_APP_SENTRY_ENVIRONMENT,
  REACT_APP_SENTRY_CREDENTIALS,
} = process.env

const SentryEnabled =
  Raven && REACT_APP_SENTRY_ENVIRONMENT && REACT_APP_SENTRY_CREDENTIALS

if (SentryEnabled) {
  const SENTRY_ENVIRONMENT = REACT_APP_SENTRY_ENVIRONMENT
  const SENTRY_CREDENTIALS = REACT_APP_SENTRY_CREDENTIALS
  const SENTRY_RELEASE = REACT_APP_SOURCE_VERSION || '0.1.0'

  Raven.config(SENTRY_CREDENTIALS, {
    environment: SENTRY_ENVIRONMENT,
    release: SENTRY_RELEASE,
  }).install()
}

function EmptyComponent() {
  return null
}

function setUserContext(user) {
  if (SentryEnabled) {
    const context = user ? pick(user, 'id', 'email') : { id: null, email: null }
    Raven.setUserContext(context)
  }
}

export default compose(
  connect(state => ({
    user: userSelector(state),
  })),
  lifecycle({
    componentDidMount() {
      setUserContext(this.props.user)
    },
    UNSAFE_componentWillReceiveProps({ user }) {
      if (get(this.props, 'user.id', null) !== get(user, 'id', null)) {
        setUserContext(user)
      }
    },
  })
)(EmptyComponent)
