import Auth from '@themenu/shared/lib/services/auth'

import { request as apiRequest } from './api'
import jwtDecode from 'jwt-decode'

const API_ENDPOINT = process.env.REACT_APP_BANKING_API_ENDPOINT

const request = async (
  method,
  endpoint,
  data = undefined,
  withEndpointPrefix = true
) => {
  const { banking_account_uuid } = jwtDecode(Auth.token)
  const normalizedEndpoint = withEndpointPrefix
    ? `accounts/${banking_account_uuid}/${endpoint}`
    : endpoint
  return apiRequest(method, normalizedEndpoint, data, {}, API_ENDPOINT)
}

export const fetchLunchrCards = () =>
  request('GET', `lunchr_cards`, { include_virtual_card: 1 })

export const activateLunchrCard = (uuid, token) =>
  request('POST', `lunchr_cards/${uuid}`, {
    lunchr_card: {
      public_token: token,
    },
  })

export const blockLunchrCard = uuid =>
  request('PUT', `lunchr_cards/${uuid}/block`)

export const unblockLunchrCard = uuid =>
  request('PUT', `lunchr_cards/${uuid}/unblock`)

export const enableNfcLunchrCard = uuid =>
  request('PUT', `lunchr_cards/${uuid}/enable_nfc`)

export const disableNfcLunchrCard = uuid =>
  request('PUT', `lunchr_cards/${uuid}/disable_nfc`)

export const enableTopupLunchrCard = (uuid, stripeCardId, amountLimit) =>
  request('PUT', `lunchr_cards/${uuid}/enable_topup`, {
    topup: {
      stripe_card_id: stripeCardId,
      amount_limit: amountLimit,
    },
  })

export const disableTopupLunchrCard = uuid =>
  request('PUT', `lunchr_cards/${uuid}/disable_topup`)

export const revealPinLunchrCard = ({ uuid, token }) => {
  return request('POST', `lunchr_cards/${uuid}/reveal_pin`, {
    lunchr_card: {
      public_token: token,
    },
  })
}

export const startRevealPinProcess = ({ uuid, token }) => {
  return request('POST', `lunchr_cards/${uuid}/start_reveal_pin_process`, {
    lunchr_card: {
      public_token: token,
    },
  })
}

export const markLunchrCardAsLost = uuid => {
  return request('PUT', `lunchr_cards/${uuid}/mark_as_lost`)
}

export const unlockCardPIN = uuid =>
  request('PUT', `lunchr_cards/${uuid}/unblock_pin`)

export const fetchPaymentsHistory = ({ before, per = 10 }) =>
  request('GET', 'payments_history', { before, per }, false)
