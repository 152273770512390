import { fromJS } from 'immutable'
import Api from '../../services/api'

import { LOGIN_SUCCEEDED } from '../../modules/auth/types'
import { loginSucceeded } from '../../modules/auth'
import { showIntl as showIntlToastMessage } from './toast-message'

const FETCH_ACCOUNT_CONFIRMATION =
  'app/screens/confirmAccount/FETCH_ACCOUNT_CONFIRMATION'
const FETCH_ACCOUNT_CONFIRMATION_SUCCEEDED =
  'app/screens/confirmAccount/FETCH_ACCOUNT_CONFIRMATION_SUCCEEDED'
const FETCH_ACCOUNT_CONFIRMATION_FAILED =
  'app/screens/confirmAccount/FETCH_ACCOUNT_CONFIRMATION_FAILED'

const VALIDATE_ACCOUNT = 'app/screens/confirmAccount/VALIDATE_ACCOUNT'

const initialState = fromJS({
  fetchError: null,
  formError: null,
  submitting: false,
  fetching: false,
})

/**
 * Action creator that checks if the confirmation token is valid
 * @param  {String} token
 */
export const fetchAccountConfirmation = token => async dispatch => {
  try {
    dispatch({ type: FETCH_ACCOUNT_CONFIRMATION })
    await Api.validatePasswordRecoveryToken(token)
    dispatch(fetchAccountConfirmationSucceeded())
  } catch (error) {
    console.error(error)
    dispatch(fetchAccountConfirmationFailed(error.result.error))
  }
}

const fetchAccountConfirmationSucceeded = () => ({
  type: FETCH_ACCOUNT_CONFIRMATION_SUCCEEDED,
})

const fetchAccountConfirmationFailed = error => ({
  type: FETCH_ACCOUNT_CONFIRMATION_FAILED,
  error,
})

/**
 * Action creator to validate an account (reset password)
 * @param  {String} token
 * @param  {String} password
 */
export const validateAccount = ({ token, password }) => async dispatch => {
  try {
    dispatch({ type: VALIDATE_ACCOUNT })
    const { user } = await Api.resetPassword({ token, password }, true)
    const cleanedCompanies = user.companies.map(company => ({
      ...company,
      id: company.uuid,
    }))
    const cleanedUser = { ...user, companies: cleanedCompanies }
    dispatch(loginSucceeded({ user: cleanedUser }))

    return user
  } catch (error) {
    console.error(error)
    dispatch(
      showIntlToastMessage('cannotLogYouIn', {}, 0, { errorModal: true })
    )
    throw new Error(error)
  }
}

export default function confirmAccountReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ACCOUNT_CONFIRMATION:
      return state.set('fetching', true)

    case FETCH_ACCOUNT_CONFIRMATION_SUCCEEDED:
      return state.withMutations(s =>
        s.set('fetchError', null).set('fetching', false)
      )

    case FETCH_ACCOUNT_CONFIRMATION_FAILED:
      return state.withMutations(s =>
        s.set('fetchError', action.error).set('fetching', false)
      )

    // Action to validate the account
    case VALIDATE_ACCOUNT:
      return state.withMutations(s =>
        s.set('formError', null).set('submitting', true)
      )

    case LOGIN_SUCCEEDED:
      return state.withMutations(s =>
        s.set('formError', null).set('submitting', false)
      )

    default:
      return state
  }
}
