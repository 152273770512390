import React from 'react'
import styled from 'styled-components'
import { Emoji, media } from '@themenu/ui'

const Title = styled.h2`
  font-size: 24px;
  line-height: 30px;
  color: #1b2733;
  margin-bottom: 32px;

  ${media.SM`
    font-size: 48px;
    line-height: 52px;
    padding-bottom: 60px;
  `}
`
const StyledEmoji = styled(Emoji)`
  display: none;

  ${media.SM`
    display: inline-block;
  `}
`

export function Header({ firstname }) {
  return (
    <div>
      <Title>
        {firstname}, vous y êtes presque ! Plus qu’une dernière étape pour vous.{' '}
        <StyledEmoji emoji="sunglasses" size="38px" />
      </Title>
    </div>
  )
}
