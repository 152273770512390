import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { path } from 'ramda'
import Grid from '@material-ui/core/Grid'

import { RewardBadge } from '../RewardBadge'
import FormTitle from '../FormTitle/FormTitle'
import { FormInput } from '../../common/components/FormInput'
import { GridItem } from '../../common/components/GridItem'
import { getColleagueCount } from '../../common/helpers/getColleagueCount'
import { ReferralContext } from '../../ReferralContext'

const StyledFormColleagueWrap = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
`

const StyledInlineWrap = styled.div`
  display: inline-flex;
  align-items: center;
  margin-bottom: 15px;
`

function FormColleague({
  id,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  disabled,
}) {
  const contactKey = `contact_${id}`
  const isValid =
    !disabled &&
    !path([contactKey, 'email'], errors) &&
    !path([contactKey, 'firstname'], errors) &&
    path([contactKey, 'email'], values) &&
    path([contactKey, 'firstname'], values)
  const commonProps = {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
  }
  const { setState, setErrors } = useContext(ReferralContext)
  const focusColleague = getColleagueCount(values, errors)

  useEffect(() => {
    setState(values)
    setErrors(errors)
  }, [focusColleague])

  return (
    <StyledFormColleagueWrap>
      <StyledInlineWrap>
        <FormTitle title={`Collègue n°${id}`} />
        <RewardBadge id={id} focus={focusColleague} isValid={isValid} />
      </StyledInlineWrap>
      <Grid container spacing={16}>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <FormInput
            type={'text'}
            name={`contact_${id}.firstname`}
            title={'Son prénom*'}
            disabled={disabled}
            {...commonProps}
          />
        </GridItem>
        <GridItem xs={12} sm={12} md={12} lg={6}>
          <FormInput
            type={'email'}
            name={`contact_${id}.email`}
            title={'Son adresse email pro*'}
            disabled={disabled}
            {...commonProps}
          />
        </GridItem>
      </Grid>
    </StyledFormColleagueWrap>
  )
}

export default FormColleague
