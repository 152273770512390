export const LOCATION_CHANGE = 'router/LOCATION_CHANGE'

export const changeLocation = (location, action = null, params = {}) => ({
  type: LOCATION_CHANGE,
  location,
  action,
  params,
})

const initialState = {
  location: {
    pathname: '',
  },
  params: {},
}
const reducer = (state = initialState, action) => {
  if (action.type === LOCATION_CHANGE) {
    return {
      location: action.location,
      params: action.params,
    }
  }

  return state
}

export default reducer
