import { MAIN_FONT, SCREEN_SIZES, COLORS } from '@themenu/ui'

export default {
  colors: Object.keys(COLORS).reduce(
    (acc, colorName) => ({
      ...acc,
      [colorName]: COLORS[colorName],
      [colorName.toLowerCase()]: COLORS[colorName],
    }),
    {}
  ),

  fonts: {
    main: MAIN_FONT,
  },

  breakpoints: [
    SCREEN_SIZES.XS,
    SCREEN_SIZES.SM,
    SCREEN_SIZES.MD,
    SCREEN_SIZES.LG,
  ],
}
