import { createSelector } from 'reselect'

import { LUNCHR_CARD_TYPE } from '../../constants'

export const dbLunchrCardsSelector = state => state.get('dbjs').lunchrCards

export const uiLunchrCardsSelector = state => state.get('uicorpo').myLunchrCards

export const lunchrCardsSelector = createSelector(
  dbLunchrCardsSelector,
  uiLunchrCardsSelector,
  (dbLunchrCards, uiLunchrCards) => {
    return uiLunchrCards.cards.map(uuid => dbLunchrCards[uuid])
  }
)

export const onlyMainLunchrCardSelector = createSelector(
  lunchrCardsSelector,
  lunchrCards => {
    if (!lunchrCards) return undefined

    return lunchrCards.find(lc => lc.card_type === LUNCHR_CARD_TYPE.MAIN)
  }
)

export const mainLunchrCardSelector = createSelector(
  lunchrCardsSelector,
  lunchrCards => {
    if (!lunchrCards) return undefined

    return lunchrCards.find(
      lc =>
        lc.card_type === LUNCHR_CARD_TYPE.MAIN ||
        lc.card_type === LUNCHR_CARD_TYPE.ANONYMOUS
    )
  }
)

export const hasOnlyVirtualCardSelector = createSelector(
  lunchrCardsSelector,
  lunchrCards => {
    if (!lunchrCards) return false

    return (
      lunchrCards.length === 1 &&
      lunchrCards.find(lc => lc.card_type === LUNCHR_CARD_TYPE.VIRTUAL)
    )
  }
)

// Return the maincard or the anonymous if the has one, if not just return the first card
// Usefull because some user have a safety card without main/anonymous card
export const mainLunchrCardOrSafetyCardSelector = createSelector(
  lunchrCardsSelector,
  mainLunchrCardSelector,
  (lunchrCards, mainLunchrCard) => mainLunchrCard || lunchrCards[0]
)

export const mainLunchrCardSimpleSelector = createSelector(
  mainLunchrCardSelector,
  mainLunchrCard => {
    if (mainLunchrCard) {
      return { active: mainLunchrCard.activated_at !== null }
    }

    return null
  }
)

export const isLoadedSelector = createSelector(
  uiLunchrCardsSelector,
  lunchrCard => lunchrCard.loaded
)

// TODO: hack to remove
export const isCreditCardsLoadedSelector = createSelector(
  uiLunchrCardsSelector,
  lunchrCard => lunchrCard.creditCardsLoaded
)
