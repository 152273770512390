import * as Api from '../../services/api'

export const FETCH_CONFIGURATION_SUCCESS = 'dbjs/FETCH_CONFIGURATION_SUCCESS'

export const fetchConfiguration = () => async (dispatch, getState) => {
  const configuration = getState().get('dbjs').configuration
  if (configuration === null) {
    const res = await Api.fetchConfiguration()
    dispatch({
      type: FETCH_CONFIGURATION_SUCCESS,
      configuration: res.configuration,
    })
  }
}

export default function reducer(state = null, action) {
  switch (action.type) {
    case FETCH_CONFIGURATION_SUCCESS:
      return action.configuration

    default:
      return state
  }
}
