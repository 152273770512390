import React, { useContext } from 'react'
import styled from 'styled-components'

import { media } from '@themenu/ui'

import { Header } from './Header'
import { Invitees } from './Invitees'
import { SummaryDelivery } from './SummaryDelivery'
import { WalkingGuy } from './WalkingGuy'
import { ReferralContext } from '../ReferralContext'
import ReferralWrap from '../ReferralWrap/ReferralWrap'
import { testLunchrUrl } from '../common/testLunchrUrl'

const ReferralSummaryWrapper = styled.main`
  display: flex;
  justify-content: center;
  padding: 0 5px;
  ${media.SM`
    padding: 0 30px;
  `}
`

const ReferralSummaryContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  ${media.SM`
    width: 730px;
  `}
`

const InviteesWithSvg = styled.div`
  display: flex;
  flex-direction: column;
  ${media.SM`
    display: block;
    position: relative;
    height: 600px;
  `}
`

export function ReferralSummary() {
  const { state, setState } = useContext(ReferralContext)
  const { leader, invitees_count } = state
  const { firstname } = leader
  if (!firstname || invitees_count === 0) {
    window.location = testLunchrUrl
    return null
  }
  return (
    <ReferralWrap notice={true}>
      <ReferralSummaryWrapper>
        <ReferralSummaryContainer>
          <Header firstname={firstname} />
          <SummaryDelivery state={state} setState={setState} />
          <InviteesWithSvg>
            <Invitees {...state} />
            <WalkingGuy />
          </InviteesWithSvg>
        </ReferralSummaryContainer>
      </ReferralSummaryWrapper>
    </ReferralWrap>
  )
}
