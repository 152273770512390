import React from 'react'
import styled from 'styled-components'

import { COLORS } from '@themenu/ui'

import { getRewardPerPerson } from '../../common/helpers/getRewardPerPerson'

const StyledRewardBadge = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  margin-left: 20px;
  margin-right: 20px;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  ${props =>
    props.isValid &&
    `
    background-color: ${props.id === props.focus ? COLORS.GREEN : '#BBEADB'};
  `}
  ${props =>
    !props.isValid &&
    `
    border: 2px dashed ${COLORS.GRAY_LIGHTER};
    background-color: ${COLORS.MAIN_BG_COLOR_GRAY};
  `}
`

const StyledBonusTitle = styled.strong`
  font-size: 16px;
  line-height: 19px;
  color: ${props => (props.isValid ? COLORS.WHITE : COLORS.BLUE_SOFT)};
`
const StyledBonus = styled.span`
  font-size: 9px;
  line-height: 11px;
  color: ${props => (props.isValid ? COLORS.WHITE : COLORS.BLUE_SOFT)};
`

export function RewardBadge(props) {
  const { id, isValid } = props
  return (
    <StyledRewardBadge {...props}>
      <StyledBonusTitle isValid={isValid}>
        {getRewardPerPerson(id)}€
      </StyledBonusTitle>
      <StyledBonus isValid={isValid}>/pers</StyledBonus>
    </StyledRewardBadge>
  )
}
