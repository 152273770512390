import React from 'react'
import { Route, Switch } from 'react-router-dom'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import {
  ReferralRoute,
  ReferralSummaryRoute,
  ReferralSummaryCompleteRoute,
  ReferralGuestRoute,
  ReferralGuestCompleteRoute,
  ReferralFeedBackNegativeRoute,
  ReferralFeedBackPositiveRoute,
  ReferralNoticeRoute,
} from '../../routes'

import { ReferralForm } from './ReferralForm'
import { ReferralProvider } from './ReferralContext'
import { ReferralSummaryComplete } from './ReferralSummaryComplete'
import { ReferralSummary } from './ReferralSummary'
import { ReferralGuestForm } from './ReferralGuestForm'
import { ReferralGuestComplete } from './ReferralGuestComplete'
import { NegativeFeedBack } from './ReferralFeedBack/NegativeFeedBack'
import { PositiveFeedBack } from './ReferralFeedBack/PositiveFeedBack'
import { ReferralLegalNotice } from './ReferralLegalNotice'

const BodyWrap = styled.div`
  background: white;
`

function ReferralContainer() {
  return (
    <ReferralProvider>
      <BodyWrap>
        <Switch>
          <Route exact path={ReferralRoute.spec} component={ReferralForm} />
          <Route
            exact
            path={ReferralSummaryRoute.spec}
            component={ReferralSummary}
          />
          <Route
            exact
            path={ReferralSummaryCompleteRoute.spec}
            component={ReferralSummaryComplete}
          />
          <Route
            exact
            path={ReferralGuestRoute.spec}
            component={ReferralGuestForm}
          />
          <Route
            exact
            path={ReferralGuestCompleteRoute.spec}
            component={ReferralGuestComplete}
          />
          <Route
            exact
            path={ReferralFeedBackNegativeRoute.spec}
            component={NegativeFeedBack}
          />
          <Route
            exact
            path={ReferralFeedBackPositiveRoute.spec}
            component={PositiveFeedBack}
          />
          <Route
            exact
            path={ReferralNoticeRoute.spec}
            component={ReferralLegalNotice}
          />
        </Switch>
      </BodyWrap>
    </ReferralProvider>
  )
}

const mapStateToProps = () => createStructuredSelector({})
const mapDispatchToProps = dispatch => bindActionCreators({}, dispatch)
export default connect(mapStateToProps, mapDispatchToProps)(ReferralContainer)
