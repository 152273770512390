import React from 'react'
import { Grid } from '@material-ui/core'

export function GridItem({ children, ...props }) {
  return (
    <Grid item {...props} style={{ marginBottom: '5px' }}>
      {children}
    </Grid>
  )
}
