import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveCardIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15 19V20H16V19H15ZM5 19H4V20H5V19ZM6 2H14V0H6V2ZM14 2V19H16V2H14ZM15 18H5V20H15V18ZM6 19V2H4V19H6ZM14 2H16C16 0.89543 15.1046 0 14 0V2ZM6 0C4.89543 0 4 0.895432 4 2H6L6 2V0Z"
      fill={humanColorToHex(color)}
    />
    <rect x="7" y="4" width="2" height="2" fill={humanColorToHex(color)} />
    <rect x="7" y="7" width="2" height="2" fill={humanColorToHex(color)} />
    <rect x="7" y="10" width="2" height="2" fill={humanColorToHex(color)} />
    <rect x="11" y="4" width="2" height="2" fill={humanColorToHex(color)} />
    <rect x="11" y="7" width="2" height="2" fill={humanColorToHex(color)} />
    <rect x="11" y="10" width="2" height="2" fill={humanColorToHex(color)} />
    <rect x="8" y="15" width="4" height="4" fill={humanColorToHex(color)} />
  </svg>
)

export default ActiveCardIcon
