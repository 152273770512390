import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveCardIcon = ({ color }) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V4H0V3ZM0 6V13C0 14.6569 1.34315 16 3 16H17C18.6569 16 20 14.6569 20 13V6H0ZM4 10C4 9.44771 4.44772 9 5 9H5.01C5.56228 9 6.01 9.44771 6.01 10C6.01 10.5523 5.56228 11 5.01 11H5C4.44772 11 4 10.5523 4 10ZM9 9C8.44771 9 8 9.44771 8 10C8 10.5523 8.44771 11 9 11H15C15.5523 11 16 10.5523 16 10C16 9.44771 15.5523 9 15 9H9Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default ActiveCardIcon
