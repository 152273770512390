import { isValidContact } from './isValidContact'
import { isDisabledContact } from './isDisabledContact'

export function getColleagueCount(values, errors) {
  return ['contact_1', 'contact_2', 'contact_3', 'contact_4'].reduce(
    (count, contactId, index) => {
      const hasNoErrors =
        isValidContact(values[contactId]) &&
        Object.keys(errors[contactId] ? errors[contactId] : {}).length === 0
      if (
        index === count &&
        hasNoErrors &&
        !isDisabledContact(contactId, values, errors)
      )
        count += 1

      return count
    },
    0
  )
}
