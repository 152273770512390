import * as Api from '../services/api'
import { userInputSelector } from './auth-selectors'

export const FETCH_USER_ADDRESSES_SUCCEEDED =
  'dbjs/userme/FETCH_USER_ADDRESSES_SUCCEEDED'
export const ADD_USER_ADDRESSES_SUCCEEDED =
  'dbjs/userme/ADD_USER_ADDRESSES_SUCCEEDED'
export const DELETE_USER_ADDRESSES_SUCCEEDED =
  'dbjs/userme/DELETE_USER_ADDRESSES_SUCCEEDED'

const fetchUserAddressesSucceeded = addresses => ({
  type: FETCH_USER_ADDRESSES_SUCCEEDED,
  addresses,
  dbRes: {
    addresses,
  },
})

export const fetchUserAddresses = () => (dispatch, getState) => {
  const user = userInputSelector(getState())
    ? userInputSelector(getState()).toJS()
    : null
  if (!user) return
  return Api.fetchUserAddresses(user.id).then(data => {
    dispatch(fetchUserAddressesSucceeded(data))
  })
}
