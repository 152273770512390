import PropTypes from 'prop-types'

export const BalancePropType = PropTypes.shape({
  value: PropTypes.number,
  text: PropTypes.string,
})

export const MealVoucherWalletPropType = PropTypes.shape({
  id: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  gauge: PropTypes.number,
  balance: BalancePropType,
  authorizableBalance: BalancePropType,
  dailyAvailableBalance: BalancePropType,
  dailyAvailableBalanceForRestaurant: BalancePropType,
  maximumDailyBalance: BalancePropType,
  maximumDailyBalanceForRestaurant: BalancePropType,
})
