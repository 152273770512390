import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const InactiveCardIcon = ({ color }) => (
  <svg
    width="20"
    height="16"
    viewBox="0 0 20 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 3C0 1.34315 1.34315 0 3 0H17C18.6569 0 20 1.34315 20 3V13C20 14.6569 18.6569 16 17 16H3C1.34315 16 0 14.6569 0 13V3ZM3 2C2.44772 2 2 2.44772 2 3V4H18V3C18 2.44772 17.5523 2 17 2H3ZM18 6H2V13C2 13.5523 2.44772 14 3 14H17C17.5523 14 18 13.5523 18 13V6ZM4 10C4 9.44771 4.44772 9 5 9H5.01C5.56228 9 6.01 9.44771 6.01 10C6.01 10.5523 5.56228 11 5.01 11H5C4.44772 11 4 10.5523 4 10ZM8 10C8 9.44771 8.44772 9 9 9H15C15.5523 9 16 9.44771 16 10C16 10.5523 15.5523 11 15 11H9C8.44772 11 8 10.5523 8 10Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default InactiveCardIcon
