import { Link } from 'react-router-dom'
import React, { useState } from 'react'
import { curryN } from 'ramda'

import SidebarItem from '../SidebarItem'

const onMouseEnter = curryN(2, setHovered => setHovered(true))

const onMouseLeave = curryN(2, setHovered => setHovered(false))

const SidebarItemLink = ({ to, icon, active, absolute, ...props }) => {
  const [hovered, setHovered] = useState(false)

  if (absolute) {
    return (
      <a
        href={to}
        onMouseEnter={onMouseEnter(setHovered)}
        onMouseLeave={onMouseLeave(setHovered)}
      >
        <SidebarItem {...props} active={active} hovered={hovered} icon={icon} />
      </a>
    )
  }

  return (
    <Link
      to={to}
      onMouseEnter={onMouseEnter(setHovered)}
      onMouseLeave={onMouseLeave(setHovered)}
    >
      <SidebarItem {...props} active={active} hovered={hovered} icon={icon} />
    </Link>
  )
}

export default SidebarItemLink
