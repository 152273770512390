import omit from 'lodash/omit'

const CREATE_NOTIFICATION = 'app/uiresto/CREATE_NOTIFICATION'
const REMOVE_NOTIFICATION = 'app/uiresto/REMOVE_NOTIFICATION'

export function removeNotification(id) {
  return {
    type: REMOVE_NOTIFICATION,
    id,
  }
}

export default function uiRestoNotificationsReducer(state = {}, action = {}) {
  const { notification, type, id } = action
  switch (type) {
    case CREATE_NOTIFICATION:
      return {
        ...state,
        [notification.id]: notification,
      }
    case REMOVE_NOTIFICATION:
      return omit(state, id)
    default:
      return state
  }
}
