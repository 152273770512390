import { createSelector } from 'reselect'
import { find, lt, and, propEq, pathEq, isNil, any } from 'ramda'
import {
  COMPANY_ROLE,
  PROGRAM_KINDS,
} from '@themenu/shared/lib/constants/corpo'
import { getPrice } from '../helpers/price'
import { onlyMainLunchrCardSelector } from '../modules/uicorpo/my-lunchr-cards-selector'
import {
  isMealVoucherAdmin,
  isAdminOfAProgram,
  adminOrManager,
} from '@themenu/shared/lib/utils/corpoAdmin'

export const userInputSelector = state => state.getIn(['auth', 'user'], null)

const getTeam = team =>
  team && {
    id: team.get('id'),
    name: team.get('name'),
    has_restaurants: team.get('has_restaurants'),
    address: {
      address: team.getIn(['address', 'street']),
      latitude: team.getIn(['address', 'coordinates', 'latitude']),
      longitude: team.getIn(['address', 'coordinates', 'longitude']),
    },
  }

/**
 * Returns the user from the auth reducer
 * @param  {Function} userInputSelector
 * @param  {Immutable.Map}
 * @return {Object}
 */
export const userSelector = createSelector(userInputSelector, user =>
  getUser(user)
)

export const isUserWithoutEmailSelector = createSelector(
  userSelector,
  user => user && !user.email
)

export const getUser = user =>
  user && {
    firstName: user.get('first_name'),
    lastName: user.get('last_name'),
    language: user.get('language'),
    phoneNumber: user.get('phone_number'),
    phoneNumberConfirmed: user.get('phone_number_confirmed'),
    avatarPictureKey: user.getIn(['avatar_picture', 'key']),
    hasLunchrCard: user.get('lunchr_card_available'),
    lastGroupedOrderJoinedAt: user.get('last_grouped_order_joined_at'),
    id: user.get('id'),
    email: user.get('email'),
    company: getTeam(user.get('company')),
    team: getTeam(user.get('workspace')),
    workspace: getTeam(user.get('workspace')),
    card: user.get('card')
      ? {
          token: user.getIn(['card', 'token']),
          name: user.getIn(['card', 'name']),
          last4: user.getIn(['card', 'last4']),
          expMonth: user.getIn(['card', 'exp_month']),
          expYear: user.getIn(['card', 'exp_year']),
          brand: user.getIn(['card', 'brand']),
        }
      : null,
    mealVoucher: user.get('meal_voucher_info') && {
      balance: getPrice(user.getIn(['meal_voucher_info', 'balance'])),
      expectedBalance: getPrice(
        user.getIn(['meal_voucher_info', 'expected_balance'])
      ),
      expectedBalanceDate: user.getIn([
        'meal_voucher_info',
        'expected_balance_date',
      ]),
      dailyBalance: getPrice(
        user.getIn(['meal_voucher_info', 'daily_balance'])
      ),
      facialValue: getPrice(user.getIn(['meal_voucher_info', 'facial_value'])),
      companyContributionPercent: user.getIn([
        'meal_voucher_info',
        'assumed_amount_percent',
      ]),
      availableOnDayOff: user.getIn([
        'meal_voucher_info',
        'enabled_on_day_off',
      ]),
    },
    groupedOrderEnabled: user.getIn(['enabled_features', 'grouped_order']),
    betaWebappEnabled: user.getIn(['enabled_features', 'employee_webapp']),
    uuid: user.get('uuid'),
  }

export const companyAdminId = state => state.getIn(['auth', 'companyAdminId'])

export const companiesSelector = state =>
  state.getIn(['auth', 'user', 'companies'])

export const isCompanyAdminSelector = createSelector(
  companiesSelector,
  companies =>
    companies &&
    companies
      .toJS()
      .some(
        company => isAdminOfAProgram(company) || isMealVoucherAdmin(company)
      )
)

export const isCurrentCompanyAdminSelector = createSelector(
  companyAdminId,
  companiesSelector,
  (currentCompanyId, companies) => {
    if (!companies) return false
    const currentCompanyInfo = find(
      propEq('id', currentCompanyId),
      companies.toJS()
    )
    if (!currentCompanyInfo) return false
    return (
      isMealVoucherAdmin(currentCompanyInfo) ||
      isAdminOfAProgram(currentCompanyInfo)
    )
  }
)

export const isCompanyMvoAdminOrManagerSelector = createSelector(
  companyAdminId,
  companiesSelector,
  (currentCompanyId, companies) => {
    if (!companies) return false
    const currentCompanyInfo = find(
      propEq('id', currentCompanyId),
      companies.toJS()
    )
    if (!currentCompanyInfo) return false
    return isMealVoucherAdmin(currentCompanyInfo)
  }
)

export const isCompanyMvoAdminSelector = createSelector(
  companyAdminId,
  companiesSelector,
  (currentCompanyId, companies) => {
    if (!companies) return false
    const currentCompanyInfo = find(
      propEq('id', currentCompanyId),
      companies.toJS()
    )
    return pathEq(
      ['company_membership', 'role'],
      COMPANY_ROLE.ADMINISTRATOR,
      currentCompanyInfo
    )
  }
)

export const isAdminOfAtLeastOneCompanySelector = createSelector(
  companiesSelector,
  companies =>
    companies &&
    companies
      .toJS()
      .some(
        ({ company_membership: { role } }) =>
          role === COMPANY_ROLE.ADMINISTRATOR
      )
)

export const adminCompaniesSelector = createSelector(
  companiesSelector,
  companies => {
    return companies
      ? companies
          .toJS()
          .filter(({ company_membership: { role } }) =>
            [COMPANY_ROLE.ADMINISTRATOR].includes(role)
          )
      : []
  }
)

// return the companies where the user is manager or admin
export const adminOrManagerCompaniesSelector = createSelector(
  companiesSelector,
  companies => {
    return companies
      ? companies
          .toJS()
          .filter(({ company_membership: { role } }) =>
            [COMPANY_ROLE.ADMINISTRATOR, COMPANY_ROLE.MANAGER].includes(role)
          )
      : []
  }
)

export const adminCompanyIDsSelector = createSelector(
  adminOrManagerCompaniesSelector,
  adminCompanies => adminCompanies.map(({ id }) => id)
)

export const isConnectedSelector = state =>
  state.getIn(['auth', 'user', 'id']) !== undefined

export const isConnectedWithEmailSelector = state =>
  state.getIn(['auth', 'user', 'id']) !== undefined &&
  state.getIn(['auth', 'user', 'email']) &&
  state.getIn(['auth', 'user', 'email']) !== ''

export const isAffiliateSelector = state => state.getIn(['auth', 'isAffiliate'])

export const getFirstName = state => state.getIn(['auth', 'user', 'first_name'])

export const getLastName = state => state.getIn(['auth', 'user', 'last_name'])

export const hasAnonymousLunchrCardSelector = state =>
  state.getIn(['auth', 'user', 'has_anonymous_lunchr_card'])

export const hasLunchrCardSelector = state =>
  state.getIn(['auth', 'user', 'lunchr_card_available'])

export const hasActivateAnonymousCardAccessSelector = createSelector(
  hasAnonymousLunchrCardSelector,
  companiesSelector,
  onlyMainLunchrCardSelector,
  (hasAnonymousLunchrCard, companies, mainLunchrCard) => {
    if (!companies) return false
    return and(
      hasAnonymousLunchrCard,
      lt(0, companies.size),
      isNil(mainLunchrCard)
    )
  }
)

export const hasMealVoucherBalanceSelector = createSelector(
  userSelector,
  user => {
    return (
      user &&
      user.mealVoucher &&
      user.mealVoucher.balance &&
      user.mealVoucher.balance.value > 0
    )
  }
)

const hasAProgram = (programKind, companies) => {
  if (!companies) return false
  const companyWithTheProgram = companies
    .toJS()
    .find(
      company =>
        company.programs &&
        any(program => program.kind === programKind, company.programs)
    )
  return companyWithTheProgram
}

const hasAProgramAsAdmin = (programKind, companies) => {
  if (!companies) return false
  const companyWithTheProgram = companies
    .toJS()
    .find(
      company =>
        company.programs &&
        any(
          program =>
            program.kind === programKind && adminOrManager(program.role),
          company.programs
        )
    )
  return companyWithTheProgram
}

export const hasGiftProgramSelector = createSelector(
  companiesSelector,
  companies => {
    return hasAProgram(PROGRAM_KINDS.GIFT, companies)
  }
)

export const isAdminCompanyGiftOnlySelector = createSelector(
  companyAdminId,
  companiesSelector,
  (currentCompanyId, companies) => {
    if (!companies) return false
    const currentCompanyInfo = find(
      propEq('id', currentCompanyId),
      companies.toJS()
    )
    if (!currentCompanyInfo) return false
    const companyWithProgramOtherThanGift =
      currentCompanyInfo.programs &&
      currentCompanyInfo.programs.find(
        program => program.kind != PROGRAM_KINDS.GIFT
      )
    return !companyWithProgramOtherThanGift
  }
)

export const isOnlyGiftProgramAdminSelector = createSelector(
  companiesSelector,
  companies => {
    if (!companies) return false
    const hasGiftAdmin = hasAProgramAsAdmin(PROGRAM_KINDS.GIFT, companies)
    const adminCompanyWithProgramOtherThanGift = companies
      .toJS()
      .find(
        company =>
          company.programs &&
          any(
            program =>
              program.kind !== PROGRAM_KINDS.GIFT &&
              adminOrManager(program.role),
            company.programs
          )
      )
    return !adminCompanyWithProgramOtherThanGift && hasGiftAdmin
  }
)

export const hasMealVoucherProgramSelector = createSelector(
  companiesSelector,
  companies => {
    return hasAProgram(PROGRAM_KINDS.MEAL_VOUCHER, companies)
  }
)
