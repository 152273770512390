import { isValidContact } from './isValidContact'
import { isValidLeader } from './isValidLeader'

const PREVIOUS_CONTACTS_MAPPING = {
  contact_2: ['contact_1'],
  contact_3: ['contact_1', 'contact_2'],
  contact_4: ['contact_1', 'contact_2', 'contact_3'],
}

export function isDisabledContact(contactId, values, errors) {
  if (contactId === 'contact_1') return !isValidLeader(values.leader)
  return PREVIOUS_CONTACTS_MAPPING[contactId].some(
    c => !isValidContact(values[c]) || errors[c]
  )
}
