import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveSettingsIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 2.5C7 1.11929 8.11929 0 9.5 0H10.5C11.8807 0 13 1.11929 13 2.5V2.5818C13.6952 2.8632 14.3415 3.23925 14.9229 3.69366L14.9952 3.65193C16.1909 2.96157 17.7199 3.37126 18.4103 4.56699L18.9103 5.43302C19.6006 6.62875 19.1909 8.15772 17.9952 8.84808L17.9234 8.88952C17.9739 9.25284 18 9.6236 18 10C18 10.3764 17.9739 10.7472 17.9234 11.1105L17.9952 11.1519C19.1909 11.8423 19.6006 13.3713 18.9103 14.567L18.4103 15.433C17.7199 16.6287 16.1909 17.0384 14.9952 16.3481L14.9229 16.3063C14.3415 16.7608 13.6952 17.1368 13 17.4182V17.5C13 18.8807 11.8807 20 10.5 20H9.5C8.11929 20 7 18.8807 7 17.5V17.4182C6.30476 17.1368 5.6585 16.7608 5.0771 16.3063L5.00482 16.3481C3.80909 17.0384 2.28011 16.6287 1.58976 15.433L1.08976 14.567C0.3994 13.3713 0.809089 11.8423 2.00482 11.1519L2.07658 11.1105C2.02607 10.7472 2 10.3764 2 10C2 9.6236 2.02607 9.25283 2.07658 8.88951L2.00482 8.84808C0.809088 8.15773 0.3994 6.62875 1.08976 5.43302L1.58976 4.56699C2.28011 3.37126 3.80909 2.96157 5.00482 3.65193L5.0771 3.69366C5.65849 3.23924 6.30476 2.8632 7 2.5818V2.5ZM14 10C14 12.2091 12.2091 14 10 14C7.79086 14 6 12.2091 6 10C6 7.79086 7.79086 6 10 6C12.2091 6 14 7.79086 14 10Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default ActiveSettingsIcon
