import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import jwtDecode from 'jwt-decode'
import ReactModal from 'react-modal'
import Auth from '@themenu/shared/lib/services/auth'
import { LoadingBar } from '@themenu/ui'

import * as authActions from '../modules/auth'
import { fetchCompleteProfile } from '@themenu/shared/lib/services/profile'
import qs from 'query-string'

const hasQueryParamAccessToken = () => {
  const { access_token } = qs.parse(window.location.search)
  return access_token ? true : false
}

const handleURLToken = () => {
  const { access_token, refresh_token, ...queryParams } = qs.parse(
    window.location.search
  )
  if (access_token) {
    if (refresh_token) {
      Auth.setJwt(access_token, refresh_token)
    } else {
      Auth.setJwt(access_token)
    }

    const jwt = jwtDecode(access_token)
    if (jwt.sudoer_uid || jwt.sudoer_email) {
      Auth.setSudo(true)
    }

    const { origin, pathname } = window.location
    const queryString =
      Object.keys(queryParams).length > 0 ? `?${qs.stringify(queryParams)}` : ''
    window.location.href = [origin, pathname, queryString].join('')

    return true
  }
  return false
}

const isReadyToRenderInitial = () =>
  !Auth.isLoggedIn() && !hasQueryParamAccessToken()

const fetchLoggedUser = async (onFailure, onSuccess) => {
  if (Auth.isLoggedIn()) {
    try {
      const { user } = await fetchCompleteProfile()
      onSuccess({ user })
    } catch (err) {
      console.error(err)
      onFailure()
    }
  }
}

const BeforeStart = ({ logout, loginSucceeded, children }) => {
  const [ready, setReady] = useState(isReadyToRenderInitial())

  useEffect(() => {
    const AuthManagement = async () => {
      if (!handleURLToken()) {
        await fetchLoggedUser(logout, loginSucceeded)

        setReady(true)
      }
    }
    AuthManagement()
    ReactModal.setAppElement('#root')
  }, [])

  if (ready) {
    return children
  }

  return <LoadingBar />
}

export default connect(null, {
  logout: authActions.logout,
  loginSucceeded: authActions.loginSucceeded,
})(BeforeStart)
