import React from 'react'
import styled from 'styled-components'

import { COLORS } from '@themenu/ui'

const StyledFormTitle = styled.span`
  font-size: 13px;
  line-height: 16px;
  color: ${COLORS.BLUE_SOFT};
`

export function FormTitle({ children }) {
  return <StyledFormTitle>{children}</StyledFormTitle>
}
