import React from 'react'
import { path } from 'ramda'
import { Field } from 'formik'
import styled from 'styled-components'

import { COLORS, Input } from '@themenu/ui'
import Cloudinary from '@themenu/shared/lib/services/cloudinary'

import { FormField } from '../FormField'
import { FormTitle } from '../FormTitle'

const ok = Cloudinary.url('web-assets/reco-checkbox-ok.svg', {
  secure: true,
})

const StyledField = Input.withComponent(Field)

const StyledInput = styled(StyledField)`
  padding-right: 40px;
  background-repeat: no-repeat;
  background-position: center right 16px;
  border-color: ${COLORS.GRAY_COOL};
  width: 100%;

  &:hover,
  &:focus {
    border-color: #b7bcc0;
  }

  ${props =>
    props.disabled &&
    `
    background-color: ${COLORS.GRAY_COOL};
  `}

  ${props =>
    props.validators.showValid &&
    `
    background-size: 16px 16px;
    background-image: url("${ok}");
  `}
  ${props => {
    return (
      props.validators.showError &&
      `
    border-color: ${COLORS.PINK};

    &:hover,
    &:focus {
      border-color: ${COLORS.PINK};
    }
  `
    )
  }}
`

export function FormInput({
  title,
  type,
  name,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  disabled,
}) {
  const accessPath = path(name.split('.'))
  const error = accessPath(errors)
  const wasTouched = accessPath(touched) ? 'touched' : undefined
  const value = accessPath(values)
  const isValid = !error ? 'valid' : undefined

  const showValid = value && isValid
  const showError = wasTouched && !isValid

  const validators = {
    showValid,
    showError,
  }

  const fieldProps = {
    title,
    error,
    showError,
  }

  return (
    <FormField {...fieldProps}>
      <FormTitle>{title}</FormTitle>
      <StyledInput
        type={type}
        component="input"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        validators={validators}
        disabled={disabled}
      />
    </FormField>
  )
}
