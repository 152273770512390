import { setLightness, rgba } from 'polished'

const COLORS_NAMES_SWILE = {
  BLUE: 'swile-blue',
  PURPLE: 'swile-purple',
  PINK_LIGHT: 'swile-pink-light',
  PINK_DARK: 'swile-pink-dark',
  ORANGE: 'swile-orange',
  YELLOW: 'swile-yellow',
  GREEN: 'swile-green',
  JUNGLE_GREEN: 'swile-jungle-green'
}

const HEX_COLORS = {
  // -----------------------------------------------------------------------------
  // Generic colors
  // -----------------------------------------------------------------------------
  BLACK: '#000000',
  BLACK_MEDIUM: '#0d1319',
  BLACK_LIGHT: '#1B2733',
  WHITE: '#ffffff',
  WHITE_SMOKE_LIGHT: '#FAFAFA',

  GRAY_COOL: '#DDDCE5',
  GRAY_DARK: '#7a8590',

  GREEN: '#1D1148',
  //GREEN: '#2CAF90',
  GREEN_DARK: '#196352',
  GREEN_LIGHT: rgba('#1D1148', 0.08),
  GREEN_DARK_SIDEBAR_ICON: '#216E60',
  GRAY_VERY_LIGHT: '#F7F8FA',
  BLUE: '#34BBDB',

  BLUE_DARK: '#200D4B',
  BLUE_DARK_LIGHTER: rgba('#200D4B', 0.08),

  ////// SWILE

  WHITE_SMOKE: '#F6F6F8',
  GRAY_LIGHTER: '#DDDCE5',
  GRAY_LIGHT: '#9087A5',
  GRAY: '#796F94',
  SOFT_GRAY: '#EEEDF1',

  PURPLE_DARK: '#1D1148',
  PURPLE_DARK_LIGHT: rgba('#1D1148', 0.08),
  PURPLE: '#633FD3',
  PURPLE_MEDIUM: rgba('#633FD3', 0.24),
  PURPLE_LIGHT: rgba('#633FD3', 0.08),
  PURPLE_GRAY: '#796F94',
  PURPLE_PASTEL: '#E6E0F8',

  PINK: '#FF0083',
  PINK_LIGHT: rgba('#FF0083', 0.08),
  FUSHIA_PASTEL: '#FFD6EB',

  //////

  BLUE_SOFT: '#546476',
  BLUE_ELECTRIC_DARK: '#2246d9',
  BLUE_ELECTRIC_DARKER: '#0f32b7',
  BLUE_ELECTRIC_DARKEST: '#0c227d',
  BLUE_ELECTRIC_DEEP_DARK: '#0a1c63',

  YELLOW: '#F1B500',
  YELLOW_LIGHT: '#ffebbc',
  ORANGE_DARK: '#F17A43',
  ORANGE: '#F8A304',

  BLUE_CORPO: '#9aaeff',
  BLUE_CORPO_TEXT: '#633FD3',
  BLUE_CORPO_AMOUNT: '#4565E2',

  ////// CORPO
  CORPO_LIGHT_GREY: '#918BA6',
  CORPO_GREY_BORDER: '#eeedf1',
  CORPO_YELLOW: '#F1B500',
  CORPO_GREEN: '#1ABC62',
  CORPO_YELLOW_PASTEL: '#FCF0CC',
  CORPO_GREEN_PASTEL: '#D1F2E0',
  CORPO_FIRE: '#EB7537',
  CORPO_FUCHSIA: '#FF0083',
  CORPO_ORANGE: '#FD9B28',
  CORPO_PINK: '#FC63B6',
  CORPO_RED: '#FC636B',
  CORPO_LIGHT_RED: '#FEE0E1',
  CORPO_BLUE: '#1AAFD0'
}

const COLORS = {
  ...HEX_COLORS,
  MAIN_COLOR: HEX_COLORS.PURPLE_DARK,
  MAIN_TEXT_COLOR: HEX_COLORS.PURPLE_DARK,
  MAIN_TEXT_COLOR_LIGHT: HEX_COLORS.PURPLE_DARK,
  MAIN_BG_COLOR_GRAY: '#F6F7F8',

  MAIN_SUBTITLE_COLOR: HEX_COLORS.PURPLE_GRAY,

  MAIN_BG_COLOR: '#FFFFFF',
  MAIN_BORDER_COLOR: '#EBECEE',
  MAIN_BORDER_COLOR_LIGHTER: '#F6F6F7',
  MAIN_BORDER_COLOR_DARKER: '#CCD3D9',

  // -----------------------------------------------------------------------------
  // Swile colors
  // -----------------------------------------------------------------------------
  [COLORS_NAMES_SWILE.BLUE]: '#4067DF',
  [COLORS_NAMES_SWILE.PURPLE]: '#8547DE',
  [COLORS_NAMES_SWILE.PINK_DARK]: '#AA4C8F',
  [COLORS_NAMES_SWILE.PINK_LIGHT]: '#EC5D82',
  [COLORS_NAMES_SWILE.ORANGE]: '#ED7566',
  [COLORS_NAMES_SWILE.YELLOW]: '#F5C476',
  [COLORS_NAMES_SWILE.GREEN]: '#6CDB9D',
  [COLORS_NAMES_SWILE.JUNGLE_GREEN]: '#41AFA5'
}

const HUMAN_COLORS = {
  'blue-dark': HEX_COLORS.PURPLE_DARK,
  black: HEX_COLORS.BLACK,
  green: HEX_COLORS.GREEN,
  'green-dark-sidebar-icon': HEX_COLORS.GREEN_DARK_SIDEBAR_ICON,
  gray: HEX_COLORS.GRAY,
  grayLight: HEX_COLORS.GRAY_LIGHT,
  grayLighter: HEX_COLORS.GRAY_LIGHTER,
  grayVeryLight: HEX_COLORS.GRAY_VERY_LIGHT,
  'gray-light': HEX_COLORS.GRAY_LIGHT,
  'gray-cool': HEX_COLORS.GRAY_COOL,
  yellow: HEX_COLORS.YELLOW,
  blue: HEX_COLORS.BLUE,
  // 'blue-electric': HEX_COLORS.BLUE_ELECTRIC,
  'blue-electric-dark': HEX_COLORS.BLUE_ELECTRIC_DARK,
  red: HEX_COLORS.PINK,
  'orange-dark': HEX_COLORS.ORANGE_DARK,
  orange: HEX_COLORS.ORANGE,
  // 'blue-facebook': HEX_COLORS.BLUE_FACEBOOK,
  'blue-soft': HEX_COLORS.BLUE_SOFT,
  // 'blue-twitter': HEX_COLORS.BLUE_TWITTER,
  // 'green-whatsapp': HEX_COLORS.GREEN_WHATSAPP,
  white: HEX_COLORS.WHITE,
  whiteSmoke: HEX_COLORS.WHITE_SMOKE,
  purple: HEX_COLORS.PURPLE,
  'purple-dark': HEX_COLORS.PURPLE_DARK,
  'purple-gray': HEX_COLORS.PURPLE_GRAY,
  pink: HEX_COLORS.PINK,
  // SWILE COLORS
  [COLORS_NAMES_SWILE.BLUE]: COLORS[COLORS_NAMES_SWILE.BLUE],
  [COLORS_NAMES_SWILE.PURPLE]: COLORS[COLORS_NAMES_SWILE.PURPLE],
  [COLORS_NAMES_SWILE.PINK_LIGHT]: COLORS[COLORS_NAMES_SWILE.PINK_LIGHT],
  [COLORS_NAMES_SWILE.PINK_DARK]: COLORS[COLORS_NAMES_SWILE.PINK_DARK],
  [COLORS_NAMES_SWILE.ORANGE]: COLORS[COLORS_NAMES_SWILE.ORANGE],
  [COLORS_NAMES_SWILE.YELLOW]: COLORS[COLORS_NAMES_SWILE.YELLOW],
  [COLORS_NAMES_SWILE.GREEN]: COLORS[COLORS_NAMES_SWILE.GREEN],
  [COLORS_NAMES_SWILE.JUNGLE_GREEN]: COLORS[COLORS_NAMES_SWILE.JUNGLE_GREEN]
}

export const humanColorToHex = name => {
  return HUMAN_COLORS[name]
}

const baseAvatarColors = [
  '#2CAF90',
  '#3497DB',
  '#34BADB',
  '#F8A203',
  '#F8C903',
  '#E45832',
  '#DD4695',
  '#1B2733',
  '#546476'
]
export const AVATAR_BACKGROUND = baseAvatarColors.concat(
  baseAvatarColors.map(color => {
    return setLightness(0.73, color)
  })
)

export { COLORS, HEX_COLORS, HUMAN_COLORS, COLORS_NAMES_SWILE }

export default COLORS
