import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveHistoryIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00002 1C8.00002 0.447715 7.5523 0 7.00002 0C6.44773 0 6.00002 0.447715 6.00002 1V2H3.00001C1.34316 2 1.52588e-05 3.34315 1.52588e-05 5V15C1.52588e-05 16.6569 1.34316 18 3.00002 18H6C6.55229 18 7 17.5523 7 17C7 16.4477 6.55229 16 6 16H3.00002C2.44773 16 2.00002 15.5523 2.00002 15V5C2.00002 4.44772 2.44773 4 3.00001 4H6.00002V5C6.00002 5.55228 6.44773 6 7.00002 6C7.5523 6 8.00002 5.55228 8.00002 5V4H12V5C12 5.55228 12.4477 6 13 6C13.5523 6 14 5.55228 14 5V4H17C17.5523 4 18 4.44772 18 5V6C18 6.55228 18.4477 7 19 7C19.5523 7 20 6.55228 20 6V5C20 3.34315 18.6569 2 17 2H14V1C14 0.447715 13.5523 0 13 0C12.4477 0 12 0.447715 12 1V2H8.00002V1ZM14 8C10.6863 8 8 10.6863 8 14C8 17.3137 10.6863 20 14 20C17.3137 20 20 17.3137 20 14C20 10.6863 17.3137 8 14 8ZM14 10C14.5523 10 15 10.4477 15 11V14C15 14.5523 14.5523 15 14 15H11C10.4477 15 10 14.5523 10 14C10 13.4477 10.4477 13 11 13H13V11C13 10.4477 13.4477 10 14 10Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default ActiveHistoryIcon
