import { getUniqLastFourPlaces, formatPlacesToAddresses } from './places-utils'
import { mergeTypedEntities } from './dbjs/utils'
import { AddressStoreType } from './dbjs/addresses'

const SAVE_ADDRESS = 'app/SearchAddresses/SAVE_ADDRESS'
const CLEAR_SEARCH_ADDRESSES = 'app/SearchAddresses/CLEAR_SEARCH_ADDRESSES'

const initialState = {
  addresses: {},
  lastAddresses: [],
}

export const saveAddress = address => ({
  type: SAVE_ADDRESS,
  address,
})

export const initAddressesFromPlaces = places => {
  const addresses = formatPlacesToAddresses(getUniqLastFourPlaces(places))
  const lastAddresses = addresses.map(addresse => addresse.id)
  return {
    addresses: mergeTypedEntities({}, addresses, AddressStoreType),
    lastAddresses,
  }
}

export default function searchAddressesReducer(
  state = initialState,
  action = {}
) {
  switch (action.type) {
    case SAVE_ADDRESS:
      return {
        addresses: mergeTypedEntities(
          state.addresses,
          [action.address],
          AddressStoreType
        ),
        lastAddresses: state.lastAddresses.concat([action.address.id]),
      }

    case CLEAR_SEARCH_ADDRESSES:
      return initialState

    default:
      return state
  }
}
