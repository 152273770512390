import { combineReducers } from 'redux'

import myLunchrCards from './my-lunchr-cards'
import safetyCards from './safety-cards'

const reducer = combineReducers({
  myLunchrCards,
  safetyCards,
})

export default reducer
