import { compose, lifecycle } from 'recompose'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { withRouter } from 'react-router-dom'
import PropTypes from 'prop-types'

import {
  companyAdminId,
  isUserWithoutEmailSelector,
  isAffiliateSelector,
  hasActivateAnonymousCardAccessSelector,
  isOnlyGiftProgramAdminSelector,
  hasMealVoucherProgramSelector,
  hasGiftProgramSelector,
  isCompanyAdminSelector,
  hasMealVoucherBalanceSelector,
  hasLunchrCardSelector,
} from '../../../modules/auth-selectors'
import { creditCardsSelector } from '../../../modules/dbjs/creditCards-selectors'
import { fetchIsAffiliate, fetchProfile, logout } from '../../../modules/auth'
import UserMenu from './UserMenu'
import { MealVoucherWalletPropType } from '../../../utils/propTypes'

const mapStateToProps = (state, { user }) => ({
  companyAdminId: companyAdminId(state),
  isCompanyAdmin: isCompanyAdminSelector(state),
  isOnlyGiftProgramAdmin: isOnlyGiftProgramAdminSelector(state),
  creditCards: creditCardsSelector(state),
  hasMealVoucherProgram: hasMealVoucherProgramSelector(state),
  hasGiftProgram: hasGiftProgramSelector(state),
  hasMealVoucherBalance: hasMealVoucherBalanceSelector(state),
  isAffiliate: isAffiliateSelector(state),
  firstname: user.firstName,
  lastname: user.lastName,
  emailAddress: user.email,
  avatarPictureKey: user.avatarPictureKey,
  mealVoucher: user.mealVoucher,
  userWithoutEmail: isUserWithoutEmailSelector(state),
  hasActivateAnonymousCardAccess: hasActivateAnonymousCardAccessSelector(state),
  hasLunchrCard: hasLunchrCardSelector(state),
})

const mapDispatchToProps = (dispatch, { history }) => ({
  onLogoutClick: event => {
    event.preventDefault()

    dispatch(logout())
    history.push('/signin')
  },
  fetchProfile: () => dispatch(fetchProfile()),
  fetchIsAffiliate: () => dispatch(fetchIsAffiliate()),
})

const getUserId = path(['user', 'id'])

const enhance = compose(
  withRouter,
  connect(mapStateToProps, mapDispatchToProps),
  // XXX TODO remove the fetchProfile responsability from the menu !
  lifecycle({
    UNSAFE_componentWillMount() {
      this.props.fetchProfile()
    },
    componentDidMount() {
      if (
        this.props.isAffiliate === null ||
        this.props.isAffiliate === undefined
      ) {
        this.props.fetchIsAffiliate()
      }
    },
    componentDidUpdate() {
      const currentUserId = getUserId(this.props)
      if (currentUserId && currentUserId !== getUserId(this.props)) {
        this.props.fetchIsAffiliate()
      }
    },
  })
)

const UserMenuContainer = enhance(UserMenu)

UserMenuContainer.propType = {
  mealVoucherWallet: MealVoucherWalletPropType,
  isDisplayingDailyBalance: PropTypes.bool,
}

export default UserMenuContainer
