import { Component } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'

import { actions } from '../modules'
import { errorEmitter } from '../services/api'
import * as toast from '../modules/ui/toast-message'

class ErrorController extends Component {
  listener = (status, json) => {
    if (
      status === 401 &&
      json.result &&
      json.result.error &&
      json.result.error.code === 'AUTHENTICATION_FAILED' // avoid Login failed for example
    )
      this.on401()
  }

  on401() {
    this.props.actions.logout()
    this.props.dispatch(toast.showIntl('sessionExpired'))
  }

  componentDidMount() {
    errorEmitter.on('request:error', this.listener)
  }

  componentWillUnmount() {
    errorEmitter.removeListener('request:error', this.listener)
  }

  render() {
    return null
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  actions: bindActionCreators(
    {
      ...actions.auth,
    },
    dispatch
  ),
})

export default connect(
  () => ({}),
  mapDispatchToProps
)(withRouter(ErrorController))
