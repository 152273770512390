import { path } from 'ramda'

const CORS_PROXY = 'https://cors-anywhere.herokuapp.com/'

const {
  REACT_APP_RECO_USER_GFORM_STEP_1,
  REACT_APP_RECO_USER_GFORM_STEP_2,
  REACT_APP_RECO_USER_GFORM_FEEDBACK_POS,
  REACT_APP_RECO_USER_GFORM_FEEDBACK_NEG,
} = process.env

// to get field, visite the form page, open the chrome console the type
// document.querySelectorAll('input').forEach((input) => console.log(input.name, input.getAttribute('aria-label')))
const googleFormFieldRef = {
  'entry.1885607967': ['leader', 'gender'],
  'entry.1179468386': ['leader', 'firstname'],
  'entry.384367181': ['leader', 'lastname'],
  'entry.1706710145': ['leader', 'job_title'],
  'entry.1418638755': ['leader', 'email'],
  'entry.1121325729': ['leader', 'company_name'],
  'entry.1551954922': ['leader', 'address'],
  'entry.1333922187': ['leader', 'address_2'],
  'entry.1640371595': ['leader', 'zip'],
  'entry.1034768494': ['leader', 'city'],
  'entry.2134645154': ['contact_1', 'firstname'],
  'entry.1295816957': ['contact_1', 'email'],
  'entry.478064256': ['contact_2', 'firstname'],
  'entry.16210202': ['contact_2', 'email'],
  'entry.1952792229': ['contact_3', 'firstname'],
  'entry.1880766215': ['contact_3', 'email'],
  'entry.687947046': ['contact_4', 'firstname'],
  'entry.2119459422': ['contact_4', 'email'],
  'entry.812198307': ['invitees_count'],
  'entry.161094555': ['card_amount'],
}

export function saveUsersRecommandation(state) {
  const data = new FormData()

  Object.keys(googleFormFieldRef).forEach(inputName => {
    const dataPath = googleFormFieldRef[inputName]
    const value = path(dataPath, state)
    data.append(inputName, value || '')
  })
  return fetch(CORS_PROXY + REACT_APP_RECO_USER_GFORM_STEP_1, {
    method: 'POST',
    body: data,
  })
}

const googleFormFieldRefStep2 = {
  'entry.603554430': 'leader_email',
  'entry.2131920041': 'gender',
  'entry.688300081': 'firstname',
  'entry.1221938185': 'lastname',
  'entry.1558514868': 'email',
  'entry.796632441': 'team',
  'entry.1715428430': 'address',
  'entry.1265827311': 'address_2',
  'entry.1413840727': 'city',
  'entry.1712470165': 'zip',
  'entry.1607259342': 'invitees_count',
  'entry.783325605': 'card_amount',
  'entry.1488323546': 'company_name',
}
export function saveUsersRecommandationInvitee(state) {
  const data = new FormData()

  Object.keys(googleFormFieldRefStep2).forEach(inputName => {
    const key = googleFormFieldRefStep2[inputName]
    data.append(inputName, state[key] || '')
  })
  return fetch(CORS_PROXY + REACT_APP_RECO_USER_GFORM_STEP_2, {
    method: 'POST',
    body: data,
  })
}

const googleFormFieldRefFeedbackPos = {
  'entry.870736096': ['user', 'email'],
  'entry.1128488973': ['comment'],
  'entry.575342427': ['drh', 'firstname'],
  'entry.1235882541': ['drh', 'lastname'],
  'entry.458104066': ['drh', 'email'],
  'entry.582328873': ['rating'],
}
export function saveUsersPositiveFeedback(state) {
  const data = new FormData()

  Object.keys(googleFormFieldRefFeedbackPos).forEach(inputName => {
    const dataPath = googleFormFieldRefFeedbackPos[inputName]
    const value = path(dataPath, state)
    data.append(inputName, value || '')
  })
  return fetch(CORS_PROXY + REACT_APP_RECO_USER_GFORM_FEEDBACK_POS, {
    method: 'POST',
    body: data,
  })
}

const googleFormFieldRefFeedbackNeg = {
  'entry.870736096': ['user', 'email'],
  'entry.1128488973': ['comment'],
  'entry.661111702': ['rating'],
}
export function saveUsersNegativeFeedback(state) {
  const data = new FormData()

  Object.keys(googleFormFieldRefFeedbackNeg).forEach(inputName => {
    const dataPath = googleFormFieldRefFeedbackNeg[inputName]
    const value = path(dataPath, state)
    data.append(inputName, value || '')
  })
  return fetch(CORS_PROXY + REACT_APP_RECO_USER_GFORM_FEEDBACK_NEG, {
    method: 'POST',
    body: data,
  })
}
