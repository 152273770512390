import { STATIC_WEBSITE } from '../utils/config'

///CORPO
export { LUNCHR_CARD_TYPE } from '@themenu/shared/lib/constants/corpo'

export const PAYMENT_METHODS = {
  MEAL_VOUCHER: 'MEAL_VOUCHER',
  CREDIT_CARD: 'CREDIT_CARD',
}

export const LUNCHR_CARD_TOKEN = 'lunchr'
export const CREDIT_CARD_TOKEN = 'creditCard'

export const MEAL_VOUCHER_TRANSACTION_TYPE = {
  ORDER: 'ORDER',
  LUNCHR_CARD: 'LUNCHR_CARD_PAYMENT',
  CREDIT: 'MEAL_VOUCHER_CREDIT',
  EXPIRATION: 'MEAL_VOUCHER_EXPIRATION',
  RENEWAL: 'MEAL_VOUCHER_RENEWAL',
  PAYMENT_ATTEMPT: 'PAYMENT_ATTEMPT',
  PAYMENT: 'PAYMENT',
}

export const MEAL_VOUCHER_TRANSACTION_VENDOR = {
  UBER_EATS: 'UBER_EATS',
  MONEXT: 'MONEXT',
  NEOBANK: 'NEOBANK',
}

export const ONBOARDING_RESTO_VAT_MAXLENGTH = 15

export const COMPANY_CGPS_FR_URL = `${STATIC_WEBSITE}/legal/cgps-carte-swile-beneficiaires`

export default {
  LUNCHR_CARD_TOKEN,
  PAYMENT_METHODS,
}
