export const getIntercomSettings = ({
  user,
  currentCompanyUuid,
  companyExtraInfo
}) => {
  if (!user) return {}

  const currentCompany =
    (currentCompanyUuid && user.companies
      ? user.companies.find(({ uuid }) => uuid === currentCompanyUuid)
      : null) ||
    (user.companies && user.companies[0])
  const companyInfo = currentCompany
    ? {
        id: currentCompany.uuid,
        name: currentCompany.name,
        ...(companyExtraInfo
          ? {
              people: companyExtraInfo.users_count,
              mvo_frequency:
                companyExtraInfo.meal_voucher_order_renewal_frequency,
              siret: companyExtraInfo.siret,
              created_at: companyExtraInfo.created_at,
              onboarding_solo: companyExtraInfo.self_served,
              declared_user_number: companyExtraInfo.declared_user_number,
              card_provider: companyExtraInfo.card_provider
            }
          : null)
      }
    : {}

  return {
    // Note: create one user by company membership because intercom can't handle them otherwise.
    user_id: [...(currentCompany ? [currentCompany.uuid] : []), user.uuid].join(
      '--'
    ),
    email: user.email,
    name: `${user.first_name} ${user.last_name}`,
    created_at: toTimestamp(user.invitation_confirmed_at),
    solde_card: user.meal_voucher_info && user.meal_voucher_info.balance,
    signed_up: toTimestamp(user.invitation_confirmed_at),
    ...(currentCompany && currentCompany.programs
      ? {
          role: currentCompany.programs
            .map(({ kind, role }) => `${kind}_${role}`)
            .join(','),
          plan: currentCompany.programs.map(({ kind }) => kind).join(',')
        }
      : null),
    company: companyInfo
  }
}

export default (appId, intercomSettings) => {
  window.intercomSettings = {
    app_id: appId,
    ...intercomSettings
  }

  if (typeof window.Intercom === 'function') {
    window.Intercom('reattach_activator')
    window.Intercom('update', window.intercomSettings)
  } else {
    const Intercom = function() {
      Intercom.c(arguments)
    }
    Intercom.q = []
    Intercom.c = function(args) {
      Intercom.q.push(args)
    }
    window.Intercom = Intercom

    includeScript(`https://widget.intercom.io/widget/${appId}`)
  }
}

const includeScript = src => {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.async = true
  script.src = src
  document.body.appendChild(script)
}

const toTimestamp = dateString =>
  dateString ? Math.round(new Date(dateString).getTime() / 1000) : null
