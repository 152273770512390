import styled from 'styled-components'
import PropTypes from 'prop-types'

import { themeGet } from 'styled-system'
import { darken } from 'polished'

import * as Utils from '../utils'
import { COLORS } from '../../constants'

const Textarea = styled.textarea`
  padding: 10px 16px;
  width: 100%;
  min-height: ${props => props.minHeight || '120px'};
  color: ${themeGet('colors.BLUE_DARK')};
  font-family: ${themeGet('fonts.main')};
  font-size: ${props => props.fontSize || '14px'};
  line-height: 1.5;
  vertical-align: middle;
  resize: vertical;

  background-color: ${themeGet('colors.WHITE')};
  border: 1px solid ${themeGet('colors.GRAY_COOL')};
  border-radius: 6px;
  outline: none;
  transition: all 0.35s ease;

  &:hover {
    border-color: ${({ theme }) => darken(0.2, theme.colors.GRAY_COOL)};
  }

  /* disabled */
  ${props =>
    props.disabled &&
    `
    ${Utils.disabled}

    &:hover {
      border-color: ${themeGet('colors.GRAY_COOL')};
    }
  `};

  /* Variant */
  ${props =>
    props.isVariant &&
    `
    &:focus {
      border-color: ${COLORS.GREEN}
    }
  `}

  ${props =>
    props.isErrorVariant &&
    `
     border-color: ${COLORS.RED};
  `}

  ${({ theme }) => `
    &::-webkit-input-placeholder {
      color: ${props =>
        props.placeholderColor
          ? props.placeholderColor
          : theme.colors.GRAY_COOL};
    }

    &:-moz-placeholder {
      color: ${props =>
        props.placeholderColor
          ? props.placeholderColor
          : theme.colors.GRAY_COOL};
    }

    &::-moz-placeholder {
      color: ${props =>
        props.placeholderColor
          ? props.placeholderColor
          : theme.colors.GRAY_COOL};
    }

    &:-ms-input-placeholder {
      color: ${props =>
        props.placeholderColor
          ? props.placeholderColor
          : theme.colors.GRAY_COOL};
    }
  `};
`

Textarea.propTypes = {
  minHeight: PropTypes.string,
  fontSize: PropTypes.string,
  disabled: PropTypes.bool,
  isVariant: PropTypes.bool,
  placeholderColor: PropTypes.string
}

export default Textarea
