import React from 'react'
import styled from 'styled-components'
import { media } from '@themenu/ui'

import CongratulationsDesktop from './CongratulationsDesktop'
import CongratulationsMobile from './CongratulationsMobile'

const CongratulationsDesktopWrapper = styled.div`
  display: none;
  ${media.MD`
    display: block;
  `}
`

const CongratulationsMobileWrapper = styled.div`
  display: block;
  ${media.MD`
  display: none;
`}
`
export function CompletePage(params) {
  return (
    <div>
      <CongratulationsDesktopWrapper>
        <CongratulationsDesktop {...params} />
      </CongratulationsDesktopWrapper>
      <CongratulationsMobileWrapper>
        <CongratulationsMobile {...params} />
      </CongratulationsMobileWrapper>
    </div>
  )
}
