export const LOGIN = 'app/Auth/LOGIN'
export const LOGIN_SUCCEEDED = 'app/Auth/LOGIN_SUCCEEDED'
export const LOGIN_FAILED = 'app/Auth/LOGIN_FAILED'

export const UPDATE_USER = 'app/Auth/UPDATE_USER'
export const UPDATE_USER_SUCCEEDED = 'app/Auth/UPDATE_USER_SUCCEEDED'
export const RESET_PASSWORD_SUCCEEDED = 'app/Auth/RESET_PASSWORD_SUCCEEDED'
export const LOGOUT_SUCCEEDED = 'app/Auth/LOGOUT_SUCCEEDED'
export const FETCH_PROFILE_SUCCEEDED = 'app/Auth/FETCH_PROFILE_SUCCEEDED'

export const FETCH_IS_AFFILIATE_SUCCEEDED = 'app/Auth/IS_AFFILIATE_SUCCEEDED'
export const FETCH_IS_AFFILIATE_FAILED = 'app/Auth/IS_AFFILIATE_FAILED'
