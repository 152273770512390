import React from 'react'
import styled from 'styled-components'
import { media, Button } from '@themenu/ui'

import Cloudinary from '@themenu/shared/lib/services/cloudinary'
import ReferralHeader from '../../../ReferralWrap/ReferralHeader/ReferralHeader'
import { Confettis } from './Confettis'

const FLAG_MAN = Cloudinary.url('web-assets/reco_flag_man_3', {
  secure: true,
})

const BodyWrap = styled.div`
  background: white;
  margin: 0 6%;
  ${media.SM`
    margin: 0 13%;
    padding-bottom: 100px;
  `}
  @media screen and (min-width: 91rem) {
    padding-bottom: 400px;
  }
`

const Container = styled.div`
  display: flex;
  justify-content: center;
`

const FlagManImage = styled.img`
  height: 500px;
`

const ConfettisTextTitle = styled.div`
  font-size: 60px;
  color: #1b2733;
  font-weight: bold;
  margin: 0 34px;
  padding-top: 150px;
`
const ConfettisTextDescription = styled.div`
  font-weight: bold;
  font-size: 35px;
  line-height: 44px;
  color: #1b2733;
  margin: 0 34px;
`
const ConfettisTextParapgraph = styled.div`
  font-size: 24px;
  line-height: 32px;
  color: #8c97a1;
  margin: 0 34px;
  padding: 0 54px;
`
const ConfettisText = styled.div`
  text-align: center;
  height: 100%;
`

const ContainerCentered = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledButton = styled(Button)`
  margin-top: 20px;
`

export default function CongratulationsDesktop({ title, description, text }) {
  const onClick = () => {
    window.location = 'https://www.lunchr.co/'
  }
  return (
    <BodyWrap>
      <ReferralHeader />
      <Container>
        <FlagManImage src={FLAG_MAN} alt="flag man" />
        <ContainerCentered>
          <Confettis>
            <ConfettisText>
              <ConfettisTextTitle>{title}</ConfettisTextTitle>
              <ConfettisTextDescription>{description}</ConfettisTextDescription>
              <br />
              <ConfettisTextParapgraph>{text}</ConfettisTextParapgraph>
            </ConfettisText>
          </Confettis>
          <StyledButton color="green" size="large" onClick={onClick}>
            Accéder au site Lunchr.co
          </StyledButton>
        </ContainerCentered>
      </Container>
    </BodyWrap>
  )
}
