import { combineReducers } from 'redux-immutable'

import auth, * as authActions from './auth'
import places, * as placesActions from './places'
import searchAddresses from './searchAddresses'
import db from './db'
import dbjs from './dbjs'
import ui from './ui'
import uicorpo from './uicorpo'
import uiresto from './uiresto'
import cookies from './cookies'
import router from './router'

export const actions = {
  auth: authActions,
  places: placesActions,
}

/**
 * A filter to make state exportable (filter for external tools)
 *
 * could be improved : each reducer should handle filtering of its own datas
 */
export const stateExporter = state => {
  const stateJs = state.toJS()

  return {
    ...stateJs,
    creditCards: 'filtered',
    auth: stateJs.auth &&
      stateJs.auth.user && {
        id: stateJs.auth.user.id,
      },
  }
}

export default combineReducers({
  cookies,
  auth,
  places,
  searchAddresses,
  db,
  dbjs,
  ui,
  uicorpo,
  uiresto,
  router,
})
