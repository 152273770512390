import React from 'react'
import { path } from 'ramda'
import { Field } from 'formik'
import styled from 'styled-components'

import { COLORS } from '@themenu/ui'
import Textarea from '@themenu/ui/src/components/Textarea/Textarea.style'

import { FormField } from '../FormField'

const StyledField = Textarea.withComponent(Field)

const StyledTextarea = styled(StyledField)`
  &:hover,
  &:focus {
    border-color: #b7bcc0;
  }

  ${props =>
    props.disabled &&
    `
    background-color: ${COLORS.GRAY_COOL};
  `}

  ${props => {
    return (
      props.validators.showError &&
      `
      border-color: ${COLORS.PINK};

      &:hover,
      &:focus {
        border-color: ${COLORS.PINK};
      }
    `
    )
  }}
`

export function FormTextarea({
  title,
  name,
  values,
  handleChange,
  handleBlur,
  touched,
  errors,
  disabled,
}) {
  const accessPath = path(name.split('.'))
  const error = accessPath(errors)
  const wasTouched = accessPath(touched) ? 'touched' : undefined
  const value = accessPath(values)
  const isValid = !error ? 'valid' : undefined

  const showValid = value && isValid
  const showError = wasTouched && !isValid

  const validators = {
    showValid,
    showError,
  }

  const fieldProps = {
    title,
    error,
    showError,
  }

  return (
    <FormField {...fieldProps}>
      <StyledTextarea
        component="textarea"
        name={name}
        onChange={handleChange}
        onBlur={handleBlur}
        validators={validators}
        disabled={disabled}
      />
    </FormField>
  )
}
