import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { omit } from 'ramda'

import Auth from '@themenu/shared/lib/services/auth'

const isConnected = () => Auth.isLoggedIn() && Auth.user

/**
 * Redirects the user to /signin if user is not logged in
 * @param  {Object}    options.component
 * @return {Object}
 */
const PrivateRoute = props => {
  const render = ({ location }) =>
    isConnected() ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/signin',
          state: {
            next: location
          }
        }}
      />
    )

  return <Route {...omit(['component'], props)} render={render} />
}

export default withRouter(PrivateRoute)
