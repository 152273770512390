import moment from 'moment'

import { fetchPaymentsHistory as fetchPaymentsHistoryRequest } from '../../services/api.banking'
import { FETCH_MEAL_VOUCHER_TRANSACTIONS_SUCCESS } from '../db/types'

const COUNT_PER_PAGE = 20

export const fetchPaymentsHistory = ({
  before = moment()
    .add(1, 'days')
    .format(),
  per = COUNT_PER_PAGE,
} = {}) => async dispatch => {
  const res = await fetchPaymentsHistoryRequest({
    before,
    per,
  })
  const paymentsHistory = res.payments_history.map(payment => ({
    ...payment,
    id: [payment.transaction_number, payment.executed_at, payment.type].join(
      ' - '
    ),
  }))

  dispatch({
    type: FETCH_MEAL_VOUCHER_TRANSACTIONS_SUCCESS,
    list: paymentsHistory,
  })
}

export default (state = {}, action) => {
  switch (action.type) {
    case FETCH_MEAL_VOUCHER_TRANSACTIONS_SUCCESS:
      const newState = { ...state }
      action.list.forEach(transaction => {
        newState[transaction.id] = transaction
      })
      return newState

    default:
      return state
  }
}
