import { authorizedSSOOrigins } from './config'

export const CREATE_SESSION_SUCCEEDED = 'lunchr/CREATE_SESSION_SUCCEEDED'
export const DELETE_SESSION = 'lunchr/DELETE_SESSION'
export const DELETE_SESSION_SUCCEEDED = 'lunchr/DELETE_SESSION_SUCCEEDED'

export const onReceivedMessage = (tunnel, callback) => {
  let receivedResponses = 0

  return async event => {
    try {
      if (authorizedSSOOrigins.indexOf(event.origin) === -1) {
        return
      }

      const data = JSON.parse(event.data)

      switch (data.action) {
        case DELETE_SESSION:
          return
        case CREATE_SESSION_SUCCEEDED:
        case DELETE_SESSION_SUCCEEDED:
          receivedResponses++

          if (receivedResponses === authorizedSSOOrigins.length) {
            return callback()
          }

          return
        default:
          if (data.payload) {
            await callback({
              accessToken: data.payload.accessToken,
              refreshToken: data.payload.refreshToken,
            })
            event.source.postMessage(
              JSON.stringify({ action: CREATE_SESSION_SUCCEEDED }),
              event.origin
            )
          }
      }
    } catch (error) {
      console.error(error)
      const { Raven } = window
      Raven && Raven.captureException(error, { extra: { event } })
    }
  }
}
