import { includes, map, prop, without } from 'ramda'
import React from 'react'
import { css } from 'styled-components'

import { SCREEN_SIZES } from '../constants'

/**
 * Lightens or darkens a given color
 * @param  {String} col
 * @param  {Number} amt
 * @return {String}
 */
export const toneColor = (col, amt) => {
  let usePound = false

  if (col[0] === '#') {
    col = col.slice(1)
    usePound = true
  }

  const num = parseInt(col, 16)
  let r = (num >> 16) + amt

  if (r > 255) {
    r = 255
  } else if (r < 0) {
    r = 0
  }

  let b = ((num >> 8) & 0x00ff) + amt

  if (b > 255) {
    b = 255
  } else if (b < 0) {
    b = 0
  }

  let g = (num & 0x0000ff) + amt

  if (g > 255) {
    g = 255
  } else if (g < 0) {
    g = 0
  }

  return (usePound ? '#' : '') + (g | (b << 8) | (r << 16)).toString(16)
}

/**
 * Disabled helper
 * @type {CSS}
 */
export const disabled = css`
  cursor: default;
  opacity: 0.5;
`

/**
 * Placeholder helper
 * @type {CSS}
 */
export const placeholder = css`
  &:-moz-placeholder {
    @content;
  }
  &::-moz-placeholder {
    @content;
  }
  &:-ms-input-placeholder {
    @content;
  }
  &::-webkit-input-placeholder {
    @content;
  }
`

/**
 * Fillspace helper
 * @type {CSS}
 */
export const fillSpace = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

/**
 * Scrollbar helper
 * @type {CSS}
 */
export const scrollBar = css`
  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.2);

    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
    }
  }
`

/**
 * Truncate text helper
 * @type {CSS}
 */
export function truncate(width) {
  return `
    display: inline-block;
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `
}

/**
 * Component disabled helper
 * @type {CSS}
 */
export const componentDisabled = css`
  cursor: default;
  opacity: 0.5;
`

export const media = Object.keys(SCREEN_SIZES).reduce(
  (acc, label) => ({
    ...acc,
    [label]: (...args) => css`
      @media screen and (min-width: ${SCREEN_SIZES[label]}) {
        // prettier-ignore
        ${css(...args)}
      }
    `
  }),
  {}
)

export function pickChildren(klass, children) {
  return filterChildren(
    (klasses, child) => (includes(child.type.name, klasses) ? child : null),
    klass,
    children
  )
}

export function withoutChildren(klass, children) {
  return filterChildren(
    (klasses, child) => (includes(child.type.name, klasses) ? null : child),
    klass,
    children
  )
}

export function filterChildren(predicate, klass, children) {
  const klassList = map(prop('name'), Array.isArray(klass) ? klass : [klass])
  const filteredChildren = React.Children.map(children, child =>
    predicate(klassList, child)
  )

  return without([null], filteredChildren)
}
