import React, { useState } from 'react'
import styled from 'styled-components'
import { Formik } from 'formik'
import { Grid } from '@material-ui/core'
import Svg from 'react-inlinesvg'

import { media } from '@themenu/ui'
import { saveUsersPositiveFeedback } from '../../../../../services/googleform'
import Cloudinary from '@themenu/shared/lib/services/cloudinary'
import { FormInput } from '../../../common/components/FormInput'
import { FormTextarea } from '../../../common/components/FormTextarea'
import { GridItem } from '../../../common/components/GridItem'
import { EmojiInText } from '../../../common/components/EmojiInText'
import { hasErrors } from '../../../common/helpers/hasErrors'
import { feedbackPositiveSchema } from '../../../common/StateSchema'
import {
  FeedBackButton,
  FeedBackButtonSuccessMessage,
} from '../../common/FeedBackButton'
import { FeedBackText } from '../../common/FeedBackText'

const RECO_FOOD_FAMILY = Cloudinary.url('web-assets/reco_food_family', {
  secure: true,
})

const StyledFoodFamily = styled(Svg)`
  svg {
    width: 100%;
    height: auto;

    ${media.SM`
      height: 312px;
      margin: 29px 0 69px;
    `}
  }
`

export function PositiveFeedBackForm({ values }) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [httpError, setHttpError] = useState(false)

  return (
    <div>
      <Formik
        initialValues={values}
        validateOnChange={true}
        validationSchema={feedbackPositiveSchema}
        onSubmit={async values => {
          setLoading(true)
          setSuccess(false)
          setHttpError(false)
          const { ok } = await saveUsersPositiveFeedback(values)
          setLoading(false)
          if (!ok) return setHttpError(true)
          setSuccess(true)
          return null
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => {
          const commonProps = {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }
          return (
            <form method="post" onSubmit={handleSubmit}>
              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={10} lg={10} xl={10}>
                  <FeedBackText>
                    Vous avez une remarque, un commentaire, c’est par ici
                    <EmojiInText
                      emoji="backhand-index-pointing-down"
                      size="20px"
                    />
                  </FeedBackText>
                </GridItem>
              </Grid>

              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormTextarea
                    name="comment"
                    {...commonProps}
                    disabled={success}
                  />
                </GridItem>
              </Grid>

              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <StyledFoodFamily src={RECO_FOOD_FAMILY} cacheGetRequests />
                </GridItem>
              </Grid>

              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={10} lg={10} xl={10}>
                  <FeedBackText>
                    Vous voulez que votre entreprise passe chez Lunchr,
                    donnez-nous le contact de votre DRH, on s’occupe du reste :
                  </FeedBackText>
                </GridItem>
              </Grid>

              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormInput
                    type="text"
                    name="drh.firstname"
                    title="Son prénom"
                    disabled={success}
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormInput
                    type="text"
                    name="drh.lastname"
                    title="Son nom"
                    disabled={success}
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormInput
                    type="text"
                    name="drh.email"
                    title="Son email"
                    disabled={success}
                    {...commonProps}
                  />
                </GridItem>
              </Grid>

              <FeedBackButton
                disabled={success || loading || hasErrors(errors)}
                error={httpError}
                success={success}
              >
                <FeedBackButtonSuccessMessage>
                  Merci! <EmojiInText emoji="raising-hands" size="20px" />
                </FeedBackButtonSuccessMessage>
              </FeedBackButton>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
