import QueryString from 'query-string'
import { EventEmitter } from 'events'
import Auth, { oauthFetch } from '@themenu/shared/lib/services/auth'
import { API_ENDPOINT } from '@themenu/shared/lib/utils/request'

import {
  fetchProfile as getProfile,
  fetchCompleteProfile,
} from '@themenu/shared/lib/services/profile'
import { buildEndpoint } from '@themenu/shared/lib/utils/api'
import patchFetch from '@themenu/shared/lib/middlewares/patch-fetch-jwt-refresh'
import { getEnv } from '@themenu/shared/lib/utils/env'
import { getRequestHeaders } from '@themenu/shared/lib/helpers/request'

const SETUP_INTENT_ENDPOINT = 'users/me/cards/create_setup_intent'
const RECOVER_PASSWORD_ENDPOINT = 'password/recover'
const VALIDATE_PASSWORD_RECOVERY_TOKEN_ENDPOINT = 'password/recover/:token'
const RESET_PASSWORD_ENDPOINT = 'password'
const PROFILE_ENDPOINT = 'users/me'
const PROFILE_EMAIL_VALIDATION_TOKEN_ENDPOINT = 'users/me/confirmation_code'

const CREDIT_CARDS_ENDPOINT = 'users/me/cards'
const CREDIT_CARDS_VALIDATE_AUTH_ENDPOINT =
  'users/me/cards/:id/validate_authentication'
const CREDIT_CARD_ENDPOINT = 'users/me/cards/:id'
const USERS_ADDRESSES = 'users/:userId/addresses'
const UPLOADS_ENDPOINT = 'uploads'
const PHONE_SEND_VERIFY_ENDPOINT = 'phone_number/verify'
const PHONE_RESEND_VERIFY_ENDPOINT = 'phone_number/resend_verify'
const CONFIGURATION_ENDPOINT = 'configuration'
const COMPANIES_USERS_ENDPOINT = 'companies/:companyId/users'
const SAFETY_CARD_ORDERS_ENDPOINT = 'safety_card_orders'
const SAFETY_CARD_ORDERS_PRICE_AND_ORDER_LIMIT_ENDPOINT =
  'safety_card_orders/price_and_order_limit'

const EMPTY_RESPONSE = Object.freeze({})
const fetch = patchFetch(getEnv().fetch)

export const errorEmitter = new EventEmitter()

/*
 ** We could use the request function from shared, but we need the ignoreAccessToken argument specificaly for mainapp
 **
 */
export function request(
  method,
  endpoint,
  data = undefined,
  headers = {},
  apiEndpoint = API_ENDPOINT,
  noResponse = false,
  encode = true,
  withStatusCode = false,
  ignoreAccessToken = false
) {
  let url = `${apiEndpoint}/${endpoint}`
  const requestHeaders = getRequestHeaders(headers)

  if (method === 'GET') {
    const queryString = QueryString.stringify(data, { encode })

    if (queryString) {
      url += '?' + queryString
    }
  }

  if (method === 'GET') {
    const requestPromise = fetch(url, {
      method,
      headers: requestHeaders,
    })

    if (noResponse) return requestPromise

    return requestPromise.then(getResponse)
  }

  const requestPromise = fetch(url, {
    method,
    body: JSON.stringify(data),
    headers: requestHeaders,
  })

  if (noResponse) return requestPromise

  return requestPromise.then(response =>
    getResponse(response, withStatusCode, ignoreAccessToken)
  )
}

const parseBodyAsJson = body => {
  try {
    return body.length > 0 && !body.startsWith('<')
      ? JSON.parse(body)
      : EMPTY_RESPONSE
  } catch (error) {
    console.error(error)
  }
}

/**
 * Parses the response from the API
 * If the response is not OK, reject with an error
 * @param  {Object} response
 * @return {Object}
 */
export const getResponse = (
  response,
  withStatusCode = false,
  ignoreAccessToken = false
) => {
  // Parsing the response as text prevents JSON parsing empty response error
  return response.text().then(txt => {
    const data = parseBodyAsJson(txt)

    // When reseting the password we do not always want to log-in the user automatically
    if (!ignoreAccessToken) {
      const { headers } = response

      // In case we have an access token in the response header we set it as a JTW
      const headerToken = headers.get('Authorization')
      if (headerToken) {
        const accessToken = headerToken.split('Bearer ')[1]
        Auth.setJwt(accessToken)
      }
    }

    if (response.ok) {
      if (withStatusCode) return { httpStatus: response.status, ...data }
      return data
    }

    errorEmitter.emit('request:error', response.status, { ...data })
    return Promise.reject({ status: response.status, ...data })
  })
}

/**
 * Makes the API call to log the user in
 * @param  {String} username
 * @param  {String} password
 * @return {Promise}
 */
export const login = async (username, password) => {
  const response = await oauthFetch({
    grant_type: 'password',
    username,
    password,
  })
  const { access_token, refresh_token } = await getResponse(response)

  Auth.setJwt(access_token, refresh_token)
  const data = await fetchCompleteProfile()

  return {
    user: data.user,
    accessToken: access_token,
    refreshToken: refresh_token,
  }
}

export const loginByAccessCode = async ({ token, code }) => {
  const response = await oauthFetch({
    grant_type: 'password',
    application_access_code: code,
    lunchr_card_public_token: token,
  })
  const { access_token, refresh_token } = await getResponse(response)
  Auth.setJwt(access_token, refresh_token)

  return fetchCompleteProfile()
}

/**
 * Makes the api call to let the user recover its password by sending him an email
 * @param  {String} email
 * @return {Promise}
 */
export const recoverPassword = email => {
  return request('POST', RECOVER_PASSWORD_ENDPOINT, { email })
}

/**
 * Makes the api call to validate a password recovery token
 * @param  {String} token
 * @return {Promise}
 */
export const validatePasswordRecoveryToken = token => {
  return request(
    'GET',
    buildEndpoint(VALIDATE_PASSWORD_RECOVERY_TOKEN_ENDPOINT, { token })
  )
}

/**
 * Makes the api call to reset the user password given a valid recovery token
 * @param  {String} options.token
 * @param  {String} options.password
 * @return {Promise}
 */
export const resetPassword = async (
  { token, password },
  forceConnect = false
) => {
  const data = await request(
    'PUT',
    RESET_PASSWORD_ENDPOINT,
    {
      recovery_token: token,
      password,
    },
    {},
    API_ENDPOINT,
    false,
    true,
    false,
    !forceConnect
  )
  return data
}

/**
 * Makes the api call to update the user
 * We need to send a valid token when the user whant to change his email
 * @param  {Object} options.auth
 * @param  {Object} options.data
 * @param  {String} emailToken
 * @return {Promise}
 */
export const updateUser = (
  {
    firstName,
    lastName,
    language,
    phoneNumber,
    email,
    avatarPicture,
    password,
  },
  emailToken = null
) => {
  return request('PUT', PROFILE_ENDPOINT, {
    user: {
      first_name: firstName,
      last_name: lastName,
      language,
      phone_number: phoneNumber,
      email,
      avatar_picture: avatarPicture,
      password,
    },
    email_token: emailToken,
  })
}

/**
 * Makes the api call to generate and SMS for the user that whant to change his email
 * @return {Promise}
 */
export const askEmailValidationTokenGeneration = () => {
  return request('POST', PROFILE_EMAIL_VALIDATION_TOKEN_ENDPOINT)
}

export const fetchUserAddresses = userId => {
  return request('GET', buildEndpoint(USERS_ADDRESSES, { userId }))
}

/**
 * Makes the api call to get the user's profile
 * @return {Promise}
 */
export const fetchProfile = () => {
  return getProfile()
}

/**
 * Makes the api call to fetch the user credit cards
 * @return {Promise}
 */
export const fetchCreditCards = () => {
  return request('GET', CREDIT_CARDS_ENDPOINT)
}

/**
 * Makes the api call to update a credit card
 * @param  {String} id
 * @param  {String} options.name
 * @return {Promise}
 */
export const updateCreditCard = (id, { name }) => {
  return request('PUT', buildEndpoint(CREDIT_CARD_ENDPOINT, { id }), {
    card: {
      name,
    },
  })
}
export const validateAuthCreditCard = id => {
  return request(
    'PUT',
    buildEndpoint(CREDIT_CARDS_VALIDATE_AUTH_ENDPOINT, { id })
  )
}

/**
 * Makes the api call to destroy a credit card
 * @param  {String} id
 * @return {Promise}
 */
export const deleteCreditCard = id => {
  return request('DELETE', buildEndpoint(CREDIT_CARD_ENDPOINT, { id }))
}

/**
 * Makes the api call to create a credit card
 * @param  {Strign} token
 * @return {Promise}
 */
export const createCreditCard = paymentMethod => {
  return request('POST', CREDIT_CARDS_ENDPOINT, {
    payment_method_id: paymentMethod,
  })
}

/**
 * Makes the api call to create an upload url
 * @param  {String} filename
 * @return {Promise}
 */
export const createUpload = ({ name, contentType }) => {
  return request('POST', UPLOADS_ENDPOINT, {
    upload: {
      filename: name,
      content_type: contentType,
    },
  })
}

export const fetchConfiguration = () => request('GET', CONFIGURATION_ENDPOINT)

/**
 * Ask for a code to verifiy phone number
 * if a verificationId is given, it will be cancelled by the Api
 * @param  {String} phoneNumber
 * @param  {String} [verificationId=null]
 * @return {Promise}
 */
export const verifyPhoneNumber = (phoneNumber, verificationId = null) => {
  if (verificationId) {
    return request('POST', buildEndpoint(PHONE_RESEND_VERIFY_ENDPOINT), {
      phone_number: phoneNumber,
      code_length: 6,
      verification_id: verificationId,
    })
  }

  return request('POST', buildEndpoint(PHONE_SEND_VERIFY_ENDPOINT), {
    phone_number: phoneNumber,
    code_length: 6,
  })
}

export const getCompanyUsers = ({ companyId, restaurantId }) => {
  return request(
    'GET',
    buildEndpoint(COMPANIES_USERS_ENDPOINT, { companyId }),
    { restaurant_id: restaurantId }
  )
}

export const orderSafetyCards = ({
  stripeCardToken,
  cardsCount,
  streetAndNumber,
  city,
  zipCode,
  country,
}) =>
  request('POST', buildEndpoint(SAFETY_CARD_ORDERS_ENDPOINT), {
    payload: {
      stripe_card_token: stripeCardToken,
      cards_count: cardsCount,
      street_and_number: streetAndNumber,
      city,
      zip_code: zipCode,
      country,
    },
  })

export const fetchSafetyCardsOrderPriceAndOrderLimit = () =>
  request(
    'GET',
    buildEndpoint(SAFETY_CARD_ORDERS_PRICE_AND_ORDER_LIMIT_ENDPOINT)
  )

export const createSetupIntent = () =>
  request('POST', buildEndpoint(SETUP_INTENT_ENDPOINT))

export { fetchCompleteProfile } from '@themenu/shared/lib/services/profile'

export default {
  login,
  fetchCompleteProfile,
  recoverPassword,
  validatePasswordRecoveryToken,
  resetPassword,
  updateUser,
  fetchCreditCards,
  updateCreditCard,
  deleteCreditCard,
  createCreditCard,
  createUpload,
  fetchProfile,
  verifyPhoneNumber,
  askEmailValidationTokenGeneration,
}
