import React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { COLORS, media } from '@themenu/ui'

import { ReferralNoticeRoute } from '../../../../routes'

const StyledFooter = styled.footer`
  padding-top: ${props => props.paddingTop || '100px'};
  padding-bottom: 100px;
`
const Line = styled.hr`
  color: #ededed;
  height: 1px;
`
const Text = styled.a`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY};
  padding-top: 22px;
`
const Notice = styled.div`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY};
  padding-bottom: 22px;
  ${media.SM`
    padding: 0 13%;
`}
`
const StyledNotice = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: ${COLORS.GRAY};
`
const StyledNoticeItalic = styled.span`
  font-style: italic;
`

const StyledUnderline = styled(Link)`
  color: ${COLORS.GRAY};
  text-decoration: underline;
`

function ReferralFooter({ paddingTop, notice }) {
  return (
    <StyledFooter paddingTop={paddingTop}>
      {notice && (
        <Notice>
          <StyledNotice>
            <StyledNoticeItalic>
              Avant de nous donner votre adresse, vous voulez en savoir plus sur
              les détails de l’opération ?
            </StyledNoticeItalic>
            <br />
            C’est tout à fait normal. C’est pour ça que vous les trouverez
            facilement{' '}
            <StyledUnderline target="_blank" to={ReferralNoticeRoute.spec}>
              ici
            </StyledUnderline>
          </StyledNotice>
        </Notice>
      )}
      <Line />
      <Text href="https://www.lunchr.co" role="button">
        Aller sur lunchr
      </Text>
    </StyledFooter>
  )
}
export default ReferralFooter
