import Route from 'route-parser'

export const SignInRoute = new Route('/signin')
export const ProfileRoute = new Route('/profile')

export const LunchrCardRoute = new Route('/payments/lunchr-card(/:id)')
export const MealVoucherCardRoute = new Route('/payments/swile-card(/:id)')

export const ActivateLunchrCardRoute = new Route('/card-activate/:uuid(/:step)')
export const ActivateAnonymousCardRoute = new Route('/anonymous-card-activate')

export const restoOnboardingBase = '/restaurants/onboarding/:salesforceId'
export const restoOnboardingLead = '/restaurants/join/:salesforceId'
export const restoStandaloneOnboardingBase =
  '/restaurants/standalone/onboarding'

export const UserAccountPaymentsRoute = new Route('/payments(/:method)')

export const PartnersHomeRoute = new Route('/partners')

export const ConfirmExternalRequestRoute = new Route(
  '/confirm-external-request'
)
export const ReferralRoute = new Route('/referral')
export const ReferralSummaryRoute = new Route('/referral/summary')
export const ReferralSummaryCompleteRoute = new Route('/referral/complete')
export const ReferralGuestRoute = new Route('/referral/guest')
export const ReferralGuestCompleteRoute = new Route('/referral/guest/complete')
export const ReferralFeedBackPositiveRoute = new Route(
  '/referral/feedback/positive'
)
export const ReferralFeedBackNegativeRoute = new Route(
  '/referral/feedback/negative'
)
export const ReferralNoticeRoute = new Route('/referral/notice')
