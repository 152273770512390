export function getEnv() {
  const environment_is_node =
    typeof process === 'object' && typeof require === 'function'
  const environment_is_web = typeof window === 'object'
  const environment_is_worker = typeof importScripts === 'function'

  if (environment_is_worker) {
    return self
  } else if (environment_is_web) {
    return window
  } else if (environment_is_node) {
    return global
  } else {
    throw new Error('Unsupported environment for patchFetch')
  }
}
export const isRealProd = process.env.REACT_APP_IS_REAL_PROD === 'true'
