import React from 'react'
import styled from 'styled-components'
import { COLORS, media } from '@themenu/ui'

import cloudinary from '@themenu/shared/lib/services/cloudinary'
import { ReferralFromWrap } from '../ReferralForm/ReferralFormWrap'
import { EmojiInText } from '../common/components/EmojiInText'

const CHECK_CIRCLE = cloudinary.url('web-assets/check-circle.svg', {
  secure: true,
})
const StyledWrap = styled.div`
  ${media.SM`
  padding-left: 5%;
  padding-right: 5%;
  `}
  padding-bottom: 160px;
`
const StyledTitle = styled.div`
  font-weight: bold;
  font-size: 34px;
  line-height: 44px;
  padding-bottom: 40px;
  ${media.SM`
  font-size: 54px;
  line-height: 64px;
  `}
`
const StyledText = styled.div`
  color: ${COLORS.BLUE_SOFT};
  font-size: 14px;
  line-height: 18px;
  ${media.SM`
  font-size: 20px;
  line-height: 24px;
  `}
`
const StyledList = styled.ul`
  list-style-type: circle;
  list-style-image: url(${CHECK_CIRCLE});
  margin-bottom: 30px;
  li {
    color: ${COLORS.BLUE_SOFT};
    font-size: 14px;
    line-height: 18px;
    ${media.SM`
      font-size: 20px;
      line-height: 24px;
    `}
    margin-left: 25px;
    margin-bottom: 5px;
  }
  li span {
    position: relative;
    top: -2px;
    left: 10px;
  }
`

export function ReferralLegalNotice() {
  return (
    <ReferralFromWrap>
      <StyledWrap>
        <StyledTitle>Conditions de l’opération</StyledTitle>
        <StyledText>
          Notre super opération s’étend sur 30 jours, pas un de plus, à partir
          de la date d’activation de votre carte. Mais ne perdez pas de temps :
          l’opération ne dépassera pas la date ultime du 15 novembre, quel que
          soit votre statut d’activation. Après cette date, l’opération spéciale
          découverte de Lunchr, c’est comme Capri, c’est fini.
          <br />
          Nous acceptons les nouvelles têtes exclusivement. Si vous avez déjà
          une carte Swile en votre possession, vous faites déjà parmi des
          chanceux !
          <br />
          Plus on est de collègues, plus on mange ! Pour que votre participation
          soit validée, vous devez inviter à minima une personne, dans la limite
          de 4 personnes invitées (soit entre 2 et 5 participants au total, vous
          inclus).
          <br />
          Vous n’êtes pas obligé d’inviter votre voisin de bureau mais vous
          devez tous appartenir à la même entreprise. Aussi nous nous
          permettrons d’exclure les e-mails douteux (Gmail, Yahoo etc) et qui ne
          correspondent pas (@superboite.com et @supersuperboite.com par
          exemple)
          <br />
          Nous avons besoin de votre adresse e-mail pour vous partager toutes
          les informations concernant votre participation. Si l’expérience Swile
          vous a plu, vous serez recontacté par un membre de l’équipe
          commerciale. Mais promis on ne vous spamme pas !
          <br />
          Vous avez du courrier <EmojiInText emoji="love-letter" />! Votre carte
          Swile arrive directement à votre domicile mais avant cela il y a
          quelques étapes à suivre. Vous allez remplir un formulaire en ligne
          pour nous confier vos coordonnées. Vous allez recevoir un e-mail qui
          confirmera votre participation à l’opération. Vos collègues vont
          recevoir un lien qui les redirigera vers un formulaire pour renseigner
          leur adresse postale. Cette dernière est importante car elle sert
          d’adresse de livraison, nécessaire pour valider lopération et envoyer
          les cartes pré-chargées.
          <br />
          C’est dans la boîte ? Presque... Nous commanderons et enverrons les
          cartes à tous les participants en même temps, lorsque nous aurons les
          adresses de TOUS les participants.
          <br />
          Vous avez oublié de renseigner votre adresse postale ? Pas de soucis,
          nous nous autorisons à vous envoyer un e-mail de rappel.
          <br />
          Vivez l’expérience Swile de A à Z ! Vous serez de vrais utilisateurs
          de la carte dans son environnement réel. De l’activation de votre
          compte jusqu’au paiement dans les établissements en passant par
          l’activation de votre carte avec votre propre code PIN (que vous
          trouverez en ligne), faites comme si vous y étiez vraiment !
          <br />
          Lunchr, vous emmène vers l’infini et au-delà, dans la limite tout de
          même de 1000 cartes émises pour toute la durée de l’opération.
          <br />
          Tic-tac, vous avez un mois pour dépenser votre crédit ! 30 jours. 720
          heures. 43 200 minutes. On vous laisse calculer les secondes.
          <br />
          Attention, nous avons des yeux partout <EmojiInText emoji="eyes" />!
          Pour que l’opération soit un succès, nous nous réservons le droit
          d’exclure les personnes qui ne respecteraient pas les points
          précédents.
          <br />
          <br />
          <br />
          <strong>
            Pourquoi nos utilisateurs <EmojiInText emoji="red-heart" /> Swile :
          </strong>
          <br />
          <br />
        </StyledText>
        <StyledList>
          <li>
            <span>Acceptée partout où l’on peut manger</span>
          </li>
          <li>
            <span>Paiement au-delà de 19€/jour</span>
          </li>
          <li>
            <span>Application intuitive</span>
          </li>
          <li>
            <span>Service client ultra-réactif</span>
          </li>
        </StyledList>
        <StyledText>
          Gardez votre CB au chaud dans son portefeuille : l’addition, c’est
          pour Lunchr.
        </StyledText>
        <br />
        <StyledText>
          <strong>
            C’est ça l’esprit d’équipe
            <EmojiInText emoji="female-sign" />
          </strong>
        </StyledText>
      </StyledWrap>
    </ReferralFromWrap>
  )
}
