// External modules

import 'core-js'
import 'regenerator-runtime/runtime'
import 'airbnb-js-shims/target/es2015'
import 'airbnb-browser-shims/browser-only'
import 'whatwg-fetch'
import 'picturefill'
import 'moment/locale/fr'
import 'moment-duration-format'

import React from 'react'
import { render, hydrate } from 'react-dom'
import { Provider } from 'react-redux'
import { curryN } from 'ramda'
import moment from 'moment'
import { extendMoment } from 'moment-range'
import whyDidYouRender from '@welldone-software/why-did-you-render'

import 'flexboxgrid/dist/flexboxgrid.css'

// Internal modules

import './intl'
import './helpers/moment-public-holiday'
import './yup'

import configureStore from './store'
import App from './App'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'
import MainAppContainer from './containers/MainAppContainer'

import '@themenu/shared/styles/ext/ReactCrop.css'
import '@themenu/shared/styles/application.css'
import 'react-simple-dropdown/styles/Dropdown.css'
import 'react-placeholder/lib/reactPlaceholder.css'

const renderOrHydrate = (ChildrenComponent, mountingElement) => {
  /* The sso session component is never acceced by navigation but only by iframe (from the backend)
   * Because this component is called 2 times on each page, we need to make sur it is has minimal has possible
   * To archive that we do not wrap it into unecessary component/mainapp logic
   */
  const AppWrapper = () => (
    <Provider store={store}>
      <MainAppContainer>
        <ChildrenComponent />
      </MainAppContainer>
    </Provider>
  )

  if (mountingElement.hasChildNodes()) {
    return hydrate(<AppWrapper />, mountingElement)
  }

  return render(<AppWrapper />, mountingElement)
}

const handleHotReload = curryN(2, rootElement => {
  const NextApp = require('./App').default

  return renderOrHydrate(NextApp, rootElement)
})

const isDevelopment = process.env.NODE_ENV === 'development'
const store = configureStore()
const rootElement = document.getElementById('root')

extendMoment(moment)
moment.updateLocale('fr', {
  weekdays: 'Dimanche_Lundi_Mardi_Mercredi_Jeudi_Vendredi_Samedi'.split('_'),
})

// This need to be called before any react is render to avoid weird react error
if (isDevelopment) {
  whyDidYouRender(React)
}

renderOrHydrate(App, rootElement)
unregisterServiceWorker()

if (module.hot && isDevelopment) {
  module.hot.accept('./App', handleHotReload(rootElement))
}
