import React from 'react'
import styled from 'styled-components'

import { COLORS, media } from '@themenu/ui'

const StyledFormField = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const ErrorMessage = styled.span`
  color: ${COLORS.PINK};
  font-size: 13px;
  line-height: 16px;
  padding-top: 2px;

  ${media.SM`
    position: absolute;
    top: 100%;
    left: 0;
  `}
`

export function FormField({ children, error, showError }) {
  return (
    <StyledFormField>
      {children}
      {showError && <ErrorMessage>{error}</ErrorMessage>}
    </StyledFormField>
  )
}
