import * as Yup from 'yup'

const EMAIL_DOMAIN_PATTERN = /@([a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)+)$/
const EXCLUDED_DOMAINS = [
  'gmail.com',
  'googlemail.com',
  'hotmail.com',
  'hotmail.fr',
  'wanadoo.fr',
  'orange.fr',
  'yahoo.fr',
  'yahoo.com',
  'yopmail.fr',
  'outlook.com',
  'outlook.fr',
  'free.fr',
  'sfr.fr',
  'laposte.net',
  'icloud.com',
  'aol.com',
  'aim.com',
  'bbox.fr',
  'numericable.fr',
  'ymail.com',
  'live.fr',
  'live.com',
]

function testEmailDomain(schema) {
  return schema.test(
    'email-domain',
    "Cet e-mail n'est pas valide, avez-vous renseigné une adresse professionnelle ?",
    value => {
      if (!value) return true
      const match = value.match(EMAIL_DOMAIN_PATTERN)
      if (!match || !match[1]) return true
      return !EXCLUDED_DOMAINS.includes(match[1].toLowerCase())
    }
  )
}

function testEmailDuplicate(contact, schema) {
  return contact.email
    ? schema.test(
        'email-duplicate',
        { email: 'Cet email est déjà utilisé' },
        value => contact.email !== value.email
      )
    : schema
}

function testSameEmailDomain(leader, schema) {
  return leader.email
    ? schema.test(
        'same-email-domain',
        {
          email:
            'Les e-mails ne correspondent pas, votre collègue ne travaille pas dans la même entreprise que vous ?',
        },
        value => {
          if (!value.email) return true
          // if we see a performance issue in the form, maybe just split on the @ since at this point the email should be valid
          const matchLeader = leader.email.match(EMAIL_DOMAIN_PATTERN)
          const matchValue = value.email.match(EMAIL_DOMAIN_PATTERN)
          if (!matchLeader || !matchValue || !matchLeader[1] || !matchValue[1])
            return true
          return matchLeader[1] === matchValue[1]
        }
      )
    : schema
}

function getRequiredMaxStringSchema(reqMsg, max, maxMsg) {
  return Yup.string()
    .required(reqMsg)
    .max(max, maxMsg)
}

const emailSchema = testEmailDomain(
  Yup.string()
    .required()
    .email('Veuillez renseigner un email valide')
)

const commonSchema = {
  firstname: getRequiredMaxStringSchema(
    'Veuillez renseigner un prénom',
    30,
    'Veuillez renseigner un prénom à 30 charactères maximum'
  ),
  lastname: getRequiredMaxStringSchema(
    'Veuillez renseigner un nom',
    30,
    'Veuillez renseigner un nom à 30 charactères maximum'
  ),
  email: emailSchema,
  gender: Yup.string()
    .required('Veuillez selectionner une civilité')
    .matches(/M|Mme|Mlle/, 'Civilité invalide'),
  team: Yup.string().required('Veuillez selectionner une équipe'),
  address: getRequiredMaxStringSchema(
    'Veuillez renseigner une adresse',
    50,
    'Veuillez renseigner 50 charactères maximum'
  ),
  address_2: Yup.string().max(50, 'Veuillez renseigner 50 charactères maximum'),
  zip: Yup.string()
    .required('Veuillez renseigner un code postal')
    .matches(/^[0-9]{5}$/, 'Veuillez renseigner un code postal valide'),
  city: getRequiredMaxStringSchema(
    'Veuillez renseigner une ville',
    30,
    'Veuillez renseigner une ville à 30 charactères maximum'
  ),
}

export const leaderSchema = Yup.object().shape({
  leader: Yup.object().shape({
    ...commonSchema,
    company_name: getRequiredMaxStringSchema(
      'Veuillez renseigner une entreprise',
      30,
      'Veuillez renseigner une entreprise à 30 charactères maximum'
    ),
    job_title: getRequiredMaxStringSchema(
      'Veuillez renseigner un poste',
      30,
      'Veuillez renseigner un poste à 30 charactères maximum'
    ),
  }),
})

export const guestSchema = Yup.object().shape(commonSchema)

export const colleagueSchema = Yup.object().shape(
  {
    firstname: Yup.string()
      .max(30, 'Veuillez renseigner un prénom à 30 charactères maximum')
      .when('email', (email, schema) =>
        email ? schema.required('Veuillez renseigner un prénom') : schema
      ),
    email: testEmailDomain(
      Yup.string()
        .email('Veuillez renseigner un email valide')
        .when('firstname', (firstname, schema) =>
          firstname ? schema.required('Veuillez renseigner un email') : schema
        )
    ),
  },
  ['firstname', 'email']
)

export const colleaguesSchema = Yup.object().shape({
  contact_1: colleagueSchema
    .when('leader', testEmailDuplicate)
    .when('leader', testSameEmailDomain),
  contact_2: colleagueSchema
    .when('leader', testEmailDuplicate)
    .when('leader', testSameEmailDomain)
    .when('contact_1', testEmailDuplicate),
  contact_3: colleagueSchema
    .when('leader', testEmailDuplicate)
    .when('leader', testSameEmailDomain)
    .when('contact_1', testEmailDuplicate)
    .when('contact_2', testEmailDuplicate),
  contact_4: colleagueSchema
    .when('leader', testEmailDuplicate)
    .when('leader', testSameEmailDomain)
    .when('contact_1', testEmailDuplicate)
    .when('contact_2', testEmailDuplicate)
    .when('contact_3', testEmailDuplicate),
})

export const stateSchema = Yup.object().shape({
  ...leaderSchema,
  ...colleaguesSchema,
})

const commonFeedbackSchema = {
  user: Yup.object().shape({ email: emailSchema }),
  comment: getRequiredMaxStringSchema(
    'Veuillez renseigner un commentaire',
    140,
    'Veuillez renseigner un commentaire à 140 charactères maximum'
  ),
}

export const feedbackPositiveSchema = Yup.object().shape({
  ...commonFeedbackSchema,
  drh: Yup.object()
    .shape(
      {
        firstname: Yup.string()
          .max(30, 'Veuillez renseigner un prénom à 30 charactères maximum')
          .when('lastname', (lastname, schema) =>
            lastname ? schema.required('Veuillez renseigner un prénom') : schema
          )
          .when('email', (email, schema) =>
            email ? schema.required('Veuillez renseigner un prénom') : schema
          ),
        lastname: Yup.string()
          .max(30, 'Veuillez renseigner un nom à 30 charactères maximum')
          .when('firstname', (firstname, schema) =>
            firstname ? schema.required('Veuillez renseigner un nom') : schema
          )
          .when('email', (email, schema) =>
            email ? schema.required('Veuillez renseigner un nom') : schema
          ),
        email: Yup.string()
          .email('Veuillez renseigner un email valide')
          .when('firstname', (firstname, schema) =>
            firstname ? schema.required('Veuillez renseigner un email') : schema
          )
          .when('lastname', (lastname, schema) =>
            lastname ? schema.required('Veuillez renseigner un email') : schema
          ),
      },
      [
        ['lastname', 'email'],
        ['firstname', 'email'],
        ['firstname', 'lastname'],
      ]
    )
    .when('user', testEmailDuplicate)
    .when('user', testSameEmailDomain),
})

export const feedbackNegativeSchema = Yup.object().shape(commonFeedbackSchema)
