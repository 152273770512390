import React from 'react'
import { Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { isConnectedWithEmailSelector } from '../modules/auth-selectors'
import { compose } from 'recompose'
import { omit } from 'ramda'

/**
 * Redirects the user to /signin if user is not logged in
 * @param  {Object}    options.component
 * @return {Object}
 */
const PrivateWithEmailRoute = ({ isConnectedWithEmail, ...props }) => {
  const render = ({ location }) =>
    isConnectedWithEmail ? (
      <Route {...props} />
    ) : (
      <Redirect
        to={{
          pathname: '/profile',
          state: {
            next: location,
          },
        }}
      />
    )

  return <Route {...omit(['component'], props)} render={render} />
}

const enhance = compose(
  withRouter,
  connect(state => ({
    isConnectedWithEmail: isConnectedWithEmailSelector(state),
  }))
)

export default enhance(PrivateWithEmailRoute)
