import { createSelector } from 'reselect'

export const creditCardsInputSelector = state => state.get('dbjs').creditCards

export const transformCreditCardFromDB = creditCard => {
  return {
    brand: creditCard.brand,
    id: creditCard.token,
    expMonth: creditCard.exp_month,
    expYear: creditCard.exp_year,
    last4: creditCard.last4,
    name: creditCard.name,
    token: creditCard.token,
    errorCodes: creditCard.error_codes || [],
  }
}

export const creditCardsSelector = createSelector(
  creditCardsInputSelector,
  creditCards => {
    return Object.keys(creditCards).reduce((acc, id) => {
      acc.push(transformCreditCardFromDB(creditCards[id]))
      return acc
    }, [])
  }
)

export const defaultCreditCardSelector = createSelector(
  creditCardsSelector,
  creditCards => {
    return creditCards[0] || null
  }
)
