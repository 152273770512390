import { fetchPaymentsHistory } from '../db'
import { FETCH_MEAL_VOUCHER_TRANSACTIONS_SUCCESS } from '../db/types'
import { oldestTransactionDateSelector } from './history-mealvouchers-selectors'
import { union } from 'ramda'

const UI_HISTORY_MV_INIT = 'ui/historymv/INIT'
const UI_HISTORY_MV_LOADMORE = 'ui/historymv/LOADMORE'

const COUNT_PER_PAGE = 20

export const init = () => dispatch => {
  dispatch({ type: UI_HISTORY_MV_INIT })
  return dispatch(fetchPaymentsHistory({ per: COUNT_PER_PAGE }))
}

export const loadMore = () => (dispatch, getState) => {
  dispatch({ type: UI_HISTORY_MV_LOADMORE })

  return dispatch(
    fetchPaymentsHistory({
      before: oldestTransactionDateSelector(getState()),
      per: COUNT_PER_PAGE,
    })
  )
}

const defaultState = {
  fetching: true,
  list: [],
  hasMore: true,
}

export default (state = defaultState, action) => {
  switch (action.type) {
    case UI_HISTORY_MV_INIT:
      return defaultState

    case UI_HISTORY_MV_LOADMORE:
      return { ...state, fetching: true }

    case FETCH_MEAL_VOUCHER_TRANSACTIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        list: union(
          state.list,
          action.list.map(({ id }) => id)
        ),
        hasMore: action.list.length === COUNT_PER_PAGE,
      }

    default:
      return state
  }
}
