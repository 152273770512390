import * as Api from '../../services/api'
import * as toast from '../ui/toast-message'
import { path } from 'ramda'

import { LOGOUT_SUCCEEDED } from '../auth/types'
import { mergeEntities } from './utils'
import { fetchProfile } from '../auth'
import { creditCardsSelector } from './creditCards-selectors'
import { creditCardNeedsAuthentication } from './creditCards-utils'

export const FETCH_CREDIT_CARDS_SUCCEEDED =
  'app/Payments/FETCH_CREDIT_CARDS_SUCCEEDED'
export const RENAME_CREDIT_CARD = 'app/Payments/RENAME_CREDIT_CARD'
export const RENAME_CREDIT_CARD_SUCCEEDED =
  'app/Payments/RENAME_CREDIT_CARD_SUCCEEDED'
export const VALIDATE_AUTH_CREDIT_CARD_SUCCEEDED =
  'app/Payments/VALIDATE_AUTH_CREDIT_CARD_SUCCEEDED'
export const DELETE_CREDIT_CARD = 'app/Payments/DELETE_CREDIT_CARD'
export const DELETE_CREDIT_CARD_SUCCEEDED =
  'app/Payments/DELETE_CREDIT_CARD_SUCCEEDED'
export const CREATE_CREDIT_CARD_SUCCEEDED =
  'app/Payments/CREATE_CREDIT_CARD_SUCCEEDED'

const fetchCreditCardsSucceeded = creditCards => ({
  type: FETCH_CREDIT_CARDS_SUCCEEDED,
  creditCards,
})

/**
 * Action creator when a credit card has successfully been renamed
 * @param  {Object} creditCards
 * @return {Object}
 */
const renameCreditCardSucceeded = (id, name) => ({
  type: RENAME_CREDIT_CARD_SUCCEEDED,
  id,
  name,
})

const validateAuthCreditCardSucceeded = id => ({
  type: VALIDATE_AUTH_CREDIT_CARD_SUCCEEDED,
  id,
})

/**
 * Action creator when a credit card is removed
 * @param  {String} id
 * @return {Object}
 */
const deleteCreditCardSucceeded = id => ({
  type: DELETE_CREDIT_CARD_SUCCEEDED,
  id,
})

/**
 * Action creator when a credit card is removed
 * @param  {String} id
 * @return {Object}
 */
const createCreditCardSucceeded = creditCard => ({
  type: CREATE_CREDIT_CARD_SUCCEEDED,
  creditCard,
})

/**
 * Async creator for fetching a credit card
 * @return {Promise}
 */
export const fetchCreditCards = () => dispatch =>
  Api.fetchCreditCards().then(data => {
    dispatch(fetchCreditCardsSucceeded(data.cards))
    dispatch(fetchProfile())

    return data
  })

/**
 * Async creator for renaming a credit card
 * @param {String} id
 * @param {String} name
 * @return {Promise}
 */
export const renameCreditCard = (id, name) => dispatch => {
  dispatch({ type: RENAME_CREDIT_CARD, id, name })

  return Api.updateCreditCard(id, { name }).then(data => {
    dispatch(renameCreditCardSucceeded(id, name))

    return data
  })
}

/**
 * Async creator for deleting a credit card
 * @param  {String} id
 * @return {Promise}
 */
export const deleteCreditCard = id => dispatch => {
  dispatch({ type: DELETE_CREDIT_CARD, id })

  return Api.deleteCreditCard(id).then(data => {
    dispatch(deleteCreditCardSucceeded(id))

    return data
  })
}

export const createCreditCardWithSetup = (
  stripe,
  userName
) => async dispatch => {
  try {
    const { client_secret } = await Api.createSetupIntent()
    const { setupIntent, error } = await stripe.handleCardSetup(client_secret, {
      payment_method_data: { billing_details: { name: userName } },
    })
    if (error) {
      return { error: error.message }
    } else {
      try {
        return dispatch(createCreditCard(setupIntent.payment_method))
      } catch (error) {
        console.error(error)
        return {
          error: path(['result', 'error', 'message'], error)
            ? error.result.error.message
            : "Une erreur inconnue s'est produite",
        }
      }
    }
  } catch (error) {
    console.error(error)
    return {
      error: path(['result', 'error', 'message'], error)
        ? error.result.error.message
        : "Une erreur inconnue s'est produite",
    }
  }
}

export const checkNeedAuthCreditCard = (stripe, token, showToast) => async (
  dispatch,
  getState
) => {
  try {
    const cards = creditCardsSelector(getState())
    const card = cards.filter(c => c.id === token)[0]
    if (creditCardNeedsAuthentication(card)) {
      await dispatch(validateAuthCreditCard(stripe, token, showToast))
    }
  } catch (error) {
    console.error(error)
    throw error
  }
}

export const validateAuthCreditCard = (
  stripe,
  token,
  showToast = true
) => async dispatch => {
  try {
    const { client_secret } = await Api.createSetupIntent()
    const { setupIntent, error } = await stripe.handleCardSetup(client_secret, {
      payment_method: token,
    })

    if (error || setupIntent.status !== 'succeeded') {
      if (showToast) {
        const message = path(['message'], error)
          ? error.message
          : "Une erreur sur l'authentification sécurisé de votre carte s'est produite"
        dispatch(toast.show(message))
      }
      throw error
    } else {
      await Api.validateAuthCreditCard(token)
      return dispatch(validateAuthCreditCardSucceeded(token))
    }
  } catch (error) {
    console.error(error)
    if (showToast) {
      const message = path(['result', 'error', 'message'], error)
        ? error.result.error.message
        : "Une erreur sur l'authentification sécurisé de votre carte s'est produite"
      dispatch(toast.show(message))
    }

    throw error
  }
}

/**
 * Async creator for creating a credit card
 * @param  {String} options.token [description]
 * @return {Promise}
 */
export const createCreditCard = paymentMethod => dispatch => {
  return Api.createCreditCard(paymentMethod).then(data => {
    return dispatch(createCreditCardSucceeded(data.card))
  })
}

const initialState = {}

export default function paymentsReducer(state = initialState, action = {}) {
  switch (action.type) {
    case CREATE_CREDIT_CARD_SUCCEEDED:
      return mergeEntities(state, [action.creditCard], 'token')

    case FETCH_CREDIT_CARDS_SUCCEEDED:
      return mergeEntities(state, action.creditCards, 'token')

    case RENAME_CREDIT_CARD:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          name: action.name,
        },
      }

    case VALIDATE_AUTH_CREDIT_CARD_SUCCEEDED:
      return {
        ...state,
        [action.id]: {
          ...state[action.id],
          error_codes: [],
        },
      }

    case DELETE_CREDIT_CARD:
      const newState = {
        ...state,
      }
      delete newState[action.id]
      return newState

    case LOGOUT_SUCCEEDED:
      return initialState

    default:
      return state
  }
}
