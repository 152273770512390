import React from 'react'
import ReferralHeader from './ReferralHeader/ReferralHeader'
import ReferralFooter from './ReferralFooter/ReferralFooter'
import { media } from '@themenu/ui'
import styled from 'styled-components'

const BodyWrap = styled.div`
  background: white;
  margin-left: 6%;
  margin-right: 6%;
  ${media.SM`
  margin-left: 13%;
  margin-right: 13%;
`}
`
function ReferralWrap({ children, notice }) {
  return (
    <div>
      <BodyWrap>
        <ReferralHeader />
        {children}
        <ReferralFooter notice={notice} />
      </BodyWrap>
    </div>
  )
}

export default ReferralWrap
