import { mergeEntities } from './utils'

const reducer = (state = {}, action) => {
  if (action.dbRes && action.dbRes.lunchr_cards) {
    return mergeEntities(state, action.dbRes.lunchr_cards, 'uuid')
  }

  return state
}

export default reducer
