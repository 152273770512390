import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { detect } from 'detect-browser'
import { first } from 'lodash'

import {
  ButtonLink as Button,
  ButtonVariants,
  Toaster,
  ToasterVariants,
} from '@themenu/ui'

const isOutdatedBrowser = ({ name, version }) => {
  const numericVersion = parseInt(first(version.split('.')))

  switch (name) {
    case 'ie':
      return numericVersion < 11
    default:
      return false
  }
}

const OutdatedBrowser = () => {
  const [showOutdatedBrowser, setShowOutdatedBanner] = useState(false)

  useEffect(() => {
    const browser = detect()
    browser && isOutdatedBrowser(browser) && setShowOutdatedBanner(true)
  }, [])

  return (
    showOutdatedBrowser && (
      <Toaster
        emoji="confused-face"
        variant={ToasterVariants.DARK_BLUE}
        buttons={
          <Button
            variant={ButtonVariants.COOL_GRAY}
            nativeLink
            href="http://outdatedbrowser.com/fr"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FormattedMessage
              id="Toasters.Browser.Outdated.more"
              defaultMessage="En savoir plus"
            />
          </Button>
        }
      >
        <FormattedMessage
          id="Toasters.Browser.Outdated.text"
          defaultMessage="La mise à jour de votre navigateur est requise pour pouvoir continuer sur notre site internet."
        />
      </Toaster>
    )
  )
}

export default OutdatedBrowser
