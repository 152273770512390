import * as ApiBanking from '../../services/api.banking'
import * as ApiCompany from '../../services/api.company'
import * as toast from '../ui/toast-message'
import { uiLunchrCardsSelector } from './my-lunchr-cards-selector'
import { FETCH_CREDIT_CARDS_SUCCEEDED } from '../dbjs/creditCards'
import { errorEmitter } from '../../services/api'
import SmartPinJS from '../../vendors/SmartPinV2.min'
import { IDEMIA_ENDPOINT } from '../../constants/idemia.js'
import { activateAnonymousLunchrCard } from '../../services/api.user'
import { shippingPointCodeIsHome } from '@themenu/shared/lib/constants/corpo'

const FETCH_SUCCESS = 'uicorpo/my-lunchr-cards/FETCH_SUCCESS'
const CARD_LOCK_CHANGE = 'uicorpo/my-lunchr-cards/CARD_LOCK_CHANGE'
const CARD_NFC_CHANGE = 'uicorpo/my-lunchr-cards/CARD_NFC_CHANGE'
const CARD_TOPUP_CHANGE = 'uicorpo/my-lunchr-cards/CARD_TOPUP_CHANGE'
const CARD_TOKEN_CHANGE = 'uicorpo/my-lunchr-cards/CARD_TOKEN_CHANGE'
const ANONYMOUS_CARD_TOKEN_CHANGE =
  'uicorpo/my-lunchr-cards/ANONYMOUS_CARD_TOKEN_CHANGE'
const CARD_TOKEN_SUBMIT = 'uicorpo/my-lunchr-cards/CARD_TOKEN_SUBMIT'
const ANONYMOUS_CARD_TOKEN_SUBMIT =
  'uicorpo/my-lunchr-cards/ANONYMOUS_CARD_TOKEN_SUBMIT'
const CARD_TOKEN_SUBMIT_ERROR =
  'uicorpo/my-lunchr-cards/CARD_TOKEN_SUBMIT_ERROR'
const CARD_TOKEN_SUBMIT_SUCCESS =
  'uicorpo/my-lunchr-cards/CARD_TOKEN_SUBMIT_SUCCESS'
const ANONYMOUS_CARD_TOKEN_SUBMIT_ERROR =
  'uicorpo/my-lunchr-cards/ANONYMOUS_CARD_TOKEN_SUBMIT_ERROR'
const ANONYMOUS_CARD_TOKEN_SUBMIT_SUCCESS =
  'uicorpo/my-lunchr-cards/ANONYMOUS_CARD_TOKEN_SUBMIT_SUCCESS'
const REVEAL_PIN_SUBMIT = 'uicorpo/my-lunchr-cards/REVEAL_PIN_SUBMIT'
const REVEAL_PIN_SUBMIT_ERROR =
  'uicorpo/my-lunchr-cards/REVEAL_PIN_SUBMIT_ERROR'
const REVEAL_PIN_SUBMIT_SUCCESS =
  'uicorpo/my-lunchr-cards/REVEAL_PIN_SUBMIT_SUCCESS'

const CARD_BLOCK_SUCCEEDED = 'uicorpo/my-lunchr-cards/BLOCK_SUCCEEDED'
const CARD_UNBLOCK_SUCCEEDED = 'uicorpo/my-lunchr-cards/UNBLOCK_SUCCEEDED'
const CARD_ENABLE_NFC_SUCCEEDED = 'uicorpo/my-lunchr-cards/ENABLE_NFC_SUCCEEDED'
const CARD_DISABLE_NFC_SUCCEEDED =
  'uicorpo/my-lunchr-cards/DISABLE_NFC_SUCCEEDED'
const CARD_ENABLE_TOPUP_SUCCEEDED =
  'uicorpo/my-lunchr-cards/ENABLE_TOPUP_SUCCEEDED'
const CARD_DISABLE_TOPUP_SUCCEEDED =
  'uicorpo/my-lunchr-cards/DISABLE_TOPUP_SUCCEEDED'
const CARD_BLOCK_ERRORED = 'uicorpo/my-lunchr-cards/BLOCK_ERRORED'
const CARD_UNBLOCK_ERRORED = 'uicorpo/my-lunchr-cards/UNBLOCK_ERRORED'
const CARD_ENABLE_NFC_ERRORED = 'uicorpo/my-lunchr-cards/ENABLE_NFC_ERRORED'
const CARD_DISABLE_NFC_ERRORED = 'uicorpo/my-lunchr-cards/DISABLE_NFC_ERRORED'
const CARD_ENABLE_TOPUP_ERRORED = 'uicorpo/my-lunchr-cards/ENABLE_TOPUP_ERRORED'
const CARD_DISABLE_TOPUP_ERRORED =
  'uicorpo/my-lunchr-cards/DISABLE_TOPUP_ERRORED'
const PIN_MODAL_SHOW = 'uicorpo/my-lunchr-cards/PIN_MODAL_SHOW'
const PIN_MODAL_HIDE = 'uicorpo/my-lunchr-cards/PIN_MODAL_HIDE'
const LOST_MODAL_SHOW = 'uicorpo/my-lunchr-cards/LOST_MODAL_SHOW'
const LOST_MODAL_HIDE = 'uicorpo/my-lunchr-cards/LOST_MODAL_HIDE'
const UNLOCK_PIN_WARNING_MODAL_SHOW =
  'uicorpo/my-lunchr-cards/UNLOCK_PIN_WARNING_MODAL_SHOW'
const UNLOCK_PIN_WARNING_MODAL_HIDE =
  'uicorpo/my-lunchr-cards/UNLOCK_PIN_WARNING_MODAL_HIDE'
const LOST_SUBMIT = 'uicorpo/my-lunchr-cards/LOST_SUBMIT'
const LOST_SUBMIT_ERROR = 'uicorpo/my-lunchr-cards/LOST_SUBMIT_ERROR'
const LOST_SUBMIT_SUCCESS = 'uicorpo/my-lunchr-cards/LOST_SUBMIT_SUCCESS'

const UNLOCK_CARD_PIN_SUBMIT = 'uicorpo/my-lunchr-cards/UNLOCK_CARD_PIN_SUBMIT'
const UNLOCK_CARD_PIN_SUBMIT_SUCCESS =
  'uicorpo/my-lunchr-cards/UNLOCK_CARD_PIN_SUBMIT_SUCCESS'
const UNLOCK_CARD_PIN_SUBMIT_ERROR =
  'uicorpo/my-lunchr-cards/UNLOCK_CARD_PIN_SUBMIT_ERROR'

export const fetchMyLunchrCards = () => async dispatch => {
  const { lunchr_cards } = await ApiBanking.fetchLunchrCards()

  dispatch({
    type: FETCH_SUCCESS,
    dbRes: { lunchr_cards },
  })
}

export function tokenChange(uuid, value) {
  return { type: CARD_TOKEN_CHANGE, uuid, value }
}

export function anonymousCardTokenChange(value) {
  return { type: ANONYMOUS_CARD_TOKEN_CHANGE, value }
}

export function lockChange(uuid, value) {
  return { type: CARD_LOCK_CHANGE, uuid, value }
}

export function nfcChange(uuid, value) {
  return { type: CARD_NFC_CHANGE, uuid, value }
}

export function topupChange(uuid, value) {
  return { type: CARD_TOPUP_CHANGE, uuid, value }
}

export function showPinModal(uuid) {
  return { type: PIN_MODAL_SHOW, uuid }
}

export function hidePinModal(uuid) {
  return { type: PIN_MODAL_HIDE, uuid }
}

export const revealPinLunchrCard = (
  uuid,
  { token, cardVendor }
) => async dispatch => {
  dispatch({ type: REVEAL_PIN_SUBMIT, uuid })

  try {
    switch (cardVendor) {
      case 'MONEXT':
        const {
          lunchr_card: {
            demand_id,
            consumer_id,
            customer_ref,
            control_value,
            timestamp,
            mac,
            control_value_is_encrypted,
          },
        } = await ApiBanking.startRevealPinProcess({
          uuid,
          token,
        })

        SmartPinJS.getPin(
          IDEMIA_ENDPOINT,
          demand_id,
          consumer_id,
          customer_ref,
          control_value,
          timestamp,
          mac,
          control_value_is_encrypted,
          getPinCallback(dispatch, uuid)
        )
        break
      default:
        const { lunchr_card } = await ApiBanking.revealPinLunchrCard({
          uuid,
          token,
        })
        dispatch({
          type: REVEAL_PIN_SUBMIT_SUCCESS,
          uuid,
          pin: lunchr_card.pin,
        })
    }
  } catch (err) {
    console.error(err)
    if (err && err.error) {
      dispatch({
        type: REVEAL_PIN_SUBMIT_ERROR,
        uuid,
        message: err.error.message,
      })
    }
  }
}

function getPinCallback(dispatch, uuid) {
  return (pin, result, message) => {
    if (result) {
      dispatch({
        type: REVEAL_PIN_SUBMIT_SUCCESS,
        uuid,
        pin: pin,
      })
    } else {
      dispatch({
        type: REVEAL_PIN_SUBMIT_ERROR,
        uuid,
        message: message,
      })
    }
  }
}

export function showLostModal(uuid) {
  return { type: LOST_MODAL_SHOW, uuid }
}

export const hideLostModal = uuid => dispatch => {
  dispatch({ type: LOST_MODAL_HIDE, uuid })
  dispatch(fetchMyLunchrCards())
}

export function showUnlockPINWarningModal(uuid) {
  return { type: UNLOCK_PIN_WARNING_MODAL_SHOW, uuid }
}

export const hideUnlockPINWarningModal = uuid => dispatch => {
  dispatch({ type: UNLOCK_PIN_WARNING_MODAL_HIDE, uuid })
  dispatch(fetchMyLunchrCards())
}

export const markCardAsLost = (uuid, values) => async dispatch => {
  dispatch({ type: LOST_SUBMIT, uuid })

  try {
    const data = {
      lost_card_order: {
        swile_card_uuid: uuid,
        shipping_address: shippingPointCodeIsHome(values.shippingPointCode)
          ? {
              street: values.street,
              street2: values.street2,
              zip: values.zipCode,
              city: values.city,
              country: 'FR',
            }
          : null,
      },
    }

    await ApiCompany.reorderLostCard(data)

    dispatch({
      type: LOST_SUBMIT_SUCCESS,
      uuid,
    })
  } catch (err) {
    console.error(err)
    dispatch({
      type: LOST_SUBMIT_ERROR,
      uuid,
      message: err.result.error.message,
    })
  }
}

const api = {
  [CARD_BLOCK_SUCCEEDED]: {
    fun: ApiBanking.blockLunchrCard,
    error: CARD_BLOCK_ERRORED,
    successMessage: 'cardLocked',
  },
  [CARD_UNBLOCK_SUCCEEDED]: {
    fun: ApiBanking.unblockLunchrCard,
    error: CARD_UNBLOCK_ERRORED,
    successMessage: 'cardUnlocked',
  },
  [CARD_ENABLE_NFC_SUCCEEDED]: {
    fun: ApiBanking.enableNfcLunchrCard,
    error: CARD_ENABLE_NFC_ERRORED,
    successMessage: 'cardNfcEnabled',
  },
  [CARD_DISABLE_NFC_SUCCEEDED]: {
    fun: ApiBanking.disableNfcLunchrCard,
    error: CARD_DISABLE_NFC_ERRORED,
    successMessage: 'cardNfcDisabled',
  },
  [CARD_ENABLE_TOPUP_SUCCEEDED]: {
    fun: ApiBanking.enableTopupLunchrCard,
    error: CARD_ENABLE_TOPUP_ERRORED,
    successMessage: 'cardTopupEnabled',
  },
  [CARD_DISABLE_TOPUP_SUCCEEDED]: {
    fun: ApiBanking.disableTopupLunchrCard,
    error: CARD_DISABLE_TOPUP_ERRORED,
    successMessage: 'cardTopupDisabled',
  },
}

export const unlockCardPIN = uuid => async dispatch => {
  dispatch({ type: UNLOCK_CARD_PIN_SUBMIT, uuid })

  try {
    await ApiBanking.unlockCardPIN(uuid)

    dispatch({
      type: UNLOCK_CARD_PIN_SUBMIT_SUCCESS,
      uuid,
    })
  } catch ({ error }) {
    if (error) {
      console.error(error)
      dispatch({
        type: UNLOCK_CARD_PIN_SUBMIT_ERROR,
        uuid,
        message: error.message,
      })
    }
  }
}

export const updatorLunchrCard = typeSuccess => (uuid, ...params) => dispatch =>
  api[typeSuccess]
    .fun(uuid, ...params)
    .then(({ lunchr_card }) => {
      dispatch(toast.showIntl(api[typeSuccess].successMessage))
      dispatch({
        type: typeSuccess,
        uuid,
        dbRes: { lunchr_cards: [lunchr_card] },
      })
    })
    .catch(err => {
      if (err && err.error) {
        dispatch(toast.show(err.error.message))
        dispatch({
          uuid,
          type: api[typeSuccess].error,
        })
      }
    })

export const blockLunchrCard = updatorLunchrCard(CARD_BLOCK_SUCCEEDED)
export const unblockLunchrCard = updatorLunchrCard(CARD_UNBLOCK_SUCCEEDED)
export const enableNfcLunchrCard = updatorLunchrCard(CARD_ENABLE_NFC_SUCCEEDED)
export const disableNfcLunchrCard = updatorLunchrCard(
  CARD_DISABLE_NFC_SUCCEEDED
)
export const enableTopupLunchrCard = updatorLunchrCard(
  CARD_ENABLE_TOPUP_SUCCEEDED
)
export const disableTopupLunchrCard = updatorLunchrCard(
  CARD_DISABLE_TOPUP_SUCCEEDED
)

export const tokenSubmit = uuid => async (dispatch, getState) => {
  const state = getState()
  const { token } = uiLunchrCardsSelector(state).form[uuid]

  dispatch({ type: CARD_TOKEN_SUBMIT, uuid })

  try {
    const { lunchr_card } = await ApiBanking.activateLunchrCard(uuid, token)

    dispatch({
      type: CARD_TOKEN_SUBMIT_SUCCESS,
      uuid,
      dbRes: { lunchr_cards: [lunchr_card] },
    })
  } catch (err) {
    console.error(err)
    if (err && err.error) {
      dispatch({
        type: CARD_TOKEN_SUBMIT_ERROR,
        uuid,
        message: err.error.message,
      })
    }
  }
}

export const submitTokensToActivateCard = (uuid, cards) => async (
  dispatch,
  getState
) => {
  const state = getState()
  const { token } = uiLunchrCardsSelector(state).form[uuid]

  dispatch({ type: CARD_TOKEN_SUBMIT, uuid })

  const cardNumber = cards.length
  let errorNumber = 0

  for (const cardUUID of cards) {
    try {
      const { lunchr_card } = await ApiBanking.activateLunchrCard(
        cardUUID,
        token
      )
      dispatch({
        type: CARD_TOKEN_SUBMIT_SUCCESS,
        uuid: cardUUID,
        dbRes: { lunchr_cards: [lunchr_card] },
      })
    } catch (err) {
      if (err && err.error) {
        ++errorNumber
      }
    }
    if (cardNumber === errorNumber) {
      dispatch({
        type: CARD_TOKEN_SUBMIT_ERROR,
        uuid,
        message: 'Le code que vous avez fourni semble incorrect',
      })
    }
  }
}

export const anonymousCardTokenSubmit = () => async (dispatch, getState) => {
  const state = getState()
  const { token } = state.get('uicorpo').myLunchrCards.anonymousCardForm
  const id = state.getIn(['auth', 'user', 'id'])

  dispatch({ type: ANONYMOUS_CARD_TOKEN_SUBMIT })

  try {
    await activateAnonymousLunchrCard(token, id)
      .then(response => {
        if (response.status >= 400) {
          return response
            .json()
            .then(json => {
              if (response.ok) return json

              errorEmitter.emit('request:error', response.status, json)

              return Promise.reject(json)
            })
            .catch(error => {
              return Promise.reject(error)
            })
        }
      })
      .catch(error => {
        console.error(error)
        throw error
      })

    dispatch({
      type: ANONYMOUS_CARD_TOKEN_SUBMIT_SUCCESS,
    })
    dispatch(fetchMyLunchrCards())
  } catch ({ result }) {
    console.error(result)
    if (result && result.error && result.error.message) {
      dispatch({
        type: ANONYMOUS_CARD_TOKEN_SUBMIT_ERROR,
        message: result.error.message,
      })
    }
  }
}

const initialState = {
  loaded: false,

  // TODO: hack to remove
  creditCardsLoaded: false,

  cards: [],
  form: {}, // <[uuid]: {token, serverError, submitting, lockActive, nfcActive}>
  anonymousCardForm: {
    token: '',
    serverError: null,
    submitting: false,
  },
  pinCode: {}, // <[uuid]: {value, modalActive}>
  lost: {}, // <[uuid]: {value, modalActive}>
  unlockPINWarning: {}, // <[uuid]: {value, modalActive}>
}

export const updateStateSectionWithUuid = (state, section, uuid, data) => {
  return {
    ...state,
    [section]: {
      ...state[section],
      [uuid]: {
        ...state[section][uuid],
        ...data,
      },
    },
  }
}

export const updateStateSectionWithUuidAndSetServerError = (
  state,
  section,
  uuid,
  data,
  error
) => {
  const updatedState = updateStateSectionWithUuid(state, section, uuid, data)

  return updateStateSectionWithUuid(updatedState, 'form', uuid, {
    submitting: false,
    serverError: error,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SUCCESS:
      return {
        ...state,
        loaded: true,
        cards: action.dbRes.lunchr_cards.map(lc => lc.uuid),
        form: action.dbRes.lunchr_cards.reduce((acc, lc) => {
          return {
            ...acc,
            [lc.uuid]: {
              token: '',
              serverError: null,
              submitting: false,
              lockActive: lc.user_blocked_at !== null,
              nfcActive: lc.nfc_enabled,
              topupActive: lc.topup_enabled,
              topupErrorCode: lc.topup_error_code,
              topupCardId: lc.topup_stripe_card_id,
              topupAmountLimit: lc.topup_amount_limit,
            },
          }
        }, {}),
        pinCode: action.dbRes.lunchr_cards.reduce((acc, lc) => {
          return {
            ...acc,
            [lc.uuid]: {
              value: null,
              modalActive: false,
            },
          }
        }, {}),
        lost: action.dbRes.lunchr_cards.reduce((acc, lc) => {
          return {
            ...acc,
            [lc.uuid]: {
              value: false,
              modalActive: false,
            },
          }
        }, {}),
        unlockPINWarning: action.dbRes.lunchr_cards.reduce((acc, lc) => {
          return {
            ...acc,
            [lc.uuid]: {
              value: false,
              modalActive: false,
            },
          }
        }, {}),
      }

    case FETCH_CREDIT_CARDS_SUCCEEDED:
      return {
        ...state,
        creditCardsLoaded: true,
      }

    case CARD_TOKEN_CHANGE:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        token: action.value.substring(0, 9),
        serverError: null,
      })

    case ANONYMOUS_CARD_TOKEN_CHANGE:
      return {
        ...state,
        anonymousCardForm: {
          ...state.anonymousCardForm,
          token: action.value.substring(0, 9),
          serverError: null,
        },
      }

    case REVEAL_PIN_SUBMIT:
    case LOST_SUBMIT:
    case UNLOCK_CARD_PIN_SUBMIT:
    case CARD_TOKEN_SUBMIT:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        submitting: true,
        serverError: null,
      })

    case ANONYMOUS_CARD_TOKEN_SUBMIT:
      return {
        ...state,
        anonymousCardForm: {
          ...state.anonymousCardForm,
          submitting: true,
          serverError: null,
        },
      }

    case CARD_LOCK_CHANGE:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        lockActive: action.value,
      })

    case CARD_NFC_CHANGE:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        nfcActive: action.value,
      })

    case CARD_TOPUP_CHANGE:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        topupActive: action.value,
      })

    case REVEAL_PIN_SUBMIT_ERROR:
    case LOST_SUBMIT_ERROR:
    case UNLOCK_CARD_PIN_SUBMIT_ERROR:
    case CARD_TOKEN_SUBMIT_ERROR:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        submitting: false,
        serverError: action.message,
      })

    case ANONYMOUS_CARD_TOKEN_SUBMIT_ERROR:
      return {
        ...state,
        anonymousCardForm: {
          ...state.anonymousCardForm,
          submitting: false,
          serverError: action.message,
        },
      }

    case CARD_BLOCK_ERRORED:
    case CARD_UNBLOCK_ERRORED:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        lockActive: !state.form[action.uuid].lockActive,
      })

    case CARD_ENABLE_NFC_ERRORED:
    case CARD_DISABLE_NFC_ERRORED:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        nfcActive: !state.form[action.uuid].lockActive,
      })

    case CARD_ENABLE_TOPUP_ERRORED:
    case CARD_DISABLE_TOPUP_ERRORED:
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        topupActive: !state.form[action.uuid].lockActive,
      })

    case CARD_BLOCK_SUCCEEDED:
    case CARD_UNBLOCK_SUCCEEDED:
    case CARD_ENABLE_NFC_SUCCEEDED:
    case CARD_DISABLE_NFC_SUCCEEDED:
    case CARD_ENABLE_TOPUP_SUCCEEDED:
    case CARD_DISABLE_TOPUP_SUCCEEDED:
    case CARD_TOKEN_SUBMIT_SUCCESS:
      const lc = action.dbRes.lunchr_cards[0]
      return updateStateSectionWithUuid(state, 'form', action.uuid, {
        token: '',
        serverError: null,
        submitting: false,
        lockActive: lc.user_blocked_at !== null,
        nfcActive: lc.nfc_enabled,
        topupActive: lc.topup_enabled,
        topupErrorCode: lc.topup_error_code,
      })

    case ANONYMOUS_CARD_TOKEN_SUBMIT_SUCCESS:
      return {
        ...state,
        anonymousCardForm: {
          ...state.anonymousCardForm,
          token: '',
          serverError: null,
          submitting: false,
        },
      }

    case REVEAL_PIN_SUBMIT_SUCCESS:
      return updateStateSectionWithUuidAndSetServerError(
        state,
        'pinCode',
        action.uuid,
        {
          value: action.pin,
        },
        action.message
      )

    case LOST_SUBMIT_SUCCESS:
      return updateStateSectionWithUuidAndSetServerError(
        state,
        'lost',
        action.uuid,
        { value: true },
        action.message
      )

    case UNLOCK_CARD_PIN_SUBMIT_SUCCESS:
      return updateStateSectionWithUuidAndSetServerError(
        state,
        'unlockPINWarning',
        action.uuid,
        { value: true, modalActive: false },
        action.message
      )

    case PIN_MODAL_SHOW:
      const pinModalShowState = updateStateSectionWithUuidAndSetServerError(
        state,
        'pinCode',
        action.uuid,
        { modalActive: true, value: null },
        null
      )

      return updateStateSectionWithUuid(
        pinModalShowState,
        'form',
        action.uuid,
        { token: '' }
      )

    case PIN_MODAL_HIDE:
      return updateStateSectionWithUuid(state, 'pinCode', action.uuid, {
        modalActive: false,
      })

    case LOST_MODAL_SHOW:
      return updateStateSectionWithUuidAndSetServerError(
        state,
        'lost',
        action.uuid,
        {
          modalActive: true,
          value: false,
        },
        null
      )

    case LOST_MODAL_HIDE:
      return updateStateSectionWithUuid(state, 'lost', action.uuid, {
        modalActive: false,
      })

    case UNLOCK_PIN_WARNING_MODAL_SHOW:
      return updateStateSectionWithUuidAndSetServerError(
        state,
        'unlockPINWarning',
        action.uuid,
        {
          modalActive: true,
          value: false,
        },
        null
      )

    case UNLOCK_PIN_WARNING_MODAL_HIDE:
      return updateStateSectionWithUuid(
        state,
        'unlockPINWarning',
        action.uuid,
        {
          modalActive: false,
        }
      )

    default:
      return state
  }
}

export default reducer
