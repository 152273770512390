import React from 'react'
import styled from 'styled-components'

import { COLORS, Button, media } from '@themenu/ui'

export const FeedBackButtonSuccessMessage = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: ${COLORS.BLUE_SOFT};
  margin-top: 27px;
`
const StyledButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-top: 29px;

  ${media.SM`
    flex-direction: row;
  `}
`

const StyledButton = styled(Button)`
  width: 100%;

  ${media.SM`
    width: auto;
  `}
`

const ErrorMessage = styled.span`
  color: ${COLORS.PINK};
  padding-top: 20px;

  ${media.SM`
    padding-top: 0;
    padding-left: 20px;
  `}
`

export function FeedBackButton({ disabled, error, success, children }) {
  return (
    <div>
      <StyledButtonContainer>
        <StyledButton
          color="green"
          size="large"
          type="submit"
          disabled={disabled}
        >
          Valider
        </StyledButton>
        {error && (
          <ErrorMessage>
            Une erreur est survenue, merci de réessayer plus tard
          </ErrorMessage>
        )}
      </StyledButtonContainer>

      {success && children}
    </div>
  )
}
