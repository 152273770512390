import apiRequest from '@themenu/shared/lib/services/request'
import { COMPANY_API_ENDPOINT } from '@themenu/shared/lib/utils/request'

export const activateAnonymousLunchrCard = (token, userID) => {
  return apiRequest(
    'POST',
    `users/${userID}/anonymous_lunchr_cards`,
    {
      anonymous_lunchr_card: {
        public_token: token,
      },
    },
    {},
    COMPANY_API_ENDPOINT,
    true
  )
}
