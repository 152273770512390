import { fromJS } from 'immutable'
import Cookies from '@themenu/shared/lib/services/cookies'
import { COOKIE_DOMAIN } from '@themenu/shared/lib/utils/cookies'

const EXPIRATION = 365

const initialState = {
  hasSeenSecondTour: false,
}

const COOKIE_SET = 'cookie/SET'

export const setCookie = (name, value, expirationDay = EXPIRATION) => {
  Cookies.set(`lunchr:${name}`, value, {
    path: '/',
    maxAge: 3600 * 24 * expirationDay,
    secure: process.env.NODE_ENV === 'production',
    domain: COOKIE_DOMAIN,
  })
}

export const getCookie = name => Cookies.get(`lunchr:${name}`)

export const getInitialState = () => {
  return Object.keys(initialState).reduce((acc, k) => {
    const value = getCookie(k)
    if (value) {
      acc[k] = value

      // reset cookie for inifinite live
      setCookie(k, acc[k])
    } else {
      acc[k] = initialState[k]
    }

    return acc
  }, {})
}

export const set = (name, value) => {
  setCookie(name, value)

  return {
    type: COOKIE_SET,
    name,
    value,
  }
}

const reducer = (state = fromJS(initialState), action) => {
  if (action.type === COOKIE_SET) {
    return state.set(action.name, action.value)
  }

  return state
}

export default reducer
