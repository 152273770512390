import React from 'react'
import { connect } from 'react-redux'
import { FormattedMessage, defineMessages, injectIntl } from 'react-intl'
import { compose, branch, renderNothing } from 'recompose'
import { has } from 'ramda'

import { close } from '../../modules/ui/toast-message'
import withModal from '../../hocs/withModal'
import {
  Button,
  Box,
  Emoji,
  EmojiBadge,
  Title3,
  ToastMessage,
  Paragraph,
} from '@themenu/ui'

const i18n = defineMessages({
  unknownError: {
    id: 'Toaster.unknownError',
    defaultMessage: "Une erreur inconnue s'est produite",
  },
  sessionExpired: {
    id: 'Toaster.sessionExpired',
    defaultMessage: 'Votre session a expiré',
  },
  partnersDashboardUnauthorized: {
    id: 'Toaster.partnersDashboardUnauthorized',
    defaultMessage: "Vous n'avez pas accès à l'interface affilié",
  },
  changesSaved: {
    id: 'Toaster.changesSaved',
    defaultMessage: 'Nous avons bien enregistré vos modifications',
  },
  passwordChanged: {
    id: 'Toaster.passwordChanged',
    defaultMessage:
      'Vous pouvez désormais vous connecter avec votre nouveau mot de passe',
  },
  cannotLogYouIn: {
    id: 'Toaster.cannotLogYouIn',
    defaultMessage: 'Nous ne parvenons pas à vous identifier.',
  },
  userRemovedFromOrder: {
    id: 'Toaster.userRemovedFromOrder',
    defaultMessage: "Vous venez de retirer l'utilisateur de cette commande",
  },
  refillDaysChanged: {
    id: 'Toaster.refillDaysChanged',
    defaultMessage: 'Jours à charger modifiés',
  },
  importDone: {
    id: 'Toaster.importDone',
    defaultMessage: 'Import effectué',
  },
  configDeliveryAddresses: {
    id: 'Toaster.configDeliveryAddresses',
    defaultMessage:
      "Vous devez d'abord configurer les adresses de livraison de vos sociétés",
  },
  anonymousCardsRemoved: {
    id: 'Toaster.anonymousCardsRemoved',
    defaultMessage:
      'Vous venez de retirer des cartes anonymes de cette commande',
  },
  cardSaved: {
    id: 'Toaster.cardSaved',
    defaultMessage: 'Votre carte est enregistrée',
  },
  cardLocked: {
    id: 'Toaster.cardLocked',
    defaultMessage: 'Votre carte est maintenant inactive',
  },
  cardUnlocked: {
    id: 'Toaster.lockUnlocked',
    defaultMessage: 'Votre carte est maintenant active',
  },
  cardNfcEnabled: {
    id: 'Toaster.nfcEnabled',
    defaultMessage:
      'Paiement sans contact actif ! Il sera opérationnel après une première transaction réussie :)',
  },
  cardNfcDisabled: {
    id: 'Toaster.nfcDisabled',
    defaultMessage: 'Le paiement sans contact est maintenant inactif',
  },
  restaurantTooFarAway: {
    id: 'Toaster.restaurantTooFarAway',
    defaultMessage:
      'Ce restaurant semble un peu loin de votre position ({distance}), êtes-vous prêt pour une petite balade ?',
  },
  groupedOrderCancelled: {
    id: 'Toaster.groupedOrderCancelled',
    defaultMessage: 'La commande groupée a été annulée',
  },
  groupedOrderEnded: {
    id: 'Toaster.groupedOrderEnded',
    defaultMessage: 'La commande groupée est terminée',
  },
  orderNotFound: {
    id: 'Toaster.orderNotFound',
    defaultMessage: `La commande n'a pas été trouvée`,
  },
  restaurantNotFound: {
    id: 'Toaster.restaurantNotFound',
    defaultMessage: `Le restaurant n'a pas été trouvé`,
  },
  cardTopupEnabled: {
    id: 'CardScreen.topup.enabled',
    defaultMessage: 'Le dépassement a bien été activé',
  },
  cardTopupDisabled: {
    id: 'CardScreen.topup.disabled',
    defaultMessage: 'Le dépassement est à présent désactivé',
  },
  'CompanySignup.CompanyName.Form.lookup.error': {
    id: 'CompanySignup.CompanyName.Form.lookup.error',
    defaultMessage:
      "Nous n'avons pas trouvé les informations relatives à votre entreprise.",
  },
  youNowManageCompany: {
    id: 'Toaster.youNowManageCompany',
    defaultMessage: 'Vous gérez maintenant {companyName}',
  },
  avatarUpdated: {
    id: 'Toaster.avatarUpdated',
    defaultMessage: 'Votre avatar a été mis à jour',
  },
})

const ToasterErrorModal = withModal(({ children, onModalClose, options }) => (
  <Box p={[20, 50]} flex alignItems="center" justifyContent="center">
    <Box p={0}>
      <EmojiBadge>
        <Emoji emoji="man-shrugging-light-skin-tone" alt="🤷" size="48px" />
      </EmojiBadge>
      <Title3 fontSize={20} center>
        <FormattedMessage
          id="Toaster.ErrorModal.title"
          defaultMessage="Houston, nous avons un problème !"
        />
      </Title3>

      <Paragraph center fontSize={15}>
        {children}
      </Paragraph>

      {options.bottomMessage && (
        <Paragraph center fontSize={15}>
          <Emoji emoji="backhand-index-pointing-right" size="22px" />
          <strong>{options.bottomMessage}</strong>
        </Paragraph>
      )}

      {options.closeCta && (
        <Box flex justifyContent="center">
          <Button onClick={onModalClose} color="green" size="medium">
            {options.closeCta}
          </Button>
        </Box>
      )}
    </Box>
  </Box>
))

const closeHandler = dispatch => () => dispatch(close())
const Message = injectIntl(
  ({ intl: { formatMessage }, message, messageIntl }) => {
    if (messageIntl !== null) {
      return (
        <span>{formatMessage(i18n[messageIntl.key], messageIntl.values)}</span>
      )
    } else {
      return has('__html', message) ? (
        <div dangerouslySetInnerHTML={message} />
      ) : (
        <span>{message}</span>
      )
    }
  }
)
export const Toaster = ({ message, messageIntl, options, dispatch }) =>
  options.errorModal ? (
    <ToasterErrorModal
      modalProps={{
        contentLabel: 'Card code modal',
        isOpen: true,
        onRequestClose: closeHandler(dispatch),
      }}
      options={options}
    >
      <Message message={message} messageIntl={messageIntl} />
    </ToasterErrorModal>
  ) : (
    <ToastMessage onRequestClose={closeHandler(dispatch)}>
      <Message message={message} messageIntl={messageIntl} />
    </ToastMessage>
  )

const mapStateToProps = state => ({
  message: state.getIn(['ui', 'toastMessage', 'message']),
  messageIntl: state.getIn(['ui', 'toastMessage', 'messageIntl']),
  options: state.getIn(['ui', 'toastMessage', 'options']),
})

const enhance = compose(
  connect(mapStateToProps),
  branch(({ message, messageIntl }) => !message && !messageIntl, renderNothing)
)

export default enhance(Toaster)
