import React from 'react'
import { Route } from 'react-router-dom'
import { ifElse, identity, always, curry } from 'ramda'

import PrivateRoute from '@themenu/ui/src/containers/PrivateRoute'
import Layout from './Layout'

const createRouteComponent = ifElse(
  identity,
  always(PrivateRoute),
  always(Route)
)

const RouteComponentContent = curry((Component, parentProps, props) => (
  <Layout {...props} {...parentProps}>
    <Component {...props} />
  </Layout>
))

const NewLayout = ({
  path,
  component: Component,
  private: isPrivate,
  ...props
}) => {
  const RouteComponent = createRouteComponent(isPrivate)

  return (
    <RouteComponent
      path={path}
      component={RouteComponentContent(Component, props)}
    />
  )
}

export default NewLayout
