import React, { useContext } from 'react'
import styled from 'styled-components'

import { ReferralContext } from '../ReferralContext'
import { CompletePage } from '../common/components/Congratulations/CompletePage'
import { EmojiInText } from '../common/components/EmojiInText'
import { getRewardPerPerson } from '../common/helpers/getRewardPerPerson'
import { testLunchrUrl } from '../common/testLunchrUrl'

function Text() {
  const SemiBold = styled.span`
    font-weight: 600;
  `

  return (
    <span>
      <SemiBold>
        Dès que tous vos collègues de l’aventure auront rempli leur adresse
        postale
      </SemiBold>
      , vous recevrez un email de confirmation et quelques jours plus tard, vous
      recevrez votre carte Swile prépayée chez vous.  
      <br />
      <br />
      Alors, elle est pas belle la vie !<EmojiInText emoji={'sunglasses'} />
    </span>
  )
}

function Description({ voucherAmount, rewardPerPerson }) {
  return (
    <div>
      Tous ensemble vous allez débloquer {voucherAmount}€, dont{' '}
      {rewardPerPerson}€ pour vous !{' '}
      <EmojiInText emoji="party-popper" size="35px" />
    </div>
  )
}

export function ReferralGuestComplete() {
  const {
    guestState: { firstname, guest },
  } = useContext(ReferralContext)
  const rewardPerPerson = getRewardPerPerson(guest)
  const voucherAmount = rewardPerPerson * (guest + 1)
  if (!firstname || !guest || guest < 1) {
    window.location = testLunchrUrl
    return null
  }
  const params = {
    title: `Bravo ${firstname} !`,
    description: (
      <Description
        voucherAmount={voucherAmount}
        rewardPerPerson={rewardPerPerson}
      />
    ),
    text: <Text />,
  }
  return <CompletePage {...params} />
}
