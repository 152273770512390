import React from 'react'
import styled from 'styled-components'

const FormBox = styled.div`
  margin: 5px;
  padding: 5px;
`
function FormWrap({ children }) {
  return <FormBox>{children}</FormBox>
}

export default FormWrap
