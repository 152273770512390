import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveCardIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C15.5228 20 20 15.5228 20 10C20 4.47715 15.5228 0 10 0C4.47715 0 0 4.47715 0 10C0 15.5228 4.47715 20 10 20ZM13 7C13 8.65685 11.6569 10 10 10C8.34315 10 7 8.65685 7 7C7 5.34315 8.34315 4 10 4C11.6569 4 13 5.34315 13 7ZM3.88426 15.1576C5.2436 13.2466 7.47623 12 10 12C12.5238 12 14.7564 13.2466 16.1157 15.1576C14.6482 16.8959 12.4531 18 10 18C7.54694 18 5.35175 16.8959 3.88426 15.1576Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default ActiveCardIcon
