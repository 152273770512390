import { Route, Redirect, Switch } from 'react-router-dom'
import { StripeProvider } from 'react-stripe-elements'
import { ThemeProvider } from 'styled-components'
import React, { useState, useEffect } from 'react'
import { ReactQueryConfigProvider } from 'react-query'

import { ThemeProvider as DSThemeProvider } from '@themenu/design-system'
import PrivateRoute from '@themenu/ui/src/containers/PrivateRoute'

import {
  AsyncPreferences,
  AsyncCardOnboarding,
  AsyncAnonymousCardOnboarding,
  AsyncConfirmAccount,
  AsyncHistory,
  AsyncForgotPassword,
  AsyncPageNotFound,
  AsyncPayments,
  AsyncResetPassword,
  AsyncStayhungry,
  AsyncLegalNotice,
  AsyncPrivacyPolicy,
  AsyncGeneralConditionsOfService,
  AsyncConfirmExternalRequest,
  AsyncKycAppRedirect,
  AsyncTaxReceiptAddress,
} from './screens/Async'
import AppLayout from './screens/Layout/AppLayout'
import AuthLayout from './screens/Layout/AuthLayout'
import ScrollToTop from './screens/Layout/ScrollToTop'
import NewLayout from './components/NewLayout'
import CorpoRedirect from './components/CorpoRedirect/CorpoRedirect'
import Sentry from './containers/Sentry'
import {
  UserAccountPaymentsRoute,
  PartnersHomeRoute,
  restoStandaloneOnboardingBase,
  restoOnboardingBase,
  restoOnboardingLead,
  ConfirmExternalRequestRoute,
  ReferralRoute,
} from './routes'
import { main as mainTheme } from './themes'
import BrowserNotifications from './containers/BrowserNotifications'
import Home from './screens/Home/HomeSwitch'
import LegacyRouter from './screens/Legacy'
import PrivateWithEmailRoute from './containers/PrivateWithEmailRoute'
import Router from './containers/Router'
import SidebarLayout from './screens/Layout/SidebarLayout'
import ZendeskProvider from './containers/ZendeskProvider'
import BeforeStart from './containers/BeforeStart'
import ErrorController from './containers/ErrorController'
import Referral from './screens/Referral'
import Ping from './screens/Ping'
import { STATIC_WEBSITE } from './utils/config'
import AppRedirect from './components/AppRedirect'
import NewAuthLayout from './screens/Layout/NewAuthLayout'

const STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY

const App = () => {
  const [stripe, setStripe] = useState(null)

  const queryConfig = {
    refetchOnWindowFocus: false,
  }

  useEffect(() => {
    function b64_to_utf8(str) {
      return decodeURIComponent(escape(window.atob(str)))
    }

    if (process.env.NODE_ENV !== 'development') {
      if (
        window.location.host !== b64_to_utf8('YXBwLnN3aWxlLmNv') &&
        window.location.host !== b64_to_utf8('YXBwLXN0YWdpbmcuc3dpbGUuY28=') &&
        window.location.host !== b64_to_utf8('YXBwLXNhbmRib3guc3dpbGUuY28=')
      ) {
        window.location.replace(b64_to_utf8('aHR0cHM6Ly9hcHAuc3dpbGUuY28'))
      }
    }

    if (window.Stripe) {
      setStripe(window.Stripe(STRIPE_API_KEY))
    } else {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        if (window.Stripe) {
          setStripe(window.Stripe(STRIPE_API_KEY))
        }
      })
    }
  }, [])

  return (
    <DSThemeProvider>
      <ThemeProvider theme={mainTheme}>
        <StripeProvider stripe={stripe}>
          <ReactQueryConfigProvider config={queryConfig}>
            <Router>
              <ScrollToTop>
                <Sentry />
                <ZendeskProvider />
                <BrowserNotifications />
                <ErrorController />

                <Switch>
                  <AppRedirect
                    path="/pots/:id"
                    origin={`${process.env.REACT_APP_EMPLOYEE_APP_URL}/pots/:id`}
                  />

                  <Route path="/pro">
                    <CorpoRedirect />
                  </Route>

                  <Route exact path="/ping" component={Ping} />

                  <Route
                    path="/kyc-app-redirect"
                    component={AsyncKycAppRedirect}
                  />

                  <Redirect exact path="/card" to="/payments/lunchr-card" />
                  <Redirect
                    exact
                    path="/card/:id"
                    to="/payments/lunchr-card/:id"
                  />
                  <Redirect exact path="/cards" to="/payments/lunchr-card" />
                  <Redirect
                    exact
                    path="/payments/lunchr-card"
                    to="/payments/swile-card"
                  />
                  <Redirect
                    exact
                    path="/payments/lunchr-card/:id"
                    to="/payments/swile-card/:id"
                  />
                  <Redirect
                    exact
                    path="/cards/:id"
                    to="/payments/swile-card/:id"
                  />
                  <Redirect
                    exact
                    path="/cards/:id/attached-card"
                    to="/payments/swile-card/:id"
                  />
                  <AppRedirect
                    path="/payments/swile-card"
                    origin={`${process.env.REACT_APP_EMPLOYEE_APP_URL}/swile-cards`}
                  />
                  <AppRedirect
                    path="/payments/swile-card/:id"
                    origin={`${process.env.REACT_APP_EMPLOYEE_APP_URL}/swile-cards`}
                  />
                  <Redirect
                    exact
                    from="/credit-cards"
                    to="/payments/credit-cards"
                  />
                  <Redirect
                    exact
                    from="/credit-cards/:id"
                    to="/payments/credit-cards/:id"
                  />
                  <AppRedirect
                    path="/payments/credit-cards"
                    origin={`${process.env.REACT_APP_EMPLOYEE_APP_URL}/profile/my-personal-cards`}
                  />
                  <AppRedirect
                    path="/payments/credit-cards/:id"
                    origin={`${process.env.REACT_APP_EMPLOYEE_APP_URL}/profile/my-personal-cards`}
                  />
                  <Route
                    path="/profile"
                    render={() => {
                      window.location.replace(
                        process.env.REACT_APP_EMPLOYEE_APP_URL + '/profile'
                      )
                      return null
                    }}
                  />

                  <NewLayout private path="/history" component={AsyncHistory} />

                  <NewLayout
                    private
                    path="/tax-receipt-address"
                    component={AsyncTaxReceiptAddress}
                  />

                  <Route path="/orders" exact component={LegacyRouter} />

                  <PrivateWithEmailRoute path="/" exact component={Home} />

                  <Route
                    path="/invite/:invitationId/:publicToken?"
                    render={() => {
                      window.location.replace(
                        process.env.REACT_APP_JOIN_URL + '/invitation-expired'
                      )
                      return null
                    }}
                  />

                  {/* login and signup screens + template */}
                  <Route
                    path="/signin"
                    render={() => {
                      window.location.replace(
                        process.env.REACT_APP_AUTH_APP_URL +
                          '/signin' +
                          new URL(window.location.href).search
                      )
                      return null
                    }}
                  />

                  <Route
                    path="/stayhungry"
                    render={props => (
                      <AuthLayout noHeader>
                        <AsyncStayhungry {...props} />
                      </AuthLayout>
                    )}
                  />

                  <Route
                    path="/forgot-password"
                    render={props => (
                      <NewAuthLayout>
                        <AsyncForgotPassword {...props} />
                      </NewAuthLayout>
                    )}
                  />

                  <Route
                    path="/reset-password/:token"
                    render={props => (
                      <NewAuthLayout>
                        <AsyncResetPassword {...props} />
                      </NewAuthLayout>
                    )}
                  />

                  <Route
                    path="/confirm-account/:token"
                    render={props => (
                      <AuthLayout>
                        <AsyncConfirmAccount {...props} />
                      </AuthLayout>
                    )}
                  />

                  <PrivateRoute
                    path="/card-activate"
                    component={props => (
                      <AuthLayout showMenu>
                        <AsyncCardOnboarding {...props} />
                      </AuthLayout>
                    )}
                  />

                  <PrivateRoute
                    path="/anonymous-card-activate"
                    component={props => (
                      <AuthLayout showMenu>
                        <AsyncAnonymousCardOnboarding {...props} />
                      </AuthLayout>
                    )}
                  />

                  <Route path={ReferralRoute.spec} component={Referral} />

                  <AppRedirect
                    path="/recover-card"
                    origin={process.env.REACT_APP_JOIN_URL}
                  />

                  <Route path={ReferralRoute.spec} component={Referral} />

                  <Route
                    path={restoOnboardingBase}
                    render={({ match }) => {
                      const { salesforceId } = match.params
                      window.location.replace(
                        STATIC_WEBSITE +
                          '/restaurants/onboarding/' +
                          salesforceId
                      )
                      return null
                    }}
                  />
                  <Route
                    path={restoOnboardingLead}
                    render={({ match }) => {
                      const { salesforceId } = match.params
                      window.location.replace(
                        STATIC_WEBSITE + '/restaurants/join/' + salesforceId
                      )
                      return null
                    }}
                  />
                  <Route
                    path={restoStandaloneOnboardingBase}
                    render={() => {
                      window.location.replace(
                        STATIC_WEBSITE + '/restaurants/standalone/onboarding'
                      )
                      return null
                    }}
                  />

                  <AppRedirect
                    path={PartnersHomeRoute.spec}
                    origin={process.env.REACT_APP_AFFILIATES_URL}
                  />

                  <AppLayout>
                    <Switch>
                      {/* Public routes */}
                      <Route path="/404" component={AsyncPageNotFound} />
                      <Route
                        path="/legal-notice"
                        component={AsyncLegalNotice}
                      />
                      <Route
                        path="/privacy-policy"
                        component={AsyncPrivacyPolicy}
                      />
                      <Redirect exact path="/tos" to="/conditions-of-service" />
                      <Redirect exact path="/tou" to="/conditions-of-service" />
                      <Route
                        path="/conditions-of-service"
                        component={AsyncGeneralConditionsOfService}
                      />

                      <Route
                        path={ConfirmExternalRequestRoute.spec}
                        component={AsyncConfirmExternalRequest}
                      />

                      <PrivateWithEmailRoute
                        path="/history"
                        component={AsyncHistory}
                      />

                      <Route component={AsyncPageNotFound} />

                      <SidebarLayout>
                        <PrivateRoute
                          path="/preferences"
                          component={AsyncPreferences}
                        />
                        <PrivateRoute
                          path={UserAccountPaymentsRoute.reverse()}
                          component={AsyncPayments}
                        />
                      </SidebarLayout>
                    </Switch>
                  </AppLayout>
                </Switch>
              </ScrollToTop>
            </Router>
          </ReactQueryConfigProvider>
        </StripeProvider>
      </ThemeProvider>
    </DSThemeProvider>
  )
}

export default () => (
  <BeforeStart>
    <App />
  </BeforeStart>
)
