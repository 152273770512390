import React from 'react'
import styled from 'styled-components'

const Text = styled.div`
  font-size: 21px;
  line-height: 33px;
  color: #1b2733;
  font-weight: bold;
  display: inline-block;
`

function FormTitle({ title }) {
  return <Text> {title} </Text>
}

export default FormTitle
