import styled from 'styled-components'
import { COLORS, media } from '@themenu/ui'

export const FeedBackText = styled.div`
  font-size: 16px;
  line-height: 20px;
  color: ${COLORS.BLUE_SOFT};
  font-weight: bold;
  display: inline-block;

  ${media.SM`
    font-size: 20px;
    line-height: 24px;
  `}
`
