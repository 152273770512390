import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

const Wrapper = styled.div`
  height: 100%;
`

class ScrollToTop extends Component {
  componentDidUpdate(prevProps) {
    const { location } = this.props
    const noScroll = location.state && location.state.noscroll

    if (location !== prevProps.location && !noScroll) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    return <Wrapper>{this.props.children}</Wrapper>
  }
}

export default withRouter(ScrollToTop)
