import React from 'react'
import { Switch } from 'react-router-dom'
import styled from 'styled-components'

import { Box, COLORS, SIDEBAR_WIDTH } from '@themenu/ui'
import { media } from '@themenu/ui/src/components/utils'

import SidebarUser from './SidebarUser'

const SidebarWrapper = styled(Box)`
  display: none;

  ${media.MD`
    background-color: ${COLORS.MAIN_BG_COLOR_GRAY}
    display: block;
    height: 100%;
    width: ${SIDEBAR_WIDTH};
  `}
`
SidebarWrapper.displayName = 'SidebarWrapper'

const Sidebar = props => (
  <SidebarWrapper>
    <Switch>
      <SidebarUser {...props} />
    </Switch>
  </SidebarWrapper>
)
Sidebar.displayName = 'SidebarRouter'

export default Sidebar
