export function mergeOrCreateEntity(state, entity, idKey = 'id') {
  const old = state[entity[idKey]]
  return old
    ? {
        ...old,
        ...entity,
      }
    : entity
}

export function mergeEntities(state, entities, idKey = 'id') {
  const newState = { ...state }
  entities.forEach(entity => {
    newState[entity[idKey]] = mergeOrCreateEntity(newState, entity)
  })

  return newState
}

export function mergeTypedEntities(state, entities, Type, idKey = 'id') {
  const update =
    entities instanceof Array
      ? entities.reduce((memo, item) => {
          memo[item[idKey]] = {
            [state[item[idKey]] ? '$merge' : '$set']: item,
          }
          return memo
        }, {})
      : Object.keys(entities).reduce((memo, id) => {
          const item = entities[id]
          memo[id] = {
            [state[id] ? '$merge' : '$set']: item,
          }
          return memo
        }, {})
  return Type.update(state, update)
}
