import { FormattedMessage } from 'react-intl'
import { Link as ReactRouterLink } from 'react-router-dom'
import Dropdown, {
  DropdownTrigger,
  DropdownContent,
} from 'react-simple-dropdown'
import React, { useRef } from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'

import {
  Icon,
  HeaderMenu,
  HeaderMenuItem,
  UserAvatar,
  COLORS,
} from '@themenu/ui'

import { PartnersHomeRoute } from '../../../routes'
import MealVoucherInfo from '../MealVoucherInfo'
import zendesk from '@themenu/shared/lib/services/zendesk'
import MealVoucherBalanceBlock from '../../../components/MealVoucherBalanceBlock'
import { MealVoucherWalletPropType } from '../../../utils/propTypes'
import { RESTAURANT_LIMIT_ENABLED } from '../../../utils/constants'

const GIFT_APP_URL = process.env.REACT_APP_CORPO_GIFT_APP_URL
const CORPO_APP_URL = process.env.REACT_APP_CORPO_APP_URL

export const StyledUserMenu = styled.div`
  display: flex;
  align-items: center;
`

export const UserMenuDropdown = styled(Dropdown)`
  position: relative;

  .UserMenu__Avatar {
    position: relative;
    display: flex;
    cursor: pointer;

    img {
      opacity: 0.8;
    }

    &:hover {
      img {
        opacity: 1;
      }

      &::before {
        border-top-color: rgba(0, 0, 0, 0.2);
      }
    }
  }

  &.dropdown--active {
    .UserMenu__Avatar {
      img {
        opacity: 1;
      }

      &::before {
        border-top-color: rgba(0, 0, 0, 0.2);
      }
    }
  }
`

export const UserMenuDropdownContent = styled(
  ({ children, maxHeight, ...props }) => (
    <DropdownContent {...props}>
      <HeaderMenu maxHeight={maxHeight} right={-52}>
        {children}
      </HeaderMenu>
    </DropdownContent>
  )
)`
  .UserMenu__Headings {
    margin-bottom: 20px;
    line-height: 1.25;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .UserMenu__Headings__Avatar {
    flex-shrink: 1;
    width: 32px;
    height: 32px;
    margin-right: 20px;
  }

  .UserMenu__Name {
    font-weight: bold;
    font-size: 16px;
    flex-grow: 1;
  }

  .UserMenu__TeamName {
    color: ${COLORS.MAIN_TEXT_COLOR_LIGHT};
    font-size: 14px;
  }

  .UserMenu__Menu {
    margin: 0 -16px;
  }

  .UserMenu__Title {
    padding: 10px 16px;
    font-size: 14px;
    font-weight: 600;
    color: ${COLORS.ORANGE};
  }

  .UserMenu__Company {
    align-items: center;
    display: flex;
    margin-left: -2px;
  }

  .UserMenu__Company.current {
    margin-top: 25px;
    margin-bottom: 10px;
  }

  .UserMenu__CompanyName {
    font-weight: bold;
    font-size: 16px;
  }
  .UserMenu__CompanyNameList {
    font-size: 14px;
  }
  .UserMenu__CompanyName,
  .UserMenu__CompanyNameList {
    padding-left: 20px;
  }

  .UserMenu__CompanyTitle {
    padding: 2px 0 17px 36px;
  }
`

const LinkStyles = css`
  display: block;
  padding: 11px 16px;
  font-size: 14px;
  border-radius: 8px;
  color: ${COLORS.MAIN_TEXT_COLOR};

  &,
  &:visited {
    color: ${COLORS.BLUE_DARK};
  }

  &:hover {
    color: ${COLORS.GREEN};
    background-color: ${COLORS.GRAY_VERY_LIGHT};
  }
`

const Link = styled(ReactRouterLink)`
  ${LinkStyles};
`
const SimpleLink = styled.a`
  ${LinkStyles};
  &:hover {
    cursor: pointer;
  }
`

const AbsolutLink = styled.a`
  ${LinkStyles};
`

const LogOutLink = styled.a`
  ${LinkStyles};
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid ${COLORS.GRAY_COOL};
  color: ${COLORS.GRAY};
  margin-top: 10px;
  margin-bottom: 15px;
  &,
  &:visited {
    color: ${COLORS.GRAY};
  }
  &:hover {
    cursor: pointer;
    border-color: ${COLORS.GRAY_VERY_LIGHT};
  }
`

function UserMenu({
  avatarPictureKey,
  firstname,
  lastname,
  onLogoutClick,
  mealVoucher,
  active,
  isCompanyAdmin,
  isAffiliate,
  hasActivateAnonymousCardAccess,
  mealVoucherWallet,
  isDisplayingDailyBalance,
  isOnlyGiftProgramAdmin,
  hasMealVoucherProgram,
  hasGiftProgram,
  hasMealVoucherBalance,
  hasLunchrCard,
}) {
  const refDropdown = useRef()

  const handleMenuLinkClick = () => {
    refDropdown.current.hide()
  }

  return (
    <StyledUserMenu>
      <UserMenuDropdown active={active} ref={refDropdown} role="menubar">
        <DropdownTrigger>
          <HeaderMenuItem>
            <div className="UserMenu__Avatar">
              <UserAvatar
                user={{
                  avatar_picture: avatarPictureKey
                    ? { key: avatarPictureKey }
                    : null,
                  first_name: firstname,
                  last_name: lastname,
                }}
              />
            </div>
          </HeaderMenuItem>
        </DropdownTrigger>

        <UserMenuDropdownContent maxHeight={window.innerHeight - 100}>
          <div className="UserMenu__Headings">
            <span className="UserMenu__Headings__Avatar">
              <UserAvatar
                user={{
                  avatar_picture: avatarPictureKey
                    ? { key: avatarPictureKey }
                    : null,
                  first_name: firstname,
                  last_name: lastname,
                }}
              />
            </span>
            <span className="UserMenu__Name">
              {firstname} {lastname}
            </span>
          </div>

          {RESTAURANT_LIMIT_ENABLED
            ? mealVoucherWallet && (
                <MealVoucherBalanceBlock
                  isWithBorder
                  isDisplayingDailyBalance={isDisplayingDailyBalance}
                  mealVoucherWallet={mealVoucherWallet}
                />
              )
            : mealVoucher && (
                <MealVoucherInfo
                  expectedBalance={mealVoucher.expectedBalance}
                  expectedBalanceDate={mealVoucher.expectedBalanceDate}
                  globalBalance={mealVoucher.balance}
                  dailyBalance={mealVoucher.dailyBalance}
                  facialValue={mealVoucher.facialValue}
                />
              )}

          <ul className="UserMenu__Menu">
            <li
              onClick={handleMenuLinkClick}
              onKeyDown={handleMenuLinkClick}
              role="menuitem"
            >
              <Link to="/profile">
                <FormattedMessage
                  id="Layout.UserMenu.profileLink"
                  defaultMessage="Modifier mon profil"
                />
              </Link>
            </li>

            {(hasMealVoucherProgram || hasMealVoucherBalance) && (
              <li
                onClick={handleMenuLinkClick}
                onKeyDown={handleMenuLinkClick}
                role="menuitem"
              >
                <Link to={'/history/mealvouchers'}>
                  <FormattedMessage
                    id="Layout.UserMenu.ordersHistory"
                    defaultMessage="Commandes & transactions"
                  />
                </Link>
              </li>
            )}

            {(hasLunchrCard || hasMealVoucherProgram) && (
              <li
                onClick={handleMenuLinkClick}
                onKeyDown={handleMenuLinkClick}
                role="menuitem"
              >
                <Link to="/payments/swile-card">
                  <FormattedMessage
                    id="Layout.UserMenu.card"
                    defaultMessage="Cartes Swile"
                  />
                </Link>
              </li>
            )}

            {hasGiftProgram && (
              <li
                onClick={handleMenuLinkClick}
                onKeyDown={handleMenuLinkClick}
                role="menuitem"
              >
                <AbsolutLink href={GIFT_APP_URL}>
                  <FormattedMessage
                    id="UserSidebar.gift"
                    defaultMessage="Mes avantages"
                  />
                </AbsolutLink>
              </li>
            )}

            {hasActivateAnonymousCardAccess ? (
              <li
                onClick={handleMenuLinkClick}
                onKeyDown={handleMenuLinkClick}
                role="menuitem"
              >
                <Link to="/anonymous-card-activate">
                  <FormattedMessage
                    id="UserSidebar.activateCard"
                    defaultMessage="Activer une carte anonyme"
                  />
                </Link>
              </li>
            ) : null}

            {(hasMealVoucherProgram || hasMealVoucherBalance) && (
              <li
                onClick={handleMenuLinkClick}
                onKeyDown={handleMenuLinkClick}
                role="menuitem"
              >
                <Link to="/payments/credit-cards">
                  <FormattedMessage
                    id="Layout.UserMenu.payments"
                    defaultMessage="Cartes de crédit"
                  />
                </Link>
              </li>
            )}
          </ul>

          {isCompanyAdmin && (
            <div>
              <span className="Separator" />
              <ul className="UserMenu__Menu">
                <li role="menuitem">
                  <SimpleLink
                    href={
                      isOnlyGiftProgramAdmin
                        ? `${GIFT_APP_URL}/admin/adminDashboard`
                        : CORPO_APP_URL
                    }
                  >
                    <FormattedMessage
                      id="Layout.UserMenu.Admin"
                      defaultMessage="Espace Administrateur"
                    />
                  </SimpleLink>
                </li>
              </ul>
            </div>
          )}

          {isAffiliate && (
            <div>
              <span className="Separator" />
              <ul className="UserMenu__Menu">
                <li role="menuitem">
                  <Link
                    to={PartnersHomeRoute.reverse()}
                    onClick={handleMenuLinkClick}
                  >
                    <FormattedMessage
                      id="Layout.UserMenu.PartnersDashboard"
                      defaultMessage="Espace affilié"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          )}
          <div>
            <span className="Separator" />
            <ul className="UserMenu__Menu">
              <li
                onClick={zendesk.activate}
                onKeyDown={zendesk.activate}
                role="menuitem"
              >
                <SimpleLink>
                  <Icon mr={14} name="redesign/help" color="gray" size="18" />
                  <FormattedMessage
                    id="UserMenu.HelpButton.text"
                    defaultMessage="Besoin d'aide ?"
                  />
                </SimpleLink>
              </li>
              <li
                onClick={onLogoutClick}
                onKeyDown={onLogoutClick}
                role="menuitem"
              >
                <LogOutLink>
                  <FormattedMessage
                    id="UserMenu.Logout"
                    defaultMessage="Deconnexion"
                  />
                  <Icon name="logout" color="gray" size="14" />
                </LogOutLink>
              </li>
            </ul>
          </div>
        </UserMenuDropdownContent>
      </UserMenuDropdown>
    </StyledUserMenu>
  )
}

UserMenu.propType = {
  mealVoucherWallet: MealVoucherWalletPropType,
  isDisplayingDailyBalance: PropTypes.bool,
}

export default UserMenu
