import React from 'react'
import styled from 'styled-components'
import { COLORS, media } from '@themenu/ui'

import cloudinary from '@themenu/shared/lib/services/cloudinary'
import { EmojiInText } from '../../common/components/EmojiInText'

const CHECK_CIRCLE = cloudinary.url('web-assets/check-circle.svg', {
  secure: true,
})
const StyledTitle = styled.strong`
  font-size: 34px;
  line-height: 34px;
  display: flex;
  align-items: center;
  margin-bottom: 25px;
  ${media.SM`
  font-size: 48px;
  line-height: 64px;
  margin-bottom: 84px;
  `}
`

const StyledDescription = styled.div`
  margin-bottom: 36px;
  ${media.SM`
  margin-bottom: 18px;
  `}
`
const StyledText = styled.div`
  font-size: 20px;
  line-height: 24px;
  color: #546476;
`
const StyledOptionTitle = styled.strong`
  font-size: 35px;
  line-height: 46px;
  color: #1b2733;
`
const StyledList = styled.ul`
  border: 1px solid ${COLORS.GRAY_COOL};
  border-radius: 10px;
  padding: 20px;
  list-style-type: circle;
  list-style-image: url(${CHECK_CIRCLE});
  width: 540px;
  margin-bottom: 30px;
  li {
    font-size: 16px;
    line-height: 23px;
    font-weight: bold;
    margin: 5px;
    margin-left: 25px;
    padding-bottom: 25px;
    border-bottom: 1px solid ${COLORS.GRAY_COOL};
  }
  li span {
    position: relative;
    top: -4px;
    left: 10px;
  }
  li:last-child {
    padding-top: 25px;
    padding-bottom: 5px;
    border-bottom: none;
  }
`
const OptionsWrap = styled.div`
  display: none;
  ${media.SM`
 display: block;`}
`
const ColleagueFormTitle = styled.div`
  font-size: 24px;
  line-height: 10px;
  color: #1b2733;
  padding-bottom: 25px;
  font-weight: bold;
  ${media.SM`
  font-size: 35px;
  line-height: 46px;
  `}
`

function ReferralText() {
  return (
    <div className="text-first-grid">
      <StyledTitle>Plus on est de fous, plus on mange !</StyledTitle>
      <StyledText>
        <StyledDescription>
          Embarquez vos collègues dans l’aventure et recevez chacun une carte
          titres-resto prépayée.
          <EmojiInText emoji="love-letter" /> Plus vous êtes nombreux, plus vous
          cumulez de crédit ! Magique.
        </StyledDescription>
        <OptionsWrap>
          {' '}
          <StyledOptionTitle>
            Deux options se présentent à vous :
          </StyledOptionTitle>
          <br />
          <br />
          <StyledList>
            <li>
              <span>
                Vous en parlez à votre collègue assis à côté de vous. Bravo !
                Vous êtes deux, vous débloquez 20€ (10€ chacun)
              </span>
            </li>
            <li>
              <span>
                Vous en parlez à la machine à café : l’idée fait fureur.
                <EmojiInText emoji="fire" />
                <br />
                Vous êtes cinq, vous débloquez 100€ (20€ chacun)
                <EmojiInText emoji="money-with-wings" />
              </span>
            </li>
          </StyledList>
          <ColleagueFormTitle>
            Qui d’autres allez-vous embarquer dans l’aventure Swile ?
          </ColleagueFormTitle>
        </OptionsWrap>
      </StyledText>
    </div>
  )
}

export default ReferralText
