import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'

import { ButtonLink } from '@themenu/ui'

const StyledMenu = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const MenuItem = styled.li`
  &:not(:first-child) {
    margin-left: 30px;
  }
`

const Menu = ({ location, color, ...props }) => (
  <StyledMenu {...props}>
    <MenuItem>
      <ButtonLink
        size="medium"
        to={{ pathname: '/signin', state: { next: location } }}
      >
        <FormattedMessage id="Menu.signin" defaultMessage="Connexion" />
      </ButtonLink>
    </MenuItem>
  </StyledMenu>
)

Menu.propTypes = {
  /** Color of your MenuItem */
  color: PropTypes.string,
}

Menu.defaultProps = {
  color: null,
}

export default withRouter(Menu)
