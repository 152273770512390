import React from 'react'

function CorpoRedirect() {
  if (typeof window !== 'undefined') {
    const corpoUrl = window.location.href.replace(
      window.location.origin,
      process.env.REACT_APP_CORPO_APP_URL
    )
    window.location.replace(corpoUrl)
  }
  return <div> Redirecting... </div>
}

export default CorpoRedirect
