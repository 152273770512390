import React from 'react'
import styled from 'styled-components'

import { ToasterContainer } from '@themenu/ui'
import OutdatedBrowser from './OutdatedBrowser'

/* Some elements with z-index appears over Toasters (restaurants cards, home page effects) */
const ZIndexFix = styled.div`
  z-index: 200;
  position: relative;
`

const ToasterHandler = () => (
  <ZIndexFix>
    <ToasterContainer>
      <OutdatedBrowser />
    </ToasterContainer>
  </ZIndexFix>
)

export default ToasterHandler
