import cookies from '@themenu/shared/lib/services/cookies'
import Auth from '@themenu/shared/lib/services/auth'

import Zendesk from '@themenu/shared/lib/services/zendesk'
import { LOCATION_CHANGE } from '../modules/router'
import { COMPANY_SIGNUP_LEADACTION } from '../modules/uicorpo/company-signup-selector'
export const Segment = window.analytics

export const analyticsMiddleware = () => next => action => {
  const result = next(action)
  if (action.type === LOCATION_CHANGE) {
    Segment.page()
  }
  return result
}

export const SEGMENT_IDENTITY_ACCOUNTTYPE = {
  EMPLOYEE: 'employe',
  COMPANY: 'entreprise',
}

export const SEGMENT_EVENT_SIGNUP_TYPE = {
  [COMPANY_SIGNUP_LEADACTION.SIGNUP]: 'ouvrir un compte',
}

export const SEGMENT_EVENT = {
  SIGNUP_START: 'Account Creation Started',
  SIGNUP_STEP0: 'Account Creation Intro',
  SIGNUP_STEP1: 'Account Creation Step1',
  SIGNUP_STEP2: 'Account Creation Step2',
  SIGNUP_COMPLETE: 'Account Created',
  MVO_START_NOW: 'Order Initiated',
  MVO_FOR_YOU: 'Order For You',
  MVO_CONFSHIPPING: 'Order Placed Step1',
  MVO_ITEMS: 'Order Placed Step2',
  MVO_PAYMENT: 'Order Validated',
  MVO_CSV_GUIDE: 'Click Guide CSV',
  MVO_CSV_IMPORT: 'Click Import CSV',
  MVO_EMAILNEWUSER_GUIDE: 'PDF Lunchr Announcement Download',
  USERS_INVITE: 'Admin invited',
  FAQ_QUESTION_OPEN: 'Questions Clicked',
  FAQ_QUESTION_MORE: 'Questions More',
  ZENDESK_HELP_SIZE: 'Need help size',
}

/**
 * We need to save only the first value of currentInitiatedFrom for one company
 * scope : current navigation
 */
const _segmentOrderInitiatedFrom_ = {} // cache <[companyId] : initiatedFrom>
export const segmentGetOrderInitiatedFrom = (
  companyId,
  currentInitiatedFrom = 'Menu'
) => {
  if (!_segmentOrderInitiatedFrom_[companyId]) {
    _segmentOrderInitiatedFrom_[companyId] = currentInitiatedFrom
  }

  return _segmentOrderInitiatedFrom_[companyId]
}

export const segmentIdentify = infos => {
  const filteredValues = {}

  if (infos) {
    if (infos.first_name) filteredValues['firstName'] = infos.first_name
    if (infos.last_name) filteredValues['lastName'] = infos.last_name
    if (infos.email) filteredValues['email'] = infos.email
    if (infos.created_at) filteredValues['createdAt'] = infos.created_at
    if (infos.role) filteredValues['role'] = infos.role
    if (infos.birth_date) filteredValues['birthDate'] = infos.birth_date
    if (infos.sex) filteredValues['sex'] = infos.sex
    if (infos.phone) filteredValues['phone'] = infos.phone
    if (infos.account_type) filteredValues['accountType'] = infos.account_type
    if (infos.account_status)
      filteredValues['accountStatus'] = infos.account_status
    if (infos.company_id) filteredValues['companyID'] = infos.company_id
    if (infos.company_name) filteredValues['companyName'] = infos.company_name
    if (infos.company_postal_code)
      filteredValues['companyPostalCode'] = infos.company_postal_code
    if (infos.company_siret)
      filteredValues['companySiret'] = infos.company_siret
    if (infos.company_employee_count)
      filteredValues['companyEmployeeCount'] = infos.company_employee_count
    if (infos.companies) {
      filteredValues['companyNameManaged'] = infos.companies.map(c => c.name)
      filteredValues['companyIdManaged'] = infos.companies.map(c => c.id)
      filteredValues['companyManagedNumber'] = infos.companies.length
    }
    if (infos.companiesId) {
      filteredValues.companiesId = infos.companiesId
    }
  }

  if (Auth.isLoggedIn()) {
    Segment.identify(Auth.user.id, filteredValues)
  } else {
    Segment.identify(filteredValues)

    if (
      filteredValues.email &&
      filteredValues.firstName &&
      filteredValues.lastName
    ) {
      Zendesk.identify({
        email: filteredValues.email,
        first_name: filteredValues.firstName,
        last_name: filteredValues.lastName,
      })
    }
  }
}

/**
 * Identifies the user against segment
 * @param  {Object} user
 */
export const trackUser = user => {
  const lastName = user.last_name ? user.last_name : ''
  const firstName = user.first_name

  segmentIdentify({
    email: user.email,
    last_name: lastName,
    first_name: firstName,
    display_name: `${firstName} ${lastName}`,
    companiesId: user.companies ? user.companies.map(c => c.id) : null,
  })

  if (user.team) {
    Segment.group(`${user.team.id}`, {
      name: user.team.name,
    })
  }

  if (user.email) {
    Zendesk.identify(user)
  }
}

/**
 * Stop tracking the user
 */
export const untrackUser = () => Segment.reset()

export const submitHubspotForm = async (formId, datas) => {
  const base =
    process.env.REACT_APP_HUBSPOT_BASE || 'https://analytics.lunchr.co/404'

  const urlToPost = /^https:\/\//.test(formId) ? formId : `${base}/${formId}`
  const form = new FormData()
  Object.keys(datas).forEach(key => {
    form.append(key, datas[key])
  })
  form.append(
    'hs_context',
    JSON.stringify({
      hutk: cookies.get('hubspotutk'),
      pageUrl: document.location.href,
    })
  )

  try {
    await fetch(`${urlToPost}`, {
      method: 'POST',
      body: form,
      mode: 'no-cors',
    })
  } catch (err) {
    // Cors pbm but it works ..
  }
}

if (window.gtmHelpers === undefined) {
  window.gtmHelpers = {
    submitHubspotForm,
  }
}

export default { trackUser, untrackUser }
