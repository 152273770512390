import React from 'react'
import ReferralWrap from '../../ReferralWrap/ReferralWrap'
import styled from 'styled-components'
import { media } from '@themenu/ui'

const Wrapper = styled.div`
  ${media.SM`
  padding-left: 15%;
  padding-right: 15%;
`}
`
export function ReferralFromWrap({ children, notice }) {
  return (
    <ReferralWrap notice={notice}>
      <Wrapper>{children}</Wrapper>
    </ReferralWrap>
  )
}
