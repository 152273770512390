import React from 'react'
import Grid from '@material-ui/core/Grid'
import ReferralText from './ReferralText/ReferralText'
import FormUser from './FormUser/FormUser'
import FormWrap from './FormWrap/FormWrap'
import { ReferralFromWrap } from './ReferralFormWrap'
import qs from 'query-string'
import { testLunchrUrl } from '../common/testLunchrUrl'

export function ReferralForm({ history }) {
  const { firstname, lastname, company_name, job_title, email } = qs.parse(
    history.location.search
  )
  if (!firstname || !lastname || !company_name || !job_title || !email) {
    window.location = testLunchrUrl
    return null
  }
  return (
    <ReferralFromWrap notice={true}>
      <Grid container>
        <Grid item md={12} lg={12}>
          <Grid container justify="center">
            <Grid item sm={12}>
              <ReferralText />
            </Grid>
          </Grid>
        </Grid>
        <Grid item md={12} lg={12}>
          <Grid container>
            <Grid item sm={12}>
              <FormWrap>
                <FormUser history={history} />
              </FormWrap>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ReferralFromWrap>
  )
}
