import React from 'react'
import { useQuery } from 'react-query'
import { path, prop, pipe, or, lt, length } from 'ramda'
import moment from 'moment'

import { BANKING_PAYMENT_HISTORY_QUERY_ID } from '../utils/queryIds'
import { fetchTransactionHistory } from '@themenu/shared/lib/services/banking'

const BalanceContainer = ({
  Component,
  before = moment().format(),
  per = 1,
  ...props
}) => {
  const { data: transactionData } = useQuery(
    BANKING_PAYMENT_HISTORY_QUERY_ID,
    () => fetchTransactionHistory({ before, per })
  )

  const isLoadingMealVouchers = false

  const mealVoucherWallet = prop('mealVoucherWallet', {})
  const balanceIsPositive = pipe(path(['balance', 'value']), lt(0))
  const hasTransactions = pipe(prop('payments_history'), length, lt(0))
  const isDisplayingDailyBalance = or(
    balanceIsPositive(mealVoucherWallet),
    hasTransactions(transactionData)
  )

  return (
    <Component
      {...props}
      mealVoucherWallet={mealVoucherWallet}
      isDisplayingDailyBalance={
        !isLoadingMealVouchers && isDisplayingDailyBalance
      }
    />
  )
}

export default BalanceContainer
