import React from 'react'
import styled from 'styled-components'

import { Box, COLORS } from '@themenu/ui'

const ActivateCardBanner = styled(Box)`
  background: ${COLORS.PURPLE};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;

  .ActivateCardBanner__Text {
    color: ${COLORS.WHITE};
    margin-right: 16px;
  }
  a {
    color: white;
    text-decoration: underline;
  }
`

const Banner = ({ children }) => (
  <ActivateCardBanner flex>
    <span className="ActivateCardBanner__Text">{children}</span>
  </ActivateCardBanner>
)

export default Banner
