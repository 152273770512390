import React from 'react'
import styled from 'styled-components'

import { COLORS, Emoji, media } from '@themenu/ui'

import { DeliveryForm } from './DeliveryForm'

const StyledSummaryDelivery = styled.div``

const Paragraph = styled.p`
  font-size: 18px;
  line-height: 20px;
  margin: 0;
  width: 100%;
  color: ${COLORS.BLUE_SOFT};
  margin-bottom: 28px;
  font-weight: bold;

  ${media.SM`
  margin-bottom: 63px;
  font-size: 20px;
  line-height: 24px;
    width: 87%;
  `};
`

export function SummaryDelivery({ state, setState }) {
  return (
    <StyledSummaryDelivery>
      <Paragraph>
        Pour recevoir votre carte, nous avons besoin de votre adresse postale.
        C’est par ici <Emoji emoji="backhand-index-pointing-down" />
      </Paragraph>
      <DeliveryForm state={state} setState={setState} />
    </StyledSummaryDelivery>
  )
}
