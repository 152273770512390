import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import { COLORS } from '@themenu/ui'
import Header from './Header'
import Toaster from './Toaster'
import ToastersHandler from '../LayoutNew/ToastersHandler'

export const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  padding-bottom: 80px;

  ${props =>
    props.whiteLayout &&
    `
    background: ${COLORS.WHITE};
  `};
`

const AppLayout = ({ children, whiteLayout, headerStyle, stepper }) => (
  <Wrapper whiteLayout={whiteLayout}>
    <Helmet>
      <title>Swile</title>
    </Helmet>
    <Header stepper={stepper} whiteLayout={whiteLayout} {...headerStyle} />
    <Toaster />
    {children}
    <ToastersHandler />
  </Wrapper>
)

export default AppLayout
