import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const InactiveHistoryIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.00002 0C7.5523 0 8.00002 0.447715 8.00002 1V2H12V1C12 0.447715 12.4477 0 13 0C13.5523 0 14 0.447715 14 1V2H17C18.6569 2 20 3.34315 20 5V6C20 6.55228 19.5523 7 19 7C18.4477 7 18 6.55228 18 6V5C18 4.44772 17.5523 4 17 4H14V5C14 5.55228 13.5523 6 13 6C12.4477 6 12 5.55228 12 5V4H8.00002V5C8.00002 5.55228 7.5523 6 7.00002 6C6.44773 6 6.00002 5.55228 6.00002 5V4H3.00001C2.44773 4 2.00002 4.44772 2.00002 5V15C2.00002 15.5523 2.44773 16 3.00002 16H6C6.55229 16 7 16.4477 7 17C7 17.5523 6.55229 18 6 18H3.00002C1.34316 18 1.52588e-05 16.6569 1.52588e-05 15V5C1.52588e-05 3.34315 1.34316 2 3.00001 2H6.00002V1C6.00002 0.447715 6.44773 0 7.00002 0ZM14 10C11.7909 10 10 11.7909 10 14C10 16.2091 11.7909 18 14 18C16.2091 18 18 16.2091 18 14C18 11.7909 16.2091 10 14 10ZM8 14C8 10.6863 10.6863 8 14 8C17.3137 8 20 10.6863 20 14C20 17.3137 17.3137 20 14 20C10.6863 20 8 17.3137 8 14ZM14 11C14.5523 11 15 11.4477 15 12V14C15 14.5523 14.5523 15 14 15H12C11.4477 15 11 14.5523 11 14C11 13.4477 11.4477 13 12 13H13V12C13 11.4477 13.4477 11 14 11Z"
      fill={humanColorToHex(color)}
    />
  </svg>
)

export default InactiveHistoryIcon
