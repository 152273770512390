import React, { useState } from 'react'
import { Formik } from 'formik'
import { Grid } from '@material-ui/core'

import { saveUsersNegativeFeedback } from '../../../../../services/googleform'
import { EmojiInText } from '../../../common/components/EmojiInText'
import { FormTextarea } from '../../../common/components/FormTextarea'
import { GridItem } from '../../../common/components/GridItem'
import { hasErrors } from '../../../common/helpers/hasErrors'
import { feedbackNegativeSchema } from '../../../common/StateSchema'
import {
  FeedBackButton,
  FeedBackButtonSuccessMessage,
} from '../../common/FeedBackButton'
import { FeedBackText } from '../../common/FeedBackText'

export function NegativeFeedBackForm({ values }) {
  const [loading, setLoading] = useState(false)
  const [success, setSuccess] = useState(false)
  const [httpError, setHttpError] = useState(false)

  return (
    <div>
      <Formik
        initialValues={values}
        validateOnChange={true}
        validationSchema={feedbackNegativeSchema}
        onSubmit={async values => {
          setLoading(true)
          setSuccess(false)
          setHttpError(false)
          const { ok } = await saveUsersNegativeFeedback(values)
          setLoading(false)
          if (!ok) return setHttpError(true)
          setSuccess(true)
          return null
        }}
      >
        {({
          handleSubmit,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => {
          const commonProps = {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }
          return (
            <form method="post" onSubmit={handleSubmit}>
              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={10} lg={10} xl={10}>
                  <FeedBackText>
                    Nous sommes désolés que l’expérience de Swile n’ait pas été
                    satisfaisante. Dites-nous ce qui cloche
                    <EmojiInText
                      emoji="backhand-index-pointing-down"
                      size="20px"
                    />
                  </FeedBackText>
                </GridItem>
              </Grid>

              <Grid container spacing={8}>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormTextarea
                    name="comment"
                    {...commonProps}
                    disabled={success}
                  />
                </GridItem>
              </Grid>

              <FeedBackButton
                disabled={success || loading || hasErrors(errors)}
                error={httpError}
                success={success}
              >
                <FeedBackButtonSuccessMessage>
                  Merci, on donne tout pour devenir les meilleurs !{' '}
                  <EmojiInText emoji="flexed-biceps" size="20px" />
                </FeedBackButtonSuccessMessage>
              </FeedBackButton>
            </form>
          )
        }}
      </Formik>
    </div>
  )
}
