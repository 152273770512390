import { createSelector } from 'reselect'
import sortBy from 'lodash/sortBy'

const getNotifications = state => state.get('uiresto').notifications

export const notificationsSelector = createSelector(
  [getNotifications],
  notifications => {
    return sortBy(
      Object.keys(notifications).map(id => notifications[id]),
      'time'
    )
  }
)
