import moment from 'moment'
import { pipe, map, filter, prop, sum, lt } from 'ramda'
import { createSelector } from 'reselect'

import { getPrice } from '../../helpers/price'

const dbResInputSelector = state => state.getIn(['db', 'paymentsHistory'])

const historyMealVouchersInputSelector = state =>
  state.getIn(['ui', 'historyMealVouchers'])

const getRefundedAmount = details => {
  const refundedAmount = pipe(
    map(prop('refunded_amount')),
    filter(lt(0)),
    sum
  )(details)

  return refundedAmount || null
}

const getTransaction = transaction => {
  const price = getPrice(transaction.amount)
  const error = transaction.error_code
    ? {
        code: transaction.error_code,
        details: transaction.error_details,
      }
    : null

  return {
    id: transaction.id,
    displayableId: transaction.transaction_number,
    date:
      transaction.authorized_at ||
      transaction.declined_at ||
      transaction.executed_at ||
      transaction.created_at,
    created_at: transaction.created_at,
    type: transaction.type,
    restaurantName: transaction.name,
    amount: price,
    refundedAmount: transaction.details
      ? getRefundedAmount(transaction.details)
      : null,
    details: transaction.details,
    vendor: transaction.vendor,
    error,
  }
}

export const transactionsSelector = createSelector(
  dbResInputSelector,
  historyMealVouchersInputSelector,
  (dbOrders, historyMealVouchers) =>
    historyMealVouchers.list.map(id => getTransaction(dbOrders[id]))
)

export const isFetchingSelector = createSelector(
  historyMealVouchersInputSelector,
  historyMealVouchers => historyMealVouchers.fetching
)

export const hasMoreSelector = createSelector(
  historyMealVouchersInputSelector,
  historyMealVouchers => historyMealVouchers.hasMore
)

export const oldestTransactionDateSelector = createSelector(
  transactionsSelector,
  transactions => {
    let minDate = moment().format()

    transactions.forEach(transaction => {
      if (transaction.created_at < minDate) {
        minDate = transaction.created_at
      }
    })

    return moment(minDate).format()
  }
)
