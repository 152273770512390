import React, { createContext, useState } from 'react'

const defaultState = {
  leader: {
    gender: 'M',
    firstname: '',
    lastname: '',
    company_name: '',
    job_title: '',
    team: 'Ressources Humaines',
    email: '',
    address: '',
    address_2: '',
    zip: '',
    city: '',
  },
  contact_1: {
    firstname: '',
    email: '',
  },
  contact_2: {
    firstname: '',
    email: '',
  },
  contact_3: {
    firstname: '',
    email: '',
  },
  contact_4: {
    firstname: '',
    email: '',
  },
  invitees_count: 0,
}

const defaultGuestState = {
  firstname: '',
  leader_firstname: '',
  guest: 1,
}

export const ReferralContext = createContext()

export function ReferralProvider({ children }) {
  const [state, setState] = useState(defaultState)
  const [guestState, setGuestState] = useState(defaultGuestState)
  const [errors, setErrors] = useState({})
  return (
    <ReferralContext.Provider
      value={{ state, setState, guestState, setGuestState, errors, setErrors }}
    >
      {children}
    </ReferralContext.Provider>
  )
}
