import React from 'react'
import styled from 'styled-components'
import { Route } from 'react-router-dom'
import { map, curryN, or } from 'ramda'

import { Box, HelpButton } from '@themenu/ui'
import SidebarItemLink from './SidebarItemLink'

const Container = styled.div`
  height: 100%;
  overflow: auto;
  width: 100%;
  z-index: 999;
`

const Title = styled.div`
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #8c97a1;
  margin-bottom: 10px;
`

const renderAbsoluteLink = (icon, label, to) => (
  <SidebarItemLink to={to} icon={icon} absolute>
    <Box pl={10}>{label}</Box>
  </SidebarItemLink>
)

const renderRoute = curryN(4, (icon, label, to, { match }) => (
  <SidebarItemLink to={to} active={!!match} icon={icon}>
    <Box pl={10}>{label}</Box>
  </SidebarItemLink>
))

const renderLinks = map(({ icon, label, to, activePath, exact, absolute }) => {
  if (absolute) {
    return <li key={to}>{renderAbsoluteLink(icon, label, to)}</li>
  }
  return (
    <li key={to}>
      <Route exact={exact} path={or(activePath, to)}>
        {renderRoute(icon, label, to)}
      </Route>
    </li>
  )
})

const Sidebar = ({ title, nav, content = null }) => (
  <Container className="sidebar" data-test-id="Sidebar">
    <Box
      flex
      flexDirection="column"
      justifyContent="space-between"
      height="100%"
      p="44px 44px 20px"
    >
      <Box>
        <Title>{title}</Title>
        <ul>{renderLinks(nav)}</ul>
        <div>{content}</div>
      </Box>
      <Box pt={20}>
        <Box m="0 -20px 5px">
          <HelpButton />
        </Box>
      </Box>
    </Box>
  </Container>
)

export default Sidebar
