import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveGiftIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3333 5.33333H2.66667C2.29867 5.33333 2 5.632 2 6V7.33333C2 7.70133 2.29867 8 2.66667 8H13.3333C13.7013 8 14 7.70133 14 7.33333V6C14 5.632 13.7013 5.33333 13.3333 5.33333Z"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill={humanColorToHex(color)}
    />
    <path
      d="M8 14V5.33333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.464 4.408C9.74537 5.156 8.61404 5.33333 8.06604 5.33333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.066 5.33333C8.066 5.33333 7.73666 3.256 8.54666 2.41333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.464 4.40799C10.9934 3.85733 10.9934 2.96399 10.464 2.41333C9.9347 1.86266 9.0767 1.86266 8.54736 2.41333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.53601 4.408C6.25468 5.156 7.38601 5.33333 7.93401 5.33333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.93404 5.33333C7.93404 5.33333 8.26337 3.256 7.45337 2.41333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.53598 4.40799C5.00664 3.85733 5.00664 2.96399 5.53598 2.41333C6.06531 1.86266 6.92331 1.86266 7.45264 2.41333"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M12.6666 8V13.3333C12.6666 13.7013 12.368 14 12 14H3.99998C3.63198 14 3.33331 13.7013 3.33331 13.3333V8"
      stroke={humanColorToHex(color)}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ActiveGiftIcon
