import { fromJS } from 'immutable'
import { when, pipe, prop, path, propSatisfies, identity } from 'ramda'
import {
  isMealVoucherAdmin,
  isAdminOfAProgram,
} from '@themenu/shared/lib/utils/corpoAdmin'
import Auth from '@themenu/shared/lib/services/auth'

import { LOCATION_CHANGE } from './router'
import {
  LOGIN,
  LOGIN_SUCCEEDED,
  LOGIN_FAILED,
  UPDATE_USER,
  UPDATE_USER_SUCCEEDED,
  RESET_PASSWORD_SUCCEEDED,
  LOGOUT_SUCCEEDED,
  FETCH_PROFILE_SUCCEEDED,
  FETCH_IS_AFFILIATE_SUCCEEDED,
  FETCH_IS_AFFILIATE_FAILED,
} from './auth/types'
import { apiAffiliates } from '@themenu/shared/lib'
import { showIntl as showToastMessage } from './ui/toast-message'
import Analytics, { segmentIdentify } from '../services/analytics'
import * as Api from '../services/api'
import { fetchMyLunchrCards } from '../modules/uicorpo/my-lunchr-cards'

const fetchLunchrCardsWhenUserIsTemporary = when(
  propSatisfies(identity, 'hasAnonymousLunchrCard'),
  pipe(prop('dispatch'), dispatch => dispatch(fetchMyLunchrCards()))
)

/**
 * Action creators when the login succeeded
 * @param  {Object} data
 * @return {Object}
 */
export const loginSucceeded = ({
  user,
  accessToken,
  refreshToken,
}) => dispatch => {
  const baseToDispatch = {
    type: LOGIN_SUCCEEDED,
    user,
  }

  Auth.setUser(user)
  Analytics.trackUser(user)

  if (accessToken && refreshToken) {
    return dispatch({
      ...baseToDispatch,
      accessToken,
      refreshToken,
    })
  }

  return dispatch(baseToDispatch)
}

/**
 * Action creator when the update succeeded
 * @param  {Object} user
 * @return {Object}
 */
export const updateUserSucceeded = user => ({
  type: UPDATE_USER_SUCCEEDED,
  user,
})

/**
 * Action creator when the reset password succeeded
 * @param  {Object} user
 * @return {Object}
 */
export const resetPasswordSucceeded = user => ({
  type: RESET_PASSWORD_SUCCEEDED,
  user,
})

/**
 * Action creator when the user logged out successfully
 * @type {Object}
 */
export const logoutSucceeded = () => ({
  type: LOGOUT_SUCCEEDED,
})

/**
 * Action creator when the user fetch profile successfully
 * @param  {Object} user
 * @return {Object}
 */
export const fetchProfileSucceeded = user => dispatch => {
  const ret = dispatch({
    type: FETCH_PROFILE_SUCCEEDED,
    user,
    dbRes: {
      userMe: user,
    },
  })

  segmentIdentify({ companies: user.companies })

  return ret
}

export const fetchIsAffiliate = () => dispatch => {
  apiAffiliates.checkAccess().then(
    () => dispatch(fetchIsAffiliateSucceeded()),
    () => dispatch(fetchIsAffiliateFail())
  )
}

export const fetchIsAffiliateSucceeded = () => ({
  type: FETCH_IS_AFFILIATE_SUCCEEDED,
})
export const fetchIsAffiliateFail = () => ({
  type: FETCH_IS_AFFILIATE_FAILED,
})

const getAuthErrorCode = error => {
  return error.status === 429 ? 'TOO_MANY_ATTEMPTS' : 'WRONG_PASSWORD'
}

/**
 * Action creator to log the user ins
 * @param  {Object} { email, password })
 * @return {Promise}
 */
export const login = ({ email, password }) => async dispatch => {
  try {
    dispatch({
      type: LOGIN,
    })

    // Call the API service to log the user in
    const { accessToken, refreshToken, user } = await Api.login(email, password)

    dispatch(fetchIsAffiliate())
    dispatch(
      loginSucceeded({
        user,
        accessToken,
        refreshToken,
      })
    )
    dispatch(fetchProfileSucceeded(user))

    return Promise.resolve({ user })
  } catch (error) {
    console.error(error)
    const errorMessage = getAuthErrorCode(error)
    dispatch(loginFailed(errorMessage))
    return Promise.reject(errorMessage)
  }
}

export const loginByAccessCode = ({ token, code }) => async dispatch => {
  try {
    const data = await Api.loginByAccessCode({ token, code })

    dispatch(loginSucceeded({ user: data.user }))

    return Promise.resolve(data)
  } catch (error) {
    const errorMessage = 'WRONG_PASSWORD'
    dispatch(loginFailed(errorMessage))
    return Promise.reject(errorMessage)
  }
}

/**
 * Action creator login Failed
 * @param  {Object} error
 * @return {Object}
 */
export const loginFailed = error => ({
  type: LOGIN_FAILED,
  error,
})

/**
 * Action creator to update the user
 * @param  {Object} userData
 * @return {Promise}
 */
export const updateUser = (userData, token = null) => async dispatch => {
  try {
    dispatch({
      type: UPDATE_USER,
      data: {
        ...userData,
      },
    })
    // Get the new user info and update the old stored user info
    const data = await Api.updateUser(userData, token)
    // For now, because the returner data does not contain the corpo data,
    // we simply update the user with the returner data to prevent
    // the removal ou the corpo data of the user
    // In the future, with the frontend split/bff we will avoid doing that
    const newUserInfo = { ...Auth.user, ...data.user }

    // Store the user locally
    Auth.setUser(newUserInfo)
    Analytics.trackUser(newUserInfo)
    dispatch(updateUserSucceeded(newUserInfo))
    dispatch(showToastMessage('changesSaved'))
    return Promise.resolve(newUserInfo)
  } catch (error) {
    console.error('Failed to update user', error)
    return Promise.reject(error)
  }
}

/**
 * Action creator to update the user avatar
 * @param  {Object} file
 * @return {Promise}
 */
export const updateAvatar = file => async dispatch => {
  return Api.createUpload({
    name: file.name,
    contentType: file.type,
  })
    .then(uploadData => {
      const {
        upload: { key, url },
      } = uploadData

      return fetch(url, {
        method: 'PUT',
        body: file,
        headers: {
          'Content-Type': file.type,
        },
      }).then(() => {
        return Api.updateUser({
          avatarPicture: {
            key,
          },
        })
      })
    })
    .then(userData => {
      const newUserInfo = { ...Auth.user, ...userData.user }
      Auth.setUser(newUserInfo)
      dispatch(updateUserSucceeded(newUserInfo))
    })
}

/**
 * Action creator to reset password
 * @param  {String} options.token
 * @param  {String} options.password
 * @return {Promise}
 */
export const resetPassword = ({ token, password }) => dispatch =>
  Api.resetPassword({
    token,
    password,
  }).then(data => {
    dispatch(showToastMessage('passwordChanged'))
    dispatch(resetPasswordSucceeded(data.user))

    return data
  })

/**
 * Action creator to log the user out
 * @return {Object}
 */
export const logout = () => {
  Auth.logout()
  Analytics.untrackUser()
  return logoutSucceeded()
}

/**
 * Action creator to fetch the user
 * @return {Promise}
 */
export const fetchProfile = () => async dispatch => {
  const data = await Api.fetchCompleteProfile()

  await fetchLunchrCardsWhenUserIsTemporary({
    hasAnonymousLunchrCard: path(['user', 'has_anonymous_lunchr_card'], data),
    dispatch,
  })

  dispatch(fetchProfileSucceeded(data.user))
  Auth.setUser(data.user)
  Analytics.trackUser(data.user)
}

// Initialize the state here
const initialState = {
  companyAdminId: null,
  isAffiliate: null,
  accessToken: null,
  refreshToken: null,
}

// And the reducer
export default function AuthReducer(state = fromJS(initialState), action = {}) {
  switch (action.type) {
    case UPDATE_USER_SUCCEEDED:
      return state.set('user', fromJS(action.user))

    case FETCH_PROFILE_SUCCEEDED:
    case LOGIN_SUCCEEDED:
      const { user, accessToken, refreshToken } = action

      const administratedCompanies = user.companies.filter(company => {
        return isMealVoucherAdmin(company) || isAdminOfAProgram(company)
      })

      const newState =
        administratedCompanies.length > 0 &&
        state.get('companyAdminId') === null
          ? state.set('companyAdminId', administratedCompanies[0].uuid)
          : state

      if (accessToken && refreshToken) {
        return newState
          .set('user', fromJS(user))
          .set('accessToken', accessToken)
          .set('refreshToken', refreshToken)
      }

      return newState.set('user', fromJS(user))
    case LOGOUT_SUCCEEDED:
      return fromJS(initialState)

    case LOCATION_CHANGE:
      if (action.params && action.params.companyAdminId) {
        return state.set('companyAdminId', action.params.companyAdminId)
      }
      return state

    case FETCH_IS_AFFILIATE_SUCCEEDED:
      return state.set('isAffiliate', true)
    case FETCH_IS_AFFILIATE_FAILED:
      return state.set('isAffiliate', false)

    default:
      return state
  }
}
