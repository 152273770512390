import React from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import { compose } from 'recompose'

import { ButtonLink, Icon, COLORS } from '@themenu/ui'

import { userSelector } from '../../../modules/auth-selectors'

const StyledBackButton = styled(ButtonLink)`
  padding: 8px 10px;
  height: 35px;
  font-size: 12px;
  font-weight: 700;
  color: ${COLORS.GRAY};
  background: ${COLORS.GRAY_VERY_LIGHT};
  border: 1px solid ${COLORS.GRAY_LIGHTER};
  border-radius: 12px;

  &:hover {
    color: ${COLORS.GRAY};
    border-color: ${COLORS.GRAY_VERY_LIGHT};
    background: ${COLORS.WHITE_SMOKE_LIGHT};
  }

  & svg {
    fill: ${COLORS.GRAY};
  }
`

export const BackButton = ({ user }) => {
  const betaWebappEnabled = (user && user.betaWebappEnabled) || false

  return betaWebappEnabled ? (
    <StyledBackButton
      nativeLink
      href={process.env.REACT_APP_EMPLOYEE_APP_URL}
      mr={'12px'}
    >
      <Icon
        color={COLORS.GRAY}
        name="arrow-left"
        size={10}
        mr="8px"
        mt="-3px"
      />

      <FormattedMessage id="Header.button.back" defaultMessage="Retour" />
    </StyledBackButton>
  ) : (
    <span></span>
  )
}

const mapStateToProps = state => {
  return {
    user: userSelector(state),
  }
}

export default compose(connect(mapStateToProps, null))(BackButton)
