import { combineReducers } from 'redux'

import configuration from './configuration'
import addresses from './addresses'
import creditCards from './creditCards'
import userMe from './userMe'
import lunchrCards from './lunchr-cards'

const reducer = combineReducers({
  addresses,
  configuration,
  creditCards,
  userMe,
  lunchrCards,
})

export default reducer
