import QueryString from 'query-string'
import { isRealProd } from '../utils/env'

export const CORPORATE_DEPARTMENT_ID = 2147511903
export const USER_DEPARTMENT_ID = 2147511901
export const AFFILIATE_DEPARTMENT_ID = 2147513647

export const createZendeskTicket = (email, subject, message) => {
  const ticket = {
    requester: { email, name: 'Anonymous customer' },
    subject,
    comment: { body: message.trim().replace(/\n\s*/g, '\n') },
    tags: ['noconfirmationemail'],
  }
  if (isRealProd) {
    fetch('https://lunchr.zendesk.com/api/v2/requests.json', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ request: ticket }),
    })
  } else {
    // eslint-disable-next-line no-console
    console.log(`[Zendesk] Created ticket:`, ticket)
  }
}

export const activate = () => window.zE(() => window.zE.activate())

export const identify = user =>
  window.zE(() => {
    if (user) {
      const name = `${user.first_name} ${user.last_name}`
      const email = user.email

      window.zE.identify({ name, email })
    }
  })

export const setDepartment = department => {
  if (
    [
      CORPORATE_DEPARTMENT_ID,
      USER_DEPARTMENT_ID,
      AFFILIATE_DEPARTMENT_ID,
    ].indexOf(department) < 0
  ) {
    return
  }

  window.zE(() =>
    window.$zopim(() =>
      window.$zopim.livechat.departments.setVisitorDepartment(department)
    )
  )
}

export const setLabelsFromPathname = pathname => {
  const tokens = pathname
    .split('/')
    .filter(t => !/^[A-Z0-9]*$/.test(t) && t !== '')

  const finalLabels = tokens.length > 0 ? tokens : ['home']

  window.zE(() => {
    window.zE.setHelpCenterSuggestions({
      search: finalLabels.join(','),
    })
  })
}

export const searchHelpCenter = async params => {
  const res = await fetch(
    `https://lunchr.zendesk.com/api/v2/help_center/articles/search.json?${QueryString.stringify(
      params
    )}`
  )

  return res.json()
}

export default { activate, identify, setDepartment }
