import React from 'react'
import NProgress from 'nprogress'
import { lifecycle } from 'recompose'
import { createGlobalStyle } from 'styled-components'
import { COLORS } from '../../constants'

const LoadingBarGlobalStyle = createGlobalStyle`
  /* Make clicks pass-through */
  #nprogress {
    pointer-events: none;
  }

  #nprogress .bar {
    background: ${COLORS.GREEN};

    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;

    width: 100%;
    height: 3px;
  }

  /* Remove these to get rid of the spinner */
  #nprogress .spinner {
    display: block;
    position: fixed;
    z-index: 1031;
    top: 15px;
    right: 15px;
  }

  #nprogress .spinner-icon {
    width: 18px;
    height: 18px;
    box-sizing: border-box;

    border: solid 2px transparent;
    border-top-color: ${COLORS.GREEN};
    border-left-color: ${COLORS.GREEN};
    border-radius: 50%;

    animation: nprogress-spinner 400ms linear infinite;
  }

  .nprogress-custom-parent {
    overflow: hidden;
    position: relative;
  }

  .nprogress-custom-parent #nprogress .spinner,
  .nprogress-custom-parent #nprogress .bar {
    position: absolute;
  }

  @keyframes nprogress-spinner {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

const LoadingBar = lifecycle({
  componentDidMount() {
    NProgress.configure({ showSpinner: false, minimum: 0.2 })
    NProgress.start()
  },
  componentWillUnmount() {
    setTimeout(() => NProgress.done(), 300)
  }
})(() => <LoadingBarGlobalStyle />)

export default LoadingBar
