import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import styled from 'styled-components'
import Sticky from 'react-stickynode'

import {
  MobileMenuWrapper,
  StyledMobileMenu,
  MobileMenuNav,
  MobileMenuNavContent,
  MobileMenuNavItem,
  MobileMenuNavLink,
  MobileMenuNavExternalLink,
  MobileMenuMealVoucher,
  UserAvatar as AvatarZone,
} from './MobileMenu.style'
import MealVoucherInfo from '../MealVoucherInfo'
import { authAppURL, waitingTimeForSSOResponses } from '../../../utils/config'
import { DELETE_SESSION, onReceivedMessage } from '../../../utils/ssoActions'
import { UserAvatar, MobileMenuHamburger as Hamburger } from '@themenu/ui'
import { media } from '@themenu/ui/src/components/utils'
import { PartnersHomeRoute } from '../../../routes'
import { MealVoucherWalletPropType } from '../../../utils/propTypes'
import MealVoucherBalanceBlock from '../../../components/MealVoucherBalanceBlock'
import { RESTAURANT_LIMIT_ENABLED } from '../../../utils/constants'

// TODO: use ../../../components/MobileMenu

const bodyClassOpen = 'MobileMenu__Body--open'

const StyledSticky = styled(Sticky)`
  position: relative;
  top: -150px;
  ${media.SM`
    top: -70px;
  `}
  right: 10px;
  .sticky-inner-wrapper {
    display: flex;
    justify-content: flex-end;
  }
`

class MobileMenu extends React.Component {
  tunnel = null
  timeout = null

  constructor(props) {
    super(props)

    const { actions, history } = this.props

    this.state = { isOpen: props.isOpen }
    this.onReceivedMessage = onReceivedMessage(this.tunnel, () => {
      actions.logout()
      history.push('/signin')
    })
  }

  componentDidMount() {
    window.addEventListener('message', this.onReceivedMessage, false)
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.onReceivedMessage, false)
    clearTimeout(this.timeout)
  }

  setTunnelRef = element => {
    this.tunnel = element
  }

  handleNavClick() {
    this.setState({ isOpen: !this.state.isOpen })
    this.handleBodyClass()
  }

  handleBodyClass() {
    if (!this.state.isOpen) {
      document.body.classList.add(bodyClassOpen)
    } else {
      document.body.classList.remove(bodyClassOpen)
    }
  }

  handleLogoutClick(event) {
    event.preventDefault()

    const { actions, history } = this.props

    this.tunnel.contentWindow.postMessage(
      JSON.stringify({ action: DELETE_SESSION }),
      authAppURL
    )
    this.timeout = setTimeout(() => {
      actions.logout()
      history.push('/signin')
    }, waitingTimeForSSOResponses)
  }

  render() {
    const {
      user,
      isAffiliate,
      mealVoucherWallet,
      isDisplayingDailyBalance,
      hasMealVoucherProgram,
      hasMealVoucherBalance,
      ...props
    } = this.props

    return (
      <MobileMenuWrapper {...props}>
        <StyledSticky innerZ={1800}>
          <Hamburger
            onClick={this.handleNavClick.bind(this)}
            active={this.state.isOpen}
            color={this.props.color}
          />
        </StyledSticky>

        <StyledMobileMenu isVisible={this.state.isOpen}>
          <MobileMenuNav>
            {user ? (
              <div>
                <MobileMenuNavContent>
                  <MobileMenuNavItem>
                    <AvatarZone>
                      <UserAvatar
                        user={{
                          avatar_picture: user.avatarPictureKey
                            ? {
                                key: user.avatarPictureKey,
                              }
                            : null,
                          first_name: user.firstName || '',
                          last_name: user.lastName || '',
                        }}
                      />
                      <figcaption>{user.firstName}</figcaption>
                    </AvatarZone>
                  </MobileMenuNavItem>
                </MobileMenuNavContent>

                {RESTAURANT_LIMIT_ENABLED
                  ? mealVoucherWallet && (
                      <MealVoucherBalanceBlock
                        isWithBorder
                        isDisplayingDailyBalance={isDisplayingDailyBalance}
                        mealVoucherWallet={mealVoucherWallet}
                      />
                    )
                  : user.mealVoucher && (
                      <MobileMenuMealVoucher>
                        <MealVoucherInfo
                          expectedBalance={user.mealVoucher.expectedBalance}
                          expectedBalanceDate={
                            user.mealVoucher.expectedBalanceDate
                          }
                          globalBalance={user.mealVoucher.balance}
                          dailyBalance={user.mealVoucher.dailyBalance}
                          facialValue={user.mealVoucher.facialValue}
                        />
                      </MobileMenuMealVoucher>
                    )}

                <MobileMenuNavContent>
                  <MobileMenuNavItem>
                    <MobileMenuNavLink
                      to="/profile"
                      onClick={this.handleNavClick.bind(this)}
                    >
                      <FormattedMessage
                        id="Layout.UserMenu.profileLink"
                        defaultMessage="Modifier mon profil"
                      />
                    </MobileMenuNavLink>
                  </MobileMenuNavItem>

                  {(hasMealVoucherProgram || hasMealVoucherBalance) && (
                    <MobileMenuNavItem>
                      <MobileMenuNavLink
                        to={'/history/mealvouchers'}
                        onClick={this.handleNavClick.bind(this)}
                      >
                        <FormattedMessage
                          id="Layout.UserMenu.ordersHistory"
                          defaultMessage="Commandes & transactions"
                        />
                      </MobileMenuNavLink>
                    </MobileMenuNavItem>
                  )}

                  {user.hasLunchrCard && (
                    <MobileMenuNavItem>
                      <MobileMenuNavLink
                        to="/card"
                        onClick={this.handleNavClick.bind(this)}
                      >
                        <FormattedMessage
                          id="Layout.UserMenu.card"
                          defaultMessage="Cartes Swile"
                        />
                      </MobileMenuNavLink>
                    </MobileMenuNavItem>
                  )}

                  {(hasMealVoucherProgram || hasMealVoucherBalance) && (
                    <MobileMenuNavItem>
                      <MobileMenuNavLink
                        to="/payments/credit-cards"
                        onClick={this.handleNavClick.bind(this)}
                      >
                        <FormattedMessage
                          id="Layout.UserMenu.payments"
                          defaultMessage="Cartes de crédit"
                        />
                      </MobileMenuNavLink>
                    </MobileMenuNavItem>
                  )}
                </MobileMenuNavContent>

                {isAffiliate && (
                  <MobileMenuNavContent>
                    <MobileMenuNavLink
                      to={PartnersHomeRoute.reverse()}
                      onClick={this.handleNavClick.bind(this)}
                    >
                      <FormattedMessage
                        id="Layout.UserMenu.PartnersDashboard"
                        defaultMessage="Espace affilié"
                      />
                    </MobileMenuNavLink>
                  </MobileMenuNavContent>
                )}

                <MobileMenuNavContent>
                  <MobileMenuNavItem>
                    <MobileMenuNavLink
                      to="/"
                      onClick={this.handleNavClick.bind(this)}
                    >
                      <FormattedMessage
                        id="MobileMenu.home"
                        defaultMessage="Page d'accueil"
                      />
                    </MobileMenuNavLink>
                  </MobileMenuNavItem>

                  <MobileMenuNavItem>
                    <MobileMenuNavExternalLink
                      onClick={this.handleLogoutClick.bind(this)}
                    >
                      <FormattedMessage
                        id="MobileMenu.logout"
                        defaultMessage="Déconnexion"
                      />
                    </MobileMenuNavExternalLink>
                  </MobileMenuNavItem>
                </MobileMenuNavContent>
              </div>
            ) : (
              <div>
                <MobileMenuNavContent>
                  <MobileMenuNavItem>
                    <MobileMenuNavLink
                      to="/"
                      onClick={this.handleNavClick.bind(this)}
                    >
                      <FormattedMessage
                        id="MobileMenu.home"
                        defaultMessage="Page d'accueil"
                      />
                    </MobileMenuNavLink>
                  </MobileMenuNavItem>
                </MobileMenuNavContent>

                <MobileMenuNavContent>
                  <MobileMenuNavItem>
                    <MobileMenuNavLink
                      to="/signin"
                      onClick={this.handleNavClick.bind(this)}
                    >
                      <FormattedMessage
                        id="MobileMenu.signin"
                        defaultMessage="Connectez-vous"
                      />
                    </MobileMenuNavLink>
                  </MobileMenuNavItem>

                  <MobileMenuNavItem>
                    <MobileMenuNavLink
                      to="/signup"
                      onClick={this.handleNavClick.bind(this)}
                    >
                      <FormattedMessage
                        id="MobileMenu.signup"
                        defaultMessage="Créer un compte"
                      />
                    </MobileMenuNavLink>
                  </MobileMenuNavItem>
                </MobileMenuNavContent>

                <MobileMenuNavContent>
                  <MobileMenuNavItem>
                    <MobileMenuNavExternalLink href="https://itunes.apple.com/fr/app/lunchr-commande-sur-place-ou-%C3%A0-emporter/id1190612035?l=fr&amp;ls=1&amp;mt=8">
                      <FormattedMessage
                        id="MobileMenu.downloadIOS"
                        defaultMessage="Télécharger l'application iOS"
                      />
                    </MobileMenuNavExternalLink>
                  </MobileMenuNavItem>

                  <MobileMenuNavItem>
                    <MobileMenuNavExternalLink href="https://play.google.com/store/apps/details?id=hr.lunc.client">
                      <FormattedMessage
                        id="MobileMenu.downloadAndroid"
                        defaultMessage="Télécharger l'application Android"
                      />
                    </MobileMenuNavExternalLink>
                  </MobileMenuNavItem>
                </MobileMenuNavContent>
              </div>
            )}
          </MobileMenuNav>
        </StyledMobileMenu>
        {process.env.NODE_ENV === 'production' ||
        authAppURL.startsWith('https') ? (
          <iframe
            ref={this.setTunnelRef}
            title="SSO tunnel"
            src={`${authAppURL}/session/new`}
            style={{ display: 'none' }}
          />
        ) : null}
      </MobileMenuWrapper>
    )
  }
}

MobileMenu.propTypes = {
  color: PropTypes.string,
  isOpen: PropTypes.bool,
  user: PropTypes.shape({
    avatarPictureKey: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    hasLunchrCard: PropTypes.bool,
    mealVoucher: PropTypes.object,
  }),
  mealVoucherWallet: MealVoucherWalletPropType,
  isDisplayingDailyBalance: PropTypes.bool,
}

MobileMenu.defaultProps = {
  color: null,
  isOpen: false,
  user: null,
}

export default MobileMenu
