import React from 'react'
import styled from 'styled-components'
import { FormattedNumber, FormattedMessage } from 'react-intl'
import moment from 'moment-timezone'

const MealVoucherInfoStyle = styled.div`
  background-color: ${props => props.theme.colors.MAIN_BG_COLOR_GRAY};
  margin: 10px -40px 8px;
  padding: 10px 40px 8px 40px;

  .amounts {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .amount {
    text-align: left;

    .value {
      font-size: 18px;
      font-weight: normal;
      line-height: 20px;
      color: ${props => props.theme.colors.BLUE_DARK};

      &.value--disabled {
        color: ${props => props.theme.colors.GRAY};
      }
    }

    .label {
      color: ${props => props.theme.colors.GRAY};
      font-size: 13px;
    }
  }

  .facialValue {
    padding-top: 4px;
    font-size: 11px;
    color: ${props => props.theme.colors.GRAY};
    text-align: justify;
  }
`

const MealVoucherInfo = ({
  expectedBalance,
  globalBalance,
  dailyBalance,
  expectedBalanceDate,
  facialValue,
}) => {
  const showExpectedBalance =
    globalBalance.value === 0 && expectedBalance.value > 0

  return (
    <MealVoucherInfoStyle>
      <div className="amounts">
        {showExpectedBalance ? (
          <div className="amount">
            <div className="value value--disabled">
              <FormattedNumber
                value={expectedBalance.value}
                format={expectedBalance.currency}
              />
            </div>

            <div className="label">
              <FormattedMessage
                id="Layout.UserMenu.MealVoucherInfo.expectedGlobalAmount"
                defaultMessage="seront crédités le {date}"
                values={{
                  date: moment
                    .tz(expectedBalanceDate, 'Europe/Paris')
                    .format('Do MMM'),
                }}
              />
            </div>
          </div>
        ) : (
          <div className="amount">
            <div className="value">
              <FormattedNumber
                value={globalBalance.value}
                format={globalBalance.currency}
              />
            </div>

            <div className="label">
              <FormattedMessage
                id="Layout.UserMenu.MealVoucherInfo.monthlyGlobalAmount"
                defaultMessage="disponible au total"
              />
            </div>
          </div>
        )}

        <div className="amount">
          <div className="value">
            <FormattedNumber
              value={dailyBalance.value}
              format={dailyBalance.currency}
            />
          </div>

          <div className="label">
            <FormattedMessage
              id="Layout.UserMenu.MealVoucherInfo.dailyGlobalAmount"
              defaultMessage="à dépenser aujourd'hui"
            />
          </div>
        </div>
      </div>

      {facialValue && (
        <div className="facialValue">
          <FormattedMessage
            id="Layout.UserMenu.MealVoucherInfoStyle.facialValue"
            defaultMessage="Vos titres-restaurant sont d'une valeur unitaire de {facialValue}"
            values={{
              facialValue: (
                <FormattedNumber
                  value={facialValue.value}
                  format={facialValue.currency}
                />
              ),
            }}
          />
        </div>
      )}
    </MealVoucherInfoStyle>
  )
}

export default MealVoucherInfo
