import React from 'react'
import styled from 'styled-components'
import { Helmet } from 'react-helmet'

import Header from '../../../screens/Layout/Header'
import Toaster from '../../../screens/Layout/Toaster'
import SidebarLayout from './SidebarLayout'
import {
  FixedHeaderLayout,
  FixedHeader,
  FixedBody,
} from '../../FixedHeaderLayout'

export const Wrapper = styled.div`
  position: relative;
  min-height: 100%;
  background-color: white;
  overflow-x: auto;
`

const Layout = props => (
  <Wrapper>
    <Helmet>
      <title>Swile</title>
    </Helmet>

    <FixedHeaderLayout>
      <FixedHeader>
        <Header showMenu withBorder />
      </FixedHeader>

      <FixedBody>
        <SidebarLayout {...props} />
      </FixedBody>
    </FixedHeaderLayout>

    <Toaster />
  </Wrapper>
)

export default Layout
