import React, { useState, useContext, useEffect } from 'react'
import styled from 'styled-components'
import qs from 'query-string'
import { withRouter } from 'react-router'
import { Formik, Field } from 'formik'
import { Grid } from '@material-ui/core'
import { COLORS, Button, media } from '@themenu/ui'
import { FormInput } from '../../common/components/FormInput'
import { FormSelect } from '../../common/components/FormSelect'
import { GridItem } from '../../common/components/GridItem'
import { hasErrors } from '../../common/helpers/hasErrors'
import { getRewardPerPerson } from '../../common/helpers/getRewardPerPerson'
import { GENDER_OPTIONS, TEAM_OPTIONS } from '../../common/options'
import { guestSchema } from '../../common/StateSchema'
import { testLunchrUrl } from '../../common/testLunchrUrl'
import { ReferralContext } from '../../ReferralContext'
import { saveUsersRecommandationInvitee } from '../../../../services/googleform'
import { ReferralGuestCompleteRoute } from '../../../../routes'

const StyledDeliveryForm = styled.div`
  ${media.SM`
    padding-top: 60px;
  `}
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

const StyledButton = styled(Button)`
  margin-top: 14px;
  width: 100%;
  ${media.SM`
    width: auto;
  `}
`

const StyledError = styled.span`
  color: ${COLORS.PINK};
  padding-left: 20px;
`

function getFormValues(values) {
  return {
    ...values,
    card_amount: getRewardPerPerson(values.invitees_count),
  }
}

function GuestForm({ history }) {
  const [loading, setLoading] = useState(false)
  const [httpError, setHttpError] = useState(false)
  const { setGuestState } = useContext(ReferralContext)
  const {
    email,
    firstname,
    guest,
    leader_email,
    leader_firstname,
    company_name,
  } = qs.parse(history.location.search)

  useEffect(() => {
    if (firstname && guest && leader_firstname) {
      setGuestState({ firstname, guest: parseInt(guest), leader_firstname })
    }
  }, [firstname, guest, leader_firstname])

  if (
    !email ||
    !firstname ||
    !guest ||
    !leader_email ||
    !leader_firstname ||
    !company_name
  ) {
    window.location = testLunchrUrl
    return null
  }

  const initialState = {
    leader_email,
    firstname,
    email,
    gender: GENDER_OPTIONS[0].value,
    lastname: '',
    address: '',
    address_2: '',
    zip: '',
    city: '',
    team: TEAM_OPTIONS[0].value,
    invitees_count: parseInt(guest),
    leader_firstname,
    company_name,
  }

  return (
    <div>
      <StyledDeliveryForm>
        <Formik
          initialValues={initialState}
          validateOnChange={true}
          validationSchema={guestSchema}
          onSubmit={async values => {
            setLoading(true)
            setHttpError(false)
            const formValues = getFormValues(values)
            const { ok } = await saveUsersRecommandationInvitee(formValues)
            setLoading(false)
            if (!ok) return setHttpError(true)
            history.push(ReferralGuestCompleteRoute.reverse({ guest }))
            return null
          }}
        >
          {({
            handleSubmit,
            setFieldValue,
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }) => {
            const commonProps = {
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
            }
            return (
              <form method="post" onSubmit={handleSubmit}>
                <Grid container spacing={16}>
                  <Field type="hidden" name="leader_email" />
                  <GridItem xs={12} sm={12} md={2} lg={2} xl={2}>
                    <FormSelect
                      name="gender"
                      title="Civilité*"
                      options={GENDER_OPTIONS}
                      selected={values.gender}
                      setFieldValue={setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
                    <FormInput
                      type="text"
                      name="firstname"
                      title="Votre prénom*"
                      {...commonProps}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
                    <FormInput
                      type="text"
                      name="lastname"
                      title="Votre nom*"
                      {...commonProps}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormInput
                      type="text"
                      name="email"
                      title="Votre adresse email pro*"
                      disabled={true}
                      {...commonProps}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormSelect
                      name="team"
                      title="Votre équipe*"
                      options={TEAM_OPTIONS}
                      selected={values.team}
                      setFieldValue={setFieldValue}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormInput
                      type="text"
                      name="address"
                      title="Adresse d’envoi de la carte*"
                      {...commonProps}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormInput
                      type="text"
                      name="address_2"
                      title="Complément d’adresse"
                      {...commonProps}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormInput
                      type="text"
                      name="zip"
                      title="CP*"
                      {...commonProps}
                    />
                  </GridItem>
                  <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                    <FormInput
                      type="text"
                      name="city"
                      title="Ville*"
                      {...commonProps}
                    />
                  </GridItem>
                </Grid>
                <ButtonContainer>
                  <StyledButton
                    color="green"
                    size="large"
                    type="submit"
                    disabled={loading || hasErrors(errors)}
                  >
                    Valider
                  </StyledButton>
                  {httpError && (
                    <StyledError>
                      Une erreur est survenue, merci de réessayer plus tard
                    </StyledError>
                  )}
                </ButtonContainer>
              </form>
            )
          }}
        </Formik>
      </StyledDeliveryForm>
    </div>
  )
}

export default withRouter(GuestForm)
