import React from 'react'
import styled from 'styled-components'

import { COLORS, media } from '@themenu/ui'
import { EmojiInText } from '../../common/components/EmojiInText'

const Title = styled.h2`
  font-size: 20px;
  line-height: 24px;
  margin-bottom: 27px;
  color: ${COLORS.BLACK};
  font-weight: bold;
  display: inline-block;

  ${media.SM`
    font-size: 54px;
    line-height: 64px;
  `}
`

const StyledEmojiInText = styled(EmojiInText)`
  width: 20px;

  ${media.SM`
    width: 54px;
  `}
`

export function FeedBackTitle({ children, emoji }) {
  return (
    <Title>
      {children}
      <StyledEmojiInText emoji={emoji} />
    </Title>
  )
}
