import React, { useState } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router'
import { Formik } from 'formik'
import { Grid } from '@material-ui/core'

import { COLORS, Button, media } from '@themenu/ui'

import { saveUsersRecommandation } from '../../../../../services/googleform'
import { ReferralSummaryCompleteRoute } from '../../../../../routes'
import { FormInput } from '../../../common/components/FormInput'
import { FormSelect } from '../../../common/components/FormSelect'
import { GridItem } from '../../../common/components/GridItem'
import { hasErrors } from '../../../common/helpers/hasErrors'
import { leaderSchema } from '../../../common/StateSchema'
import { GENDER_OPTIONS, TEAM_OPTIONS } from '../../../common/options'

const StyledDeliveryForm = styled.div`
  margin-bottom: 62px;

  ${media.SM`
    margin-bottom: 129px;
  `}
`

const StyledButton = styled(Button)`
  margin-top: 19px;
  width: 100%;

  ${media.SM`
    width: auto;
  `}
`

const StyledError = styled.span`
  color: ${COLORS.PINK};
  padding-left: 20px;
`

function DeliveryForm({ history, state, setState }) {
  const [loading, setLoading] = useState(false)
  const [httpError, setHttpError] = useState(false)

  return (
    <StyledDeliveryForm>
      <Formik
        initialValues={state}
        validateOnChange={true}
        validationSchema={leaderSchema}
        onSubmit={async values => {
          setLoading(true)
          setHttpError(false)
          setState(values)
          const { ok } = await saveUsersRecommandation(values)
          setLoading(false)
          if (!ok) return setHttpError(true)
          history.push(ReferralSummaryCompleteRoute.spec)
          return null
        }}
      >
        {({
          handleSubmit,
          setFieldValue,
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
        }) => {
          const commonProps = {
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
          }
          return (
            <form method="post" onSubmit={handleSubmit}>
              <Grid container spacing={16}>
                <GridItem xs={12} sm={12} md={2} lg={2} xl={2}>
                  <FormSelect
                    name="leader.gender"
                    title="Civilité*"
                    options={GENDER_OPTIONS}
                    selected={values.leader.gender}
                    setFieldValue={setFieldValue}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
                  <FormInput
                    type="text"
                    name="leader.firstname"
                    title="Votre prénom*"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
                  <FormInput
                    type="text"
                    name="leader.lastname"
                    title="Votre nom*"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={5} lg={5} xl={5}>
                  <FormInput
                    type="text"
                    name="leader.company_name"
                    title="Votre entreprise*"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={4} lg={4} xl={4}>
                  <FormInput
                    type="text"
                    name="leader.job_title"
                    title="Votre poste*"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={3} lg={3} xl={3}>
                  <FormSelect
                    name="leader.team"
                    title="Votre équipe*"
                    options={TEAM_OPTIONS}
                    selected={values.leader.team}
                    setFieldValue={setFieldValue}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={12} lg={12} xl={12}>
                  <FormInput
                    type="text"
                    name="leader.email"
                    title="Votre adresse email pro*"
                    disabled={true}
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormInput
                    type="text"
                    name="leader.address"
                    title="Adresse d’envoi de la carte*"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormInput
                    type="text"
                    name="leader.address_2"
                    title="Complément d’adresse"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormInput
                    type="text"
                    name="leader.zip"
                    title="CP*"
                    {...commonProps}
                  />
                </GridItem>
                <GridItem xs={12} sm={12} md={6} lg={6} xl={6}>
                  <FormInput
                    type="text"
                    name="leader.city"
                    title="Ville*"
                    {...commonProps}
                  />
                </GridItem>
              </Grid>
              <div>
                <StyledButton
                  color="green"
                  size="large"
                  type="submit"
                  disabled={loading || hasErrors(errors)}
                >
                  Valider
                </StyledButton>
                {httpError && (
                  <StyledError>
                    Une erreur est survenue, merci de réessayer plus tard
                  </StyledError>
                )}
              </div>
            </form>
          )
        }}
      </Formik>
    </StyledDeliveryForm>
  )
}

const DeliveryFormWithRouter = withRouter(DeliveryForm)

export { DeliveryFormWithRouter as DeliveryForm }
