import React from 'react'

import { humanColorToHex } from '@themenu/ui'

const ActiveCardIcon = ({ color }) => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.70066 16.4279L2.86136 15.8843C2.60285 16.2834 2.66111 16.8093 3.00069 17.1421L3.70066 16.4279ZM16.2993 16.4279L16.9993 17.1421C17.3389 16.8093 17.3972 16.2834 17.1386 15.8843L16.2993 16.4279ZM4.53996 16.9716C5.69974 15.1811 7.71223 14 10 14V12C7.00592 12 4.37416 13.5488 2.86136 15.8843L4.53996 16.9716ZM10 14C12.2878 14 14.3003 15.1811 15.46 16.9716L17.1386 15.8843C15.6258 13.5488 12.9941 12 10 12V14ZM15.5994 15.7138C14.1554 17.129 12.1804 18 10 18V20C12.7247 20 15.1966 18.9089 16.9993 17.1421L15.5994 15.7138ZM10 18C7.81959 18 5.8446 17.129 4.40063 15.7138L3.00069 17.1421C4.80335 18.9089 7.27526 20 10 20V18Z"
      fill={humanColorToHex(color)}
    />
    <circle
      cx="10"
      cy="7"
      r="2"
      stroke={humanColorToHex(color)}
      strokeWidth="2"
    />
    <path
      d="M1.51212 13C1.18046 12.0617 1 11.0519 1 10C1 5.02944 5.02944 1 10 1C14.9706 1 19 5.02944 19 10C19 11.0519 18.8195 12.0617 18.4879 13"
      stroke={humanColorToHex(color)}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default ActiveCardIcon
