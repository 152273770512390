import { fromJS } from 'immutable'

const UI_TOAST_MESSAGE_SHOW = 'ui/TOAST_MESSAGE_SHOW'
const UI_TOAST_MESSAGE_SHOW_INTL = 'ui/TOAST_MESSAGE_SHOW_INTL'
const UI_TOAST_MESSAGE_CLOSE = 'ui/TOAST_MESSAGE_CLOSE'
const UI_TOAST_MESSAGE_TIMEOUTHIDE = 'ui/TOAST_MESSAGE_TIMEOUTHIDE'

let autoHideTimer = null
const autoHideHelper = (dispatch, duration) => {
  if (autoHideTimer) {
    // avoid conflict
    clearTimeout(autoHideTimer)
  }
  if (duration > 0) {
    autoHideTimer = setTimeout(
      () => dispatch({ type: UI_TOAST_MESSAGE_TIMEOUTHIDE }),
      duration
    )
  }
}

export const show = (
  message,
  autoHideDuration = 4000,
  options = {}
) => dispatch => {
  dispatch({ type: UI_TOAST_MESSAGE_SHOW, message, options })
  autoHideHelper(dispatch, autoHideDuration)
}

export const showIntl = (
  key,
  values = {},
  autoHideDuration = 4000,
  options = {}
) => dispatch => {
  dispatch({
    type: UI_TOAST_MESSAGE_SHOW_INTL,
    key,
    values,
    options,
  })
  autoHideHelper(dispatch, autoHideDuration)
}

export const close = () => {
  if (autoHideTimer) {
    // avoid dispatch
    clearTimeout(autoHideTimer)
  }
  return {
    type: UI_TOAST_MESSAGE_CLOSE,
  }
}

const initialState = {
  options: {}, // plain javascript
  message: null,
  messageIntl: null, // plain javascript
}

export default function(state = fromJS(initialState), action) {
  switch (action.type) {
    case UI_TOAST_MESSAGE_SHOW:
      return state
        .set('message', action.message)
        .set('messageIntl', null)
        .set('options', action.options)

    case UI_TOAST_MESSAGE_SHOW_INTL:
      return state
        .set('messageIntl', { key: action.key, values: action.values })
        .set('message', null)
        .set('options', action.options)

    case UI_TOAST_MESSAGE_CLOSE:
    case UI_TOAST_MESSAGE_TIMEOUTHIDE:
      return state.set('message', null).set('messageIntl', null)

    default:
      return state
  }
}
