import React from 'react'
import styled from 'styled-components'

import { COLORS, media } from '@themenu/ui'

const StyledInvitees = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${media.SM`
    width: 87%;
  `}
`

const InviteeHeader = styled.h3`
  font-size: 20px;
  line-height: 36px;
  color: #1b2733;
  margin-bottom: 40px;

  ${media.SM`
    font-size: 38px;
    line-height: 48px;
  `}
`

const StyledInvitee = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  color: ${COLORS.BLUE_SOFT};
  border-top: 1px solid #ededed;
  padding: 20px 0;

  ${media.SM`
    flex-direction: row;
 `}

  &:last-child {
    border-bottom: 1px solid #ededed;
  }
`

const InviteeNameColumn = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  text-align: left;

  span {
    font-weight: bold;

    ${media.SM`
      font-weight: normal;
    `}
  }
`

const InviteeEmail = styled(InviteeNameColumn)`
  margin-top: 10px;

  ${media.SM`
    margin-top: 0;
  `}
`

const InviteeLeaderBadge = styled.div`
  background-color: ${COLORS.GREEN};
  border-radius: 3px;
  color: ${COLORS.WHITE};
  padding: 0 12px;
  margin-left: 30px;
`

function Invitee({ invitee, leader }) {
  const { firstname, email } = invitee
  if (!firstname || !email) return null
  return (
    <StyledInvitee>
      <InviteeNameColumn>
        <span>{firstname}</span>
        {leader ? <InviteeLeaderBadge>Vous</InviteeLeaderBadge> : ''}
      </InviteeNameColumn>
      <InviteeEmail>{email}</InviteeEmail>
    </StyledInvitee>
  )
}

export function Invitees({
  leader,
  contact_1,
  contact_2,
  contact_3,
  contact_4,
}) {
  return (
    <StyledInvitees>
      <InviteeHeader>Dans cette aventure Lunchr, il y a :</InviteeHeader>
      <Invitee invitee={leader} leader={true} />
      <Invitee invitee={contact_1} />
      <Invitee invitee={contact_2} />
      <Invitee invitee={contact_3} />
      <Invitee invitee={contact_4} />
    </StyledInvitees>
  )
}
