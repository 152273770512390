import React, { useContext } from 'react'
import styled from 'styled-components'
import { COLORS, media } from '@themenu/ui'
import GuestForm from './GuestForm/GuestForm'
import ReferralFooter from '../ReferralWrap/ReferralFooter/ReferralFooter'
import GuestHeader from './GuestHeader/GuestHeader'
import { ReferralContext } from '../ReferralContext'
import { getRewardPerPerson } from '../common/helpers/getRewardPerPerson'

const WrapperFooter = styled.div`
  padding: 0 28px;
  background: ${COLORS.WHITE};
  ${media.SM`
    padding: 0 13%;
  `}
`

const WrapperForm = styled.div`
  padding: 0 28px;
  background: ${COLORS.WHITE};
  ${media.SM`
    padding: 0 23%;
  `}
`

export function ReferralGuestForm() {
  const {
    guestState: { leader_firstname, firstname, guest },
  } = useContext(ReferralContext)
  const amount = getRewardPerPerson(parseInt(guest))
  return (
    <div>
      <GuestHeader
        leaderName={leader_firstname}
        guestName={firstname}
        amount={amount}
      />
      <WrapperForm paddingwidth={'23%'} margintop={'-8px'}>
        <GuestForm />
      </WrapperForm>
      <WrapperFooter>
        <ReferralFooter paddingTop="80px" notice={true} />
      </WrapperFooter>
    </div>
  )
}
