import React from 'react'
import styled from 'styled-components'

import { Dropdown, COLORS } from '@themenu/ui'

import { FormTitle } from '../FormTitle'

const StyledFormSelect = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`

const StyledDropdown = styled(Dropdown)`
  color: ${COLORS.BLUE_DARK};
  border-radius: 10px;
`

export function FormSelect({
  title,
  placeholder,
  selected,
  name,
  setFieldValue,
  options,
}) {
  return (
    <StyledFormSelect>
      <FormTitle>{title}</FormTitle>
      <StyledDropdown
        name={name}
        placeholder={placeholder}
        options={options}
        selected={selected}
        onSelect={id => setFieldValue(name, id)}
        softSquare
      />
    </StyledFormSelect>
  )
}
