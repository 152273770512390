import { combineReducers } from 'redux-immutable'

import historyMealVouchers from './history-mealvouchers'
import toastMessage from './toast-message'
import confirmAccount from './confirm-account'

export default combineReducers({
  toastMessage,
  historyMealVouchers,
  confirmAccount,
})
