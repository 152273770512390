import React from 'react'
import styled from 'styled-components'
import { Grid, Row } from 'react-styled-flexboxgrid'
import Col from 'react-styled-flexboxgrid/lib/components/Col'

import { Box, HelpButton } from '@themenu/ui'
import SidebarUser from './Sidebar/User'

const GridLayout = styled(Grid)`
  margin-top: 48px;
  margin-bottom: 48px;
`

const SidebarLayout = ({ children }) => (
  <React.Fragment>
    <GridLayout>
      <Row>
        <Col xs={12} md={4} lg={3}>
          <SidebarUser />
        </Col>
        <Col xs={12} md={8} lg={9}>
          {children}
        </Col>
      </Row>
    </GridLayout>
    <Box m="0 -20px 5px">
      <HelpButton />
    </Box>
  </React.Fragment>
)

export default SidebarLayout
